<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div class="new-exp-input-fields new-exp-text-style">
  <form [formGroup]="additionalInformationForm">
    <h2 i18n>{{title}}</h2>
    <mat-form-field class="new-exp-form-field">
      <mat-label i18n>{{fieldLabel1}}</mat-label>
      <input matInput type="text" formControlName="fieldValue1"
        placeholder="Enter {{fieldLabel1}}..." i18n-placeholder/>
      <button *ngIf="fieldValue1" matSuffix mat-icon-button (click)="clearFieldValue1()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error
        *ngIf="!fieldValue1" i18n>{{fieldLabel1}} is mandatory.</mat-error>
      <mat-error
        *ngIf="fieldValue1.length > fieldLabel1MaxLength"
        i18n>{{fieldLabel1}} longer than {{fieldLabel1MaxLength}}.</mat-error>
      <mat-hint align="end">{{fieldValue1.length}}/{{fieldLabel1MaxLength}}</mat-hint>
    </mat-form-field>
    <mat-form-field class="new-exp-form-field">
      <mat-label i18n>{{fieldLabel2}}</mat-label>
      <input matInput type="text" formControlName="fieldValue2"
        placeholder="Enter {{fieldLabel2}}..." i18n-placeholder/>
      <button *ngIf="fieldValue2" matSuffix mat-icon-button (click)="clearFieldValue2()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error
        *ngIf="!fieldValue2" i18n>{{fieldLabel2}} is mandatory.</mat-error>
      <mat-error
        *ngIf="fieldValue2.length > fieldLabel2MaxLength"
        i18n>{{fieldLabel2}} longer than {{fieldLabel2MaxLength}}.</mat-error>
      <mat-hint align="end">{{fieldValue2.length}}/{{fieldLabel2MaxLength}}</mat-hint>
    </mat-form-field>
    <mat-form-field class="new-exp-form-field">
      <mat-label i18n>{{fieldLabel3}} ($)</mat-label>
      <input matInput type="number" formControlName="fieldValue3"
        placeholder="Enter {{fieldLabel3}} ($) ..." i18n-placeholder min="1" step="0.01"/>
      <mat-error *ngIf="additionalInformationForm.controls.fieldValue3.invalid"
        i18n>{{fieldLabel3}} must be a positive number.</mat-error>
    </mat-form-field>
    <button class="input-btn" (click)="navigate()"
      mat-raised-button color="accent" i18n>Next</button>
  </form>
</div>

