<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <div>
    <ng-container *ngIf="kpiFileInfo; else planSelection">
      <h2 i18n>Input Files</h2>
      <mat-card class="view-experiment-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-title>KPI File</mat-card-title>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle i18n>Filename</mat-card-subtitle>
                <p>{{kpiFileName | longStringTruncate}}</p>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
          <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle i18n>Size</mat-card-subtitle>
                <p>{{kpiFileSize}}</p>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
          <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle i18n>First sample date</mat-card-subtitle>
                <p>{{kpiFirstSampleDate}}</p>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
          <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle i18n>Last sample date</mat-card-subtitle>
                <p>{{kpiLastSampleDate}}</p>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
  <div>
    <ng-template #planSelection>
      <h2 i18n>Selected Plan</h2>
      <mat-card class="view-experiment-last-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>Selected Plan</mat-card-subtitle>
            <p>{{selectedPlan}}</p>
          </mat-card-title-group>
        </mat-card-header>
      </mat-card>
    </ng-template>
  </div>
  <div>
    <h2 i18n>Test Groups</h2>
    <mat-card class="view-experiment-last-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Test Groups</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-listbox disabled>
          <mat-chip-option *ngFor="let testGroup of testGroups">{{testGroup}}</mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <h2 i18n>Budget Simulation Parameters</h2>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Estimated Incremental CPA</mat-card-subtitle>
          <p>{{icpa}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Durations</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-listbox disabled>
          <mat-chip-option *ngFor="let duration of durations">{{duration}}</mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
    <mat-card class="view-experiment-last-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Uplifts</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-listbox disabled>
          <mat-chip-option *ngFor="let uplift of uplifts">{{uplift}}</mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
  </div>
</div>