<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div class="new-exp-input-fields new-exp-text-style">
  <h2>Impact Measurement</h2>
  <p i18n>Create a new Impact Measurement for an existing A/B experiment.</p>
  <h2 i18n>Experiment Details</h2>
  <app-new-experiment-description (nameModified)="storeExperimentName($event)"
    (descriptionModified)="storeExperimentDescription($event)"/>
  <!-- Analysis method -->
  <h2 i18n>Analysis Method</h2>
  <mat-form-field class="new-exp-form-field">
    <mat-label i18n>Analysis Method</mat-label>
    <mat-select
      (selectionChange)="showOrHideDiDSection($event.value); clearKPIFileDatesTestControlGroups()"
      [matTooltip]="helpMessagesService.newImpactMeasurementPage.SELECT_ANALYSIS_METHOD"
      matTooltipClass="max-width-tooltip" [(value)]="selectedMethod" required>
      <mat-option *ngFor="let method of methods" [value]="method">{{method}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="customized-multi-control-button-container" *ngIf="showCIFields">
    <mat-slide-toggle [(ngModel)]="isMultipleControlGroupsModeEnabled"
      (change)="clearKPIFileDatesTestControlGroups()"
      i18n>Multiple Control Groups/Covariates</mat-slide-toggle>
  </div>
  <h2 i18n>Input File</h2>
  <app-file-picker [inputFileLabel]="'KPI File'" #filePicker
    (fileSelected)="storeTestGroupsSelection($event); storeFirstAndLastDates($event)"
    [matTooltip]="helpMessagesService.newImpactMeasurementPage.FILE_PICKER_KPI"
    matTooltipClass="max-width-tooltip" />
  <app-file-preview [fileToView]="kpiFile" [isChartEnable]="true"/>
  <!-- Test groups -->
  <h2 i18n>Test Groups</h2>
  <mat-form-field class="new-exp-form-field">
    <mat-label i18n>Test Groups</mat-label>
    <mat-select #selectTestGroup multiple [(value)]="selectedTestGroups"
      [matTooltip]="helpMessagesService.experimentCommonInputFields.SELECT_TEST_GROUPS">
      <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="
        toggleAllSelection()" i18n>Select All</mat-checkbox>
        <mat-select-trigger>
          <mat-chip-listbox>
            <mat-chip *ngFor="let selectedTestGroup of selectedTestGroups"
              [removable]="true" (removed)="onTestGroupRemoved(selectedTestGroup)">
              {{selectedTestGroup}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-listbox>
      </mat-select-trigger>
      <mat-option *ngFor="let testGroup of testGroupsSelection" [value]="testGroup"
        (click)="updateAllSelected()">
        {{testGroup}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Analysis parameters -->
  <h2 i18n>Analysis Parameters</h2>
  <div class="customized-date-button-container">
    <mat-slide-toggle [(ngModel)]="isCustomizedDateSelection" (change)="clearDateSelection()">Customize Pre-Experiment
      Dates</mat-slide-toggle>
  </div>
  <ng-container *ngIf="isCustomizedDateSelection">
    <mat-form-field class="new-exp-form-field">
      <mat-label>Pre-Experiment Start Date</mat-label>
      <input matInput [min]="kpiFirstDate" [max]="kpiLastDate" [matDatepicker]="pickerForPreExperimentStart"
        [(ngModel)]="preExperimentStartDate" required>
      <mat-datepicker-toggle matIconSuffix [for]="pickerForPreExperimentStart"></mat-datepicker-toggle>
      <mat-datepicker #pickerForPreExperimentStart></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="new-exp-form-field">
      <mat-label>Pre-Experiment End Date</mat-label>
      <input matInput [min]="kpiFirstDate" [max]="kpiLastDate" [matDatepicker]="pickerForPreExperimentEnd"
        [(ngModel)]="preExperimentEndDate" required>
      <mat-datepicker-toggle matIconSuffix [for]="pickerForPreExperimentEnd"></mat-datepicker-toggle>
      <mat-datepicker #pickerForPreExperimentEnd></mat-datepicker>
    </mat-form-field>
  </ng-container>
  <mat-form-field class="new-exp-form-field">
    <mat-label>Experiment Start Date</mat-label>
    <input matInput [min]="kpiFirstDate" [max]="kpiLastDate" [matDatepicker]="pickerForExperimentStart"
      [(ngModel)]="experimentStartDate" required
      [matTooltip]="helpMessagesService.newImpactMeasurementPage.INPUT_EXP_START_DATE"
      matTooltipClass="max-width-tooltip">
    <mat-datepicker-toggle matIconSuffix [for]="pickerForExperimentStart"></mat-datepicker-toggle>
    <mat-datepicker #pickerForExperimentStart></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="new-exp-form-field">
    <mat-label>Experiment End Date</mat-label>
    <input matInput [min]="kpiFirstDate" [max]="kpiLastDate" [matDatepicker]="pickerForExperimentEnd"
      [(ngModel)]="experimentEndDate" required>
    <mat-datepicker-toggle matIconSuffix [for]="pickerForExperimentEnd"></mat-datepicker-toggle>
    <mat-datepicker #pickerForExperimentEnd></mat-datepicker>
  </mat-form-field>
  <ng-container *ngIf="showCIFields">
    <div class="new-exp-form-field">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Advanced Settings </mat-panel-title>
          <mat-panel-description class="mat-expansion-panel-header-description">
            <mat-icon>settings</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="right-margin">
          <app-numeric-input-field [fieldLabel]="'Number of Seasons'"
            [minVal]="minimumNumberOfSeasons" [maxVal]="maximumNumberOfSeasons"
            [step]="1" [defaultValue]="numberOfSeasonsDefaultValue" [fieldMustBeInteger]="true"
            (fieldModified)="storeNumberOfSeasons($event)"/>
        </div>
        <div class="right-margin">
        <mat-form-field class="new-exp-form-field">
          <mat-label i18n>Confidence Interval</mat-label>
            <mat-select [(value)]="confidenceInterval" required>
              <mat-option *ngFor="let confidenceIntervalValue of confidenceIntervalValues"
                [value]="confidenceIntervalValue/100">{{confidenceIntervalValue}}%</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <br>
  </div>
  </ng-container>
  <!-- Covariates Selector for multi-control mode -->
  <ng-container
      *ngIf="isMultipleControlGroupsModeEnabled && showCIFields && selectedTestGroups.length">
    <h2 i18n>Control Groups and Covariates</h2>
    <app-covariates-selector *ngFor="let testGroup of selectedTestGroups"
      [testGroupLabel]="testGroup"
      [covariatesList]="allCovariatesInKPIFile"
      [selectedCovariatesFormControl]="testGroupCovariatesSelectionMappings.get(testGroup)!"/>
  </ng-container>
  <!-- Estimated costs for DID -->
  <ng-container *ngIf="showDiDFields">
    <h2 i18n *ngIf="selectedTestGroups.length>0">Estimated costs</h2>
    <app-numeric-input-field *ngFor="let testGroup of selectedTestGroups" [fieldLabel]="testGroup"
      [matTooltip]="helpMessagesService.newImpactMeasurementPage.INPUT_DID_ESTIMATED_COST"
      matTooltipClass="max-width-tooltip" [minVal]="0.01" [maxVal]="1000000" [step]="0.01"
      [defaultValue]="estimatedCostDefaultValue"
      (fieldModified)="storeEstimatedCost($event)"/>
  </ng-container>
  <span class="input-btn">
    <button mat-flat-button color="accent" (click)="submitImpactMeasurementExperiment()"
      [matTooltip]="helpMessagesService.newImpactMeasurementPage.BUTTON_SUBMIT"
      matTooltipClass="max-width-tooltip" i18n>Submit</button>
  </span>
</div>