/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ENTER, SPACE, TAB} from '@angular/cdk/keycodes';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatSnackBar} from '@angular/material/snack-bar';

/** Component used by users to input list of numeric values. */
@Component({
  selector: 'app-numeric-values-input-field',
  templateUrl: './numeric-values-input-field.component.html',
  styleUrls: ['./numeric-values-input-field.component.scss'],
})
export class NumericValuesInputFieldComponent implements OnInit {
  @Input() fieldLabel = '';
  @Input() placeHolderLabel = '';
  @Input() valuesMustBeInteger = true;
  @Input() defaultValues: number[] = [];
  @Input() maxVal = 100;
  @Input() minVal = 1;
  @Input() maxLength = 10;
  @Input() addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, SPACE, TAB] as const;

  readonly snackBarDuration = 2000;
  readonly snackBarActionLabel = 'OK';
  // Only a few elements will be stored in values so sorting at each insertion
  // should not be a problem (switching from set to sortedSet datastructure if
  // the expected number of elements increases).
  values = new Set<number>();
  @Output() readonly valuesChanged = new EventEmitter<Set<number>>();

  valuesForm = new FormGroup({valuesList: new FormControl()});

  ngOnInit() {
    this.initSetWithValues(this.defaultValues);
  }

  private numericSort(n1: number, n2: number) {
    return n1 - n2;
  }

  initSetWithValues(startingValues: number[]): void {
    // Sorting initial values in case they are not pre-sorted before populating
    // the set.
    this.values = new Set<number>(startingValues.sort(this.numericSort));
    this.valuesChanged.emit(this.values);
    this.valuesForm = this.formBuilder.group({
      valuesList: [
        this.values,
        (formControl: FormControl): ValidationErrors | null => {
          return this.isEmpty() ? {valuesList: {valid: false}} : null;
        },
      ],
    });
  }

  constructor(
    readonly warningSnackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
  ) {}

  private openSnackBarWithMessage(message: string): void {
    this.warningSnackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDuration,
    });
  }

  add(event: MatChipInputEvent): void {
    // This is required to avoid the insertion events of empty chips triggered
    // when addOnBlur is true.
    if (event.value === '') {
      return;
    }

    const value = Number(event.value);
    event.chipInput!.clear();

    if (!value && value !== 0) {
      this.openSnackBarWithMessage('Values must be numeric.');
      return;
    }
    if (this.valuesMustBeInteger && !Number.isInteger(value)) {
      this.openSnackBarWithMessage('Values must be integer.');
      return;
    }
    if (value < this.minVal) {
      this.openSnackBarWithMessage(
        `Values cannot be smaller than ${this.minVal}.`,
      );
      return;
    }
    if (value > this.maxVal) {
      this.openSnackBarWithMessage(
        `Values cannot be bigger than ${this.maxVal}.`,
      );
      return;
    }
    if (this.values.size === this.maxLength) {
      this.openSnackBarWithMessage(
        `Cannot store more than ${this.maxLength} values.`,
      );
      return;
    }
    if (this.values.has(value)) {
      this.openSnackBarWithMessage(
        `${value} already in the list, cannot add duplicate values.`,
      );
      return;
    }

    this.values.add(value);
    // sorting the set after each insertion to maintain the set order.
    this.values = new Set<number>([...this.values].sort(this.numericSort));
    this.valuesChanged.emit(this.values);
    this.valuesForm.controls.valuesList.setValue(this.values);
  }

  remove(value: number): void {
    if (this.values.has(value)) {
      this.values.delete(value);
      this.valuesChanged.emit(this.values);

      if (this.values.size === 0) {
        this.valuesForm.controls.valuesList.setErrors({
          valuesList: {valid: false},
        });
        this.valuesForm.markAllAsTouched();
        this.valuesForm.updateValueAndValidity();
      }
    }
  }

  isEmpty(): boolean {
    return this.values.size === 0;
  }
}
