/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import * as routing from '../../common/routes';
import * as metadataUtil from './additional-information-util';
import * as labels from './labels';

/**
 * Component for inputing additional information when creating an experiment.
 */
@Component({
  standalone: false,
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent {
  /**
   * The labels/field names are extracted from labels.ts file.
   * The content of the variables stored in this file is going to be modified
   * when building the application allowing flexibility.
   */
  readonly title = labels.ADDITIONAL_INFORMATION_TITLE;
  readonly fieldLabel1 = labels.ADDITIONAL_INFORMATION_LABEL_FIELD1;
  readonly fieldLabel2 = labels.ADDITIONAL_INFORMATION_LABEL_FIELD2;
  readonly fieldLabel3 = labels.ADDITIONAL_INFORMATION_LABEL_FIELD3;

  readonly fieldLabel1MaxLength = 100;
  readonly fieldLabel2MaxLength = 100;

  readonly snackBarInvalidInputError = 'Some input fields are incorrect.';
  readonly snackBarUnexpectedError =
    'Something went wrong when processing the input.';

  processAdditionalInformation = metadataUtil.processAdditionalInformation;

  additionalInformationForm = this.formBuilder.group({
    fieldValue1: [
      '',
      [Validators.required, Validators.maxLength(this.fieldLabel1MaxLength)],
    ],
    fieldValue2: [
      '',
      [Validators.required, Validators.maxLength(this.fieldLabel2MaxLength)],
    ],
    fieldValue3: ['', [Validators.min(0)]],
  });

  constructor(
    private readonly router: Router,
    private formBuilder: FormBuilder,
    readonly snackBar: MatSnackBar,
  ) {
    this.fieldValue1 = '';
    this.fieldValue2 = '';
    this.fieldValue3 = '';
  }

  navigate(): void {
    if (this.additionalInformationForm.invalid) {
      this.snackBar.open(this.snackBarInvalidInputError, 'Dismiss');
      return;
    }
    if (
      !this.processAdditionalInformation(
        this.fieldValue1,
        this.fieldValue2,
        this.fieldValue3,
      )
    ) {
      this.snackBar.open(this.snackBarUnexpectedError, 'Dismiss');
      return;
    }
    const metricsObject = {
      [metadataUtil.f1]: this.fieldValue1,
      [metadataUtil.f2]: this.fieldValue2,
      [metadataUtil.f3]: this.fieldValue3,
    };
    window.localStorage.setItem('metrics', JSON.stringify(metricsObject));
    this.router.navigate([routing.NEW_EXPERIMENT_URL], {
      queryParams: {
        [metadataUtil.f1]: this.fieldValue1,
        [metadataUtil.f2]: this.fieldValue2,
        [metadataUtil.f3]: this.fieldValue3,
      },
      skipLocationChange: true,
    });
  }

  get fieldValue1(): string {
    return this.additionalInformationForm.controls.fieldValue1.value ?? '';
  }

  set fieldValue1(value: string) {
    this.additionalInformationForm.controls.fieldValue1.setValue(value);
  }

  get fieldValue2(): string {
    return this.additionalInformationForm.controls.fieldValue2.value ?? '';
  }

  set fieldValue2(value: string) {
    this.additionalInformationForm.controls.fieldValue2.setValue(value);
  }

  get fieldValue3(): string {
    return this.additionalInformationForm.controls.fieldValue3.value ?? '';
  }

  set fieldValue3(value: string) {
    this.additionalInformationForm.controls.fieldValue3.setValue(value);
  }

  clearFieldValue1() {
    this.fieldValue1 = '';
  }

  clearFieldValue2() {
    this.fieldValue2 = '';
  }
}
