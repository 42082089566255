/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input} from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

import {TopLevelSpec} from 'vega-lite';
import {BudgetSimulationGraphTaskResult} from '../../../model/budget-simulation-table-graph-result';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {HelpMessagesService} from '../../../service/help-messages.service';

/** Component to display Budget Simulation Graphs. */
@Component({
  standalone: false,
  selector: 'app-budget-simulation-graphs-results',
  templateUrl: './budget-simulation-graphs-results.component.html',
  styleUrls: ['./budget-simulation-graphs-results.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class BudgetSimulationGraphsResultsComponent {
  @Input({required: true})
  budgetSimulationGraphsResult!: BudgetSimulationGraphTaskResult;
  @Input({required: true}) experimentId = '';

  constructor(protected readonly helpMessagesService: HelpMessagesService) {}

  get upliftedGraphs(): TopLevelSpec {
    // Cast the JSON object to TopLevelSpec for Vega-Lite plot.
    return this.budgetSimulationGraphsResult
      .causal_impact_uplifted_graphs as unknown as TopLevelSpec;
  }
  get nonUpliftedGraphs(): TopLevelSpec {
    // Cast the JSON object to TopLevelSpec for Vega-Lite plot.
    return this.budgetSimulationGraphsResult
      .causal_impact_non_uplifted_graphs as unknown as TopLevelSpec;
  }

  protected sendImpactMeasurementAction(): StartingPointEnum {
    return StartingPointEnum.IMPACT_MEASUREMENT;
  }
}
