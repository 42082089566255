/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, Input, OnInit, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, interval} from 'rxjs';
import {filter, map, switchMap, takeWhile} from 'rxjs/operators';

import * as constants from '../../common/constants';
import {
  experimentNameAndDescriptionAreValid,
  openSnackBarWithMessage,
} from '../../common/utils';
import {Experiment} from '../../model/experiment';
import {Task} from '../../model/task';
import {TaskStatusEnum} from '../../model/task-status-enum';
import {BusinessLogicService} from '../../service/business-logic.service';

/** Component to view an existing experiment. */
@Component({
  standalone: false,
  selector: 'app-experiment-and-task-details',
  templateUrl: './experiment-and-task-details.component.html',
  styleUrls: ['./experiment-and-task-details.component.scss'],
})
export class ExperimentAndTaskDetailsComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  readonly dateDisplayFormat = 'medium';
  static readonly EXECUTION_TIME_REFRESH_RATE = 1000;
  editMode = false;

  readonly snackBarNameDescriptionNothingToSaveMessage = 'Nothing to save.';
  readonly snackBarNameDescriptionEditSuccessMessage =
    'Experiment name and description updated successfully.';
  readonly snackBarNameDescriptionEditFailureMessage =
    'Experiment name and description update failed.';
  readonly snackBarNameDescriptionEditTaskRunningErrorMessage =
    'Cannot update experiment name and description while a task is running.';

  @Input({required: true}) experiment!: Experiment;
  @Input({required: true}) task!: Task;

  experimentId!: string;
  experimentName!: string;
  experimentCreationDate!: Date;
  experimentDescription!: string;

  editedExperimentName = '';
  editedExperimentDescription = '';

  taskId!: string;
  taskCreationDate!: Date;
  taskExecutionTime!: Observable<string>;

  private readonly taskStatusForDisplayMappings = new Map<string, string>();

  constructor(
    readonly editSnackBar: MatSnackBar,
    private businessLogicService: BusinessLogicService,
  ) {
    this.taskStatusForDisplayMappings.set(TaskStatusEnum.QUEUED, 'queued...');
    this.taskStatusForDisplayMappings.set(
      TaskStatusEnum.IN_PROGRESS,
      'processing...',
    );
    this.taskStatusForDisplayMappings.set(TaskStatusEnum.FAILURE, 'failed');
    this.taskStatusForDisplayMappings.set(
      TaskStatusEnum.CANCELLED,
      'cancelled',
    );
    this.taskStatusForDisplayMappings.set(
      TaskStatusEnum.COMPLETED,
      'completed',
    );
  }

  ngOnInit() {
    this.experimentId = this.experiment.id;
    this.experimentName = this.experiment.name;
    this.experimentCreationDate = this.experiment.created;
    this.experimentDescription = this.experiment.description;

    this.taskId = this.task.taskId;
    this.taskCreationDate = this.task.created;

    this.taskExecutionTime = interval(
      ExperimentAndTaskDetailsComponent.EXECUTION_TIME_REFRESH_RATE,
    ).pipe(
      map(() => this.updateTaskExecutionDuration()),
      takeWhile(() => this.isProgressBarVisible()),
    );
  }

  getTaskStatusForDisplay(): string {
    return (
      this.taskStatusForDisplayMappings.get(this.task.taskStatus) ?? 'Unknown'
    );
  }

  styleTaskStatus(): {color: string; fontWeight: string} | null {
    if (
      this.task.taskStatus === TaskStatusEnum.CANCELLED ||
      this.task.taskStatus === TaskStatusEnum.FAILURE
    ) {
      return {color: '#f44336', fontWeight: '500'};
    }
    if (this.task.taskStatus === TaskStatusEnum.COMPLETED) {
      return {color: '#49be25', fontWeight: '500'};
    }
    return null;
  }

  isProgressBarVisible(): boolean {
    return (
      this.task.taskStatus === TaskStatusEnum.IN_PROGRESS ||
      this.task.taskStatus === TaskStatusEnum.QUEUED
    );
  }

  closeExperimentDetailsEditMode() {
    this.editMode = false;
    this.editedExperimentName = this.experimentName;
    this.editedExperimentDescription = this.experimentDescription;
  }

  storeEditedExperimentDescription(editedDescription: string) {
    this.editedExperimentDescription = editedDescription;
  }

  storeEditedExperimentName(editedExperimentName: string) {
    this.editedExperimentName = editedExperimentName;
  }

  flipExperimentDetailsEditMode() {
    this.editMode = !this.editMode;
    this.editedExperimentName = this.experimentName;
    this.editedExperimentDescription = this.experimentDescription;
  }

  private openSnackBarWithMessage(message: string): void {
    openSnackBarWithMessage(message, this.editSnackBar);
  }

  private hasRunningTask(experiment: Experiment): boolean {
    const tasks = Object.values(experiment.tasks).filter(
      (task): task is Task => task !== undefined,
    );
    const hasRunningTask = tasks.some(
      (task) =>
        task.taskStatus === TaskStatusEnum.IN_PROGRESS ||
        task.taskStatus === TaskStatusEnum.QUEUED,
    );
    if (hasRunningTask) {
      this.openSnackBarWithMessage(
        this.snackBarNameDescriptionEditTaskRunningErrorMessage,
      );
    }
    return hasRunningTask;
  }

  saveEditedExperimentDetails() {
    if (
      !experimentNameAndDescriptionAreValid(
        this.editedExperimentName,
        this.editedExperimentDescription,
      )
    ) {
      this.openSnackBarWithMessage(
        constants.SNACKBAR_INVALID_NAME_DESCRIPTION_ERROR_MESSAGE,
      );
      return;
    }

    if (
      this.editedExperimentName === this.experimentName &&
      this.editedExperimentDescription === this.experimentDescription
    ) {
      this.openSnackBarWithMessage(
        this.snackBarNameDescriptionNothingToSaveMessage,
      );
      return;
    }
    this.businessLogicService
      .getExperiment(this.experimentId)
      .pipe(
        filter((experiment: Experiment) => {
          return !this.hasRunningTask(experiment);
        }),
        switchMap((nonRunningExperiment: Experiment) => {
          const experimentToEdit = nonRunningExperiment;
          experimentToEdit.name = this.editedExperimentName;
          experimentToEdit.description = this.editedExperimentDescription;
          return this.businessLogicService.saveExperiment(experimentToEdit);
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe({
        next: () => {
          this.experimentName = this.editedExperimentName;
          this.experimentDescription = this.editedExperimentDescription;
          this.openSnackBarWithMessage(
            this.snackBarNameDescriptionEditSuccessMessage,
          );
          this.closeExperimentDetailsEditMode();
        },
        error: () => {
          this.openSnackBarWithMessage(
            this.snackBarNameDescriptionEditFailureMessage,
          );
        },
      });
  }

  updateTaskExecutionDuration(endTime?: Date): string {
    const currentTime = endTime ?? new Date();
    const secondsInOneDay = 86_400;
    const secondsInOneHour = 3600;
    const secondsInOneMinute = 60;

    const executionDurationInSeconds = Math.floor(
      (new Date(currentTime).getTime() -
        new Date(this.taskCreationDate).getTime()) /
        1000,
    );

    const days = Math.floor(executionDurationInSeconds / secondsInOneDay);
    const hours = Math.floor(
      (executionDurationInSeconds % secondsInOneDay) / secondsInOneHour,
    );
    const minutes = Math.floor(
      (executionDurationInSeconds % secondsInOneHour) / secondsInOneMinute,
    );
    const seconds = Math.floor(executionDurationInSeconds % secondsInOneMinute);

    if (executionDurationInSeconds < secondsInOneMinute) {
      return `${seconds}s`;
    }
    if (executionDurationInSeconds < secondsInOneHour) {
      return `${minutes}m ${seconds}s`;
    }
    if (executionDurationInSeconds < secondsInOneDay) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
}
