/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * To be ONLY used for Google internal EE4E Instance.
 * Replace client/src/app/app.component.ts by this file before building
 * the internal instance.
 */

import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {filter} from 'rxjs';

declare const gtag: Function;

/**
 * The root component of the app.
 *
 * Renders the router-outlet essential for handling dynamic routing.
 */
@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ee4e_web_client';
  ee4e_url = window.location.href;

  constructor(private readonly router: Router) {
    history.pushState(null, '', this.ee4e_url);
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event: unknown) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.sendPageView((event as NavigationEnd).url);
      });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.router.navigate([''], {skipLocationChange: true});
    history.pushState(null, '', this.ee4e_url);
  }

  private sendPageView(url: string) {
    let urlWithoutTaskId = url;
    const subPaths = decodeURI(url).split('/');
    // Removes the taskId from the url.
    if (subPaths.length === 4) {
      urlWithoutTaskId = `/${subPaths[1]}/${subPaths[3]}`;
    }
    gtag('event', 'page_view', {
      'page_location': urlWithoutTaskId,
    });
  }
}
