<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <app-mape-and-impact-summary-card
  [rawCausalImpactSummary]="budgetSimulationGraphsResult.causal_impact_summary"
  [mape]="budgetSimulationGraphsResult.causal_impact_mape"/>
  <div class="view-result-entry">
    <h2 [matTooltip]="helpMessagesService.budgetSimulationResultsPage.GRAPH_CI_WITH_UPLIFT"
      matTooltipClass="unset-max-width-tooltip" i18n>Causal Impact Graphs With Pseudo-Uplift</h2>
    <app-vega-chart [spec]="upliftedGraphs"/>
  </div>
  <div class="view-result-entry">
    <h2 [matTooltip]="helpMessagesService.budgetSimulationResultsPage.GRAPH_CI_WITHOUT_UPLIFT"
    matTooltipClass="unset-max-width-tooltip" i18n>Causal Impact Graphs Without Pseudo-Uplift</h2>
    <app-vega-chart [spec]="nonUpliftedGraphs"/>
  </div>
  <div class="view-result-container">
    <app-routing-button class="left-button"[buttonLabel]="'Impact Measurement'"
      [matTooltip]="helpMessagesService.budgetSimulationResultsPage.BUTTON_GO_TO_IMPACT_MEASUREMENT"
      matTooltipClass="max-width-tooltip" [experimentId]="experimentId"
      [action]="sendImpactMeasurementAction()"/>
  </div>
</div>