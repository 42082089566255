<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<h2 mat-dialog-title i18n>Overwrite experiment</h2>
<mat-dialog-content i18n>
  Are you sure you want to overwrite the results below?
  <ul>
    <li *ngFor="let taskName of displayTaskNames">{{taskName}}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close i18n>Cancel</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary"
    cdkFocusInitial i18n>Overwrite</button>
</mat-dialog-actions>
