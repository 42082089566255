/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Injectable} from '@angular/core';
import {
  FromWorkerEvent,
  ToWorkerEvent,
  WORKER_EVENT_START,
  WORKER_EVENT_STOP,
} from '../task-status.worker-events';

/** A service wrapper that holds a task updater worker instance.  */
@Injectable({
  providedIn: 'root',
})
export class TaskWorkerClient {
  private readonly worker: Worker = TaskWorkerClient.createWorker();

  static createWorker(): Worker {
    return new Worker(new URL('../task-status.worker', import.meta.url));
  }

  /** Starts checking task status periodically. */
  start() {
    this.sendMessage({type: WORKER_EVENT_START});
  }

  /** Stops checking task status. */
  stop() {
    this.sendMessage({type: WORKER_EVENT_STOP});
  }

  /**
   * Registers a callback function for receiving task status update.
   *
   * @param workerNotificationHandler A callback function that handles the task status update.
   */
  register(
    workerNotificationHandler: (message: MessageEvent<FromWorkerEvent>) => void,
  ) {
    if (this.worker) {
      this.worker.onmessage = workerNotificationHandler;
    }
  }

  private sendMessage(message: ToWorkerEvent) {
    this.worker.postMessage(message);
  }
}
