/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';

import {formatBytes} from '../../../common/utils';
import {FileInfo} from '../../../model/file-info';

import {BudgetTableParameter} from '../../../model/budget-table-parameter';
import {StartingPointEnum} from '../../../model/starting-point-enum';

/**
 * Component displaying budget simulation input parameters when viewing an
 * existing Budget Simulation experiment.
 */
@Component({
  standalone: false,
  selector: 'app-budget-simulation-input-files-and-parameters',
  templateUrl: './budget-simulation-input-files-and-parameters.component.html',
  styleUrls: ['./budget-simulation-input-files-and-parameters.component.scss'],
})
export class BudgetSimulationInputFilesAndParametersComponent
  implements OnInit
{
  testGroups: string[] = [];
  icpa!: number;
  uplifts: number[] = [];
  durations: number[] = [];
  @Input({required: true}) selectedPlan: string | null = null;
  @Input({required: true}) startingPoint!: StartingPointEnum;
  @Input({required: true})
  budgetSimulationParameters!: BudgetTableParameter;
  @Input({required: true}) kpiFileInfo: FileInfo | null = null;
  kpiFileName!: string;
  kpiFileSize!: string;
  kpiFirstSampleDate!: string;
  kpiLastSampleDate!: string;

  ngOnInit() {
    if (this.startingPoint === StartingPointEnum.SPLITTING) {
      // KPI file is not expected to input when starting from splitting.
      // Remove it if given.
      this.kpiFileInfo = null;
    }
    this.testGroups = this.budgetSimulationParameters.test_groups;
    this.icpa = this.budgetSimulationParameters.icpa;
    this.durations = this.budgetSimulationParameters.durations;
    this.uplifts = this.budgetSimulationParameters.uplifts;
    if (this.kpiFileInfo) {
      this.kpiFileName = this.kpiFileInfo.fileName;
      this.kpiFileSize = formatBytes(this.kpiFileInfo.size, 1);
      if (this.kpiFileInfo.extra) {
        const firstSampleDate = this.kpiFileInfo.extra[
          'firstSampleDate'
        ] as Date;
        this.kpiFirstSampleDate = firstSampleDate.toISOString().split('T')[0];
        const lastSampleDate = this.kpiFileInfo.extra['lastSampleDate'] as Date;
        this.kpiLastSampleDate = lastSampleDate.toISOString().split('T')[0];
      } else {
        this.kpiFirstSampleDate = '-';
        this.kpiLastSampleDate = '-';
      }
    }
  }
}
