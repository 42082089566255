/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import {catchError, of, switchMap} from 'rxjs';

import * as constants from '../../common/constants';
import {FilePickerComponent} from '../../common/file-picker/file-picker.component';
import * as routes from '../../common/routes';
import {CausalImpactAnalysisRequest} from '../../model/causal-impact-analysis-request';
import {Experiment} from '../../model/experiment';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {TaskTypeEnum} from '../../model/task-type-enum';
import {BusinessLogicService} from '../../service/business-logic.service';
import {ExperimentOverwriteGuardService} from '../../service/experiment-overwrite-guard.service';
import {FileReaderService} from '../../service/file-reader.service';
import {HelpMessagesService} from '../../service/help-messages.service';

/**
 * Component for transition to Impact Measurement from Splitting.
 */
@Component({
  standalone: false,
  selector: 'app-continue-experiment-to-impact-measurement',
  templateUrl: './continue-experiment-to-impact-measurement.component.html',
  styleUrls: ['./continue-experiment-to-impact-measurement.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class ContinueExperimentToImpactMeasurementComponent
  implements AfterViewInit
{
  /** Selected ResultSet (Plan), by default "Plan_1" (Result Set 0). */
  selectedResultSet = 0;
  allSelected = false;
  testGroupsSelection: string[] = [];
  selectedTestGroups: string[] = [];
  kpiFile: File | null = null;
  selectedMethod: string | null = null;
  protected estimatedCostsForms = new Map<string, FormGroup>();
  protected readonly estimatedCostDefaultValue = 1000;

  @ViewChild('selectTestGroup') select!: MatSelect;
  @ViewChild('filePicker') filePicker!: FilePickerComponent;

  protected readonly snackBarDuration = 2000;
  protected readonly snackBarActionLabel = 'OK';
  private errorMessage = '';
  protected readonly snackBarKpiFileErrorMessage =
    'A KPI file must be attached.';
  protected readonly snackBarSubmittingImpactMeasurementMessage =
    'Submitting impact measurement task to server.';
  protected readonly snackBarNoTestGroupsSelectedErrorMessage =
    'Please select at least one test group.';
  protected readonly snackBarNoStartDateSelectedErrorMessage =
    'Please select the start date of the experiment.';
  protected readonly snackBarNoAnalysisMethodSelectedErrorMessage =
    'Please select an analysis method.';
  protected readonly snackBarEstimatedCostsErrorMessage =
    'Some estimated costs for DiD are incorrect.';
  protected readonly snackBarInvalidStartDateSelectedErrorMessage =
    'The start date selected is invalid.';
  protected canDisplayPage = false;
  @Input({required: true}) experimentStartingPoint!: StartingPointEnum;
  experimentId = '';
  // The dates user selects for the experiment.
  preExperimentStartDate: Moment | null = null;
  preExperimentEndDate: Moment | null = null;
  experimentStartDate: Moment | null = null;
  experimentEndDate: Moment | null = null;

  // The first and last dates of KPI file uploaded for Splitting or Budget Simulation.
  kpiFirstDateForPreExperiment: Moment | null = null;
  kpiLastDateForPreExperiment: Moment | null = null;

  // The first and last dates of KPI file uploaded for Impact Measurement.
  kpiFirstDateForExperiment: Moment | null = null;
  kpiLastDateForExperiment: Moment | null = null;

  // The next date of kpiLastDateForPreExperiment.
  // Used as a valid date for the beginning of experimentStartDate and experimentEndDate.
  nextDateOfPreExperimentLastDate: Moment | null = null;

  protected readonly methods = ['Causal Impact', 'Difference-in-Differences'];
  protected showDiDFields = false;
  showCIFields = false;
  isMultipleControlGroupsModeEnabled = false;
  confidenceInterval = constants.DEFAULT_CONFIDENCE_INTERVAL;
  confidenceIntervalValues = constants.CONFIDENCE_INTERVAL_VALUES;
  numberOfSeasonsForm = new FormGroup({});
  readonly numberOfSeasonsDefaultValue = constants.DEFAULT_NUMBER_OF_SEASONS;
  readonly minimumNumberOfSeasons = constants.MINIMUM_NUMBER_OF_SEASONS;
  readonly maximumNumberOfSeasons = constants.MAXIMUM_NUMBER_OF_SEASONS;
  groupMembersToExclude = new Map<string, FormControl<string[] | null>>();
  isCustomizedDateSelection = false;

  allCovariatesInKPIFile: string[] = [];
  testGroupCovariatesSelectionMappings = new Map<
    string,
    FormControl<string[] | null>
  >();

  private readonly destroyedRef = inject(DestroyRef);

  constructor(
    private readonly route: ActivatedRoute,
    readonly snackBar: MatSnackBar,
    private readonly businessLogicService: BusinessLogicService,
    private router: Router,
    private readonly fileReaderService: FileReaderService,
    private readonly experimentOverwriteGuardService: ExperimentOverwriteGuardService,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {
    const action = this.route.snapshot.paramMap.get('action')!;
    this.experimentId = this.route.snapshot.paramMap.get('experimentId')!;

    if (action !== StartingPointEnum.IMPACT_MEASUREMENT) {
      this.errorMessage =
        'Action must be Impact Measurement.' + ` Received Action: ${action}.`;
      return;
    }

    this.businessLogicService
      .getExperiment(this.experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment | null) => {
        if (!experiment) {
          this.errorMessage = `Experiment: ${this.experimentId} not found.`;
          return;
        }
        // Judge the task type for the starting point.
        let taskTypeForStartingPoint;
        if (this.wasExperimentStartedFromSplitting()) {
          taskTypeForStartingPoint = TaskTypeEnum.SPLIT;
        } else if (this.wasExperimentStartedFromBudgetSimulation()) {
          taskTypeForStartingPoint = TaskTypeEnum.BUDGET_TABLE;
        } else {
          return;
        }
        const taskForStartingPoint = experiment.tasks[taskTypeForStartingPoint];
        if (!taskForStartingPoint) {
          this.errorMessage = `Task type: ${taskTypeForStartingPoint} does not exist.`;
          return;
        }
        // The following parts are applied when the task start with Impact Measurement.
        // Extract the first date in pre-experiment.
        this.kpiFirstDateForPreExperiment = moment(
          taskForStartingPoint.fileInfos.KPI?.extra?.['firstSampleDate'],
        );
        // Extract the last date in pre-experiment.
        this.kpiLastDateForPreExperiment = moment(
          taskForStartingPoint.fileInfos.KPI?.extra?.['lastSampleDate'],
        );
        // Calculate the next date of the pre-experiment last date.
        this.nextDateOfPreExperimentLastDate = this.kpiLastDateForPreExperiment
          .clone()
          .add(1, 'days');
      });

    this.canDisplayPage = true;
  }

  clearKPIFileDatesTestControlGroups(): void {
    if (this.wasExperimentStartedFromImpactMeasurement()) {
      this.allSelected = false;
      this.selectedTestGroups = [];
      this.kpiFile = null;
      this.filePicker.resetSelectedFile();
      this.testGroupsSelection = [];
      this.kpiFirstDateForExperiment = null;
      this.kpiLastDateForExperiment = null;
      this.clearDateSelection();
      this.allCovariatesInKPIFile = [];
      this.testGroupCovariatesSelectionMappings = new Map<
        string,
        FormControl<string[] | null>
      >();
    }
  }

  private initiateTestGroupCovariatesSelectionMappings(
    testGroups: string[],
  ): void {
    this.testGroupCovariatesSelectionMappings.clear();
    for (const testGroup of testGroups) {
      this.testGroupCovariatesSelectionMappings.set(
        testGroup,
        new FormControl<string[] | null>([]),
      );
    }
  }

  protected wasExperimentStartedFromSplitting(): boolean {
    return this.experimentStartingPoint === StartingPointEnum.SPLITTING;
  }

  protected wasExperimentStartedFromBudgetSimulation(): boolean {
    return this.experimentStartingPoint === StartingPointEnum.BUDGET_SIMULATION;
  }

  protected wasExperimentStartedFromImpactMeasurement(): boolean {
    return (
      this.experimentStartingPoint === StartingPointEnum.IMPACT_MEASUREMENT
    );
  }

  ngAfterViewInit() {
    if (this.errorMessage) {
      this.snackBar.open(this.errorMessage, this.snackBarActionLabel);
    }
  }

  protected storeResultSet(resultSet: number): void {
    this.selectedResultSet = resultSet;
  }

  protected storeTestGroups(testGroups: string[]): void {
    this.selectedTestGroups = testGroups;
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  updateAllSelected() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  protected onTestGroupRemoved(testGroup: string): void {
    this.select.options
      .filter((item: MatOption) => item.value === testGroup)
      .forEach((item: MatOption) => item.deselect());
    this.allSelected = false;
  }

  private openSnackBarWithMessage(message: string): void {
    this.snackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDuration,
    });
  }

  showOrHideDiDSection(value: string) {
    if (value === 'Difference-in-Differences') {
      this.showDiDFields = true;
      this.showCIFields = false;
    } else if (value === 'Causal Impact') {
      this.showCIFields = true;
      this.showDiDFields = false;
    } else {
      this.showCIFields = false;
      this.showDiDFields = false;
    }
  }

  protected storeKpiFile(kpiFile: File | null): void {
    this.kpiFile = kpiFile;
  }

  storeTestGroupsSelection(kpiFile: File | null): void {
    // Reset a checkbox and options.
    this.allSelected = false;
    this.selectedTestGroups = [];
    if (!kpiFile) {
      this.kpiFile = null;
      this.initiateTestGroupCovariatesSelectionMappings([]);
      this.allCovariatesInKPIFile = [];
      this.isMultipleControlGroupsModeEnabled = false;
      return;
    }

    const isSingleControlGroupsModeEnabled = !(
      this.selectedMethod === 'Causal Impact' &&
      this.isMultipleControlGroupsModeEnabled
    );

    this.fileReaderService
      .extractAllTestGroups(kpiFile, ',', isSingleControlGroupsModeEnabled)
      .pipe(
        catchError((error) => {
          this.openSnackBarWithMessage(error.message);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe((testGroups) => {
        this.testGroupsSelection = testGroups;
        // Create a form control for each possible test group.
        this.initiateTestGroupCovariatesSelectionMappings(testGroups);
        this.kpiFile = kpiFile;
      });

    if (!isSingleControlGroupsModeEnabled) {
      this.fileReaderService
        .extractAllCovariates(kpiFile)
        .pipe(
          catchError((error) => {
            this.openSnackBarWithMessage(error.message);
            return of([]);
          }),
        )
        .subscribe((covariates) => {
          this.allCovariatesInKPIFile = covariates;
        });
    }
  }

  storeFirstAndLastDates(kpiFile: File | null): void {
    if (!kpiFile) {
      this.kpiFirstDateForExperiment = null;
      this.kpiLastDateForExperiment = null;
      this.preExperimentStartDate = null;
      this.preExperimentEndDate = null;
      this.experimentStartDate = null;
      this.experimentEndDate = null;
      this.isCustomizedDateSelection = false;
      return;
    }
    this.fileReaderService
      .extractFirstAndLastDates(kpiFile)
      .pipe(
        catchError((error) => {
          this.openSnackBarWithMessage(error.message);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe((dates) => {
        this.kpiFirstDateForExperiment = moment.utc(dates[0]);
        this.kpiLastDateForExperiment = moment.utc(dates[1]);
        // Set the last date in KPI data to a default experiment end date.
        if (!this.isCustomizedDateSelection && !this.experimentEndDate) {
          this.experimentEndDate = this.kpiLastDateForExperiment;
        }
        // In the case of an experiment starting at impact measurement,
        // there is no pre-period. The first date in the KPI for the experiment
        // is used as a proxy for the next date of pre-experiment last date.
        if (!this.nextDateOfPreExperimentLastDate) {
          this.nextDateOfPreExperimentLastDate = this.kpiFirstDateForExperiment;
        }
      });
  }

  protected storeGroupMembersToExclude(
    groupMembersToExclude: Map<string, FormControl<string[] | null>>,
  ): void {
    this.groupMembersToExclude = groupMembersToExclude;
  }

  protected storeEstimatedCost(
    estimatedCostRecord: Record<string, FormGroup>,
  ): void {
    const parameterName = Object.keys(estimatedCostRecord)[0] as string;
    this.estimatedCostsForms.set(
      parameterName,
      estimatedCostRecord[parameterName],
    );
  }

  protected storeNumberOfSeasons(
    numberOfSeasonsRecord: Record<string, FormGroup>,
  ): void {
    const parameterName = Object.keys(numberOfSeasonsRecord)[0] as string;
    this.numberOfSeasonsForm = numberOfSeasonsRecord[parameterName];
    console.log('parameterName: ' + parameterName);
    console.log(
      'Number of seasons: ' + this.numberOfSeasonsForm.get('fieldValue')?.value,
    );
  }

  private getNumberOfSeasons() {
    return (
      this.numberOfSeasonsForm.get('fieldValue')?.value ??
      this.numberOfSeasonsDefaultValue
    );
  }

  private areAllInputFieldsValid(inputFields: Map<string, FormGroup>): boolean {
    for (const form of inputFields.values()) {
      if (form.invalid) {
        return false;
      }
    }
    return true;
  }

  submitImpactMeasurementExperiment(): void {
    // A KPI file must be attached.
    // TODO b/313802172 - Add validation: for transitions from Budget Simulation
    // the KPI file must be "testgroup-based", otherwise the KPI file must be
    // "member-based".
    if (!this.kpiFile) {
      this.openSnackBarWithMessage(this.snackBarKpiFileErrorMessage);
      return;
    }

    // At least one test group must be selected.
    if (this.selectedTestGroups.length === 0) {
      this.openSnackBarWithMessage(
        this.snackBarNoTestGroupsSelectedErrorMessage,
      );
      return;
    }

    if (!this.selectedMethod) {
      this.openSnackBarWithMessage(
        this.snackBarNoAnalysisMethodSelectedErrorMessage,
      );
      return;
    }

    if (this.isCustomizedDateSelection) {
      // All dates must be set in customized date selection.
      if (
        !this.isValidDate(
          this.preExperimentStartDate,
          'pre-experiment start date',
        )
      ) {
        return;
      }
      if (
        !this.isValidDate(this.preExperimentEndDate, 'pre-experiment end date')
      ) {
        return;
      }
      if (
        !this.isValidDate(this.experimentStartDate, 'experiment start date')
      ) {
        return;
      }
      if (!this.isValidDate(this.experimentEndDate, 'experiment end date')) {
        return;
      }
    } else {
      // Experiment start and end dates must be set in default date selection.
      if (
        !this.isValidDate(this.experimentStartDate, 'experiment start date')
      ) {
        return;
      }
      if (!this.isValidDate(this.experimentEndDate, 'experiment end date')) {
        return;
      }
      // Set default values for pre-experiment start and end dates.
      if (!this.kpiFirstDateForExperiment) {
        return;
      } else {
        this.preExperimentStartDate = this.kpiFirstDateForExperiment;
      }
      this.preExperimentEndDate = this.experimentStartDate
        .clone()
        .subtract(1, 'days');
    }

    if (this.isCustomizedDateSelection) {
      // Check if the four dates are in chronological order when the customized
      // date selection is on.
      if (
        !(
          this.preExperimentStartDate.isSameOrBefore(
            this.preExperimentEndDate,
          ) &&
          this.preExperimentEndDate.isBefore(this.experimentStartDate) &&
          this.experimentStartDate.isSameOrBefore(this.experimentEndDate)
        )
      ) {
        this.openSnackBarWithMessage(
          'Selected pre-experiment start, end, experiment start, and end dates' +
            ' are not in chronological order.',
        );
        return;
      }
    } else {
      // Check if the experiment start and end dates are in chronological order.
      if (!this.experimentStartDate.isSameOrBefore(this.experimentEndDate)) {
        this.openSnackBarWithMessage(
          'Selected experiment start, and end dates are not in chronological order.',
        );
        return;
      }
    }

    if (
      this.selectedMethod === 'Difference-in-Differences' &&
      !this.areAllInputFieldsValid(this.estimatedCostsForms)
    ) {
      this.openSnackBarWithMessage(this.snackBarEstimatedCostsErrorMessage);
      return;
    }

    // this.openSnackBarWithMessage(
    //   'The number of seasons is invalid. Please enter a positive integer.',
    // );

    if (this.selectedMethod === 'Causal Impact') {
      if (this.numberOfSeasonsForm.invalid) {
        this.openSnackBarWithMessage(
          'The number of seasons is invalid. Please enter a positive integer.',
        );
        return;
      }
      if (this.isMultipleControlGroupsModeEnabled) {
        for (const testGroup of this.selectedTestGroups) {
          if (
            this.testGroupCovariatesSelectionMappings.get(testGroup)?.value
              ?.length === 0 ??
            true
          ) {
            this.openSnackBarWithMessage(
              `Control groups/covariates are empty for test group: ${testGroup}.`,
            );
            return;
          }
        }
      }
    }

    // Submitting Impact measurement experiment after overwrite confirmation.
    const currentTaskType =
      this.selectedMethod === 'Difference-in-Differences'
        ? TaskTypeEnum.ANALYSIS_DID
        : TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT;
    this.experimentOverwriteGuardService
      .confirmOverwrite(this.experimentId, currentTaskType)
      .pipe(
        switchMap((proceed) => {
          if (proceed && this.kpiFile) {
            // Submitting Impact measurement experiment to server.
            return this.createAnalysisTaskAsContinuation(this.kpiFile);
          } else {
            // Not sending a request to server.
            return of(null);
          }
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.openSnackBarWithMessage(
              this.snackBarSubmittingImpactMeasurementMessage,
            );
            const targetUrl =
              routes.VIEW_EXPERIMENT_URL +
              `/${this.experimentId}/${StartingPointEnum.IMPACT_MEASUREMENT}`;
            this.router.navigate([targetUrl], {skipLocationChange: true});
          }
        },
        error: (error: Error) => {
          this.openSnackBarWithMessage(error.message);
        },
      });
  }

  private createAnalysisTaskAsContinuation(kpiFile: File) {
    if (this.selectedMethod === 'Difference-in-Differences') {
      return this.businessLogicService.createDidAnalysisTaskAsContinuation(
        this.experimentId,
        kpiFile,
        (this.preExperimentStartDate as Moment).format('YYYY-MM-DD'),
        (this.preExperimentEndDate as Moment).format('YYYY-MM-DD'),
        (this.experimentStartDate as Moment).format('YYYY-MM-DD'),
        (this.experimentEndDate as Moment).format('YYYY-MM-DD'),
        this.buildEstimatedCosts(),
        this.selectedTestGroups,
        this.getGroupMembersToExclude(),
        this.selectedResultSet,
      );
    } else {
      const request = this.buildCausalImpactAnalysisTaskRequest();
      if (this.isMultipleControlGroupsModeEnabled) {
        const selectedCovariates = this.getSelectedCovariates();
        request.causal_impact_analysis_parameters.test_group_covariates =
          selectedCovariates;
      }
      return this.businessLogicService.createCausalImpactAnalysisTaskAsContinuation(
        this.experimentId,
        this.selectedResultSet,
        request,
      );
    }
  }

  private getGroupMembersToExclude(): Record<string, string[]> {
    const groupMembersToExclude: Record<string, string[]> = {};
    for (const [testGroupName, formControl] of this.groupMembersToExclude) {
      if (
        !(
          testGroupName === 'Control' ||
          this.selectedTestGroups.includes(testGroupName)
        )
      ) {
        continue;
      }
      if (formControl.value && formControl.value.length > 0) {
        groupMembersToExclude[testGroupName] = formControl.value;
      }
    }
    return groupMembersToExclude;
  }

  private buildEstimatedCosts(): number[] {
    const estimatedCosts: number[] = [];
    for (const key of this.selectedTestGroups) {
      estimatedCosts.push(
        this.estimatedCostsForms.get(key)?.get('fieldValue')?.value ??
          this.estimatedCostDefaultValue,
      );
    }
    return estimatedCosts;
  }

  private isValidDate(date: Moment | null, dateType: string): date is Moment {
    if (!date) {
      this.openSnackBarWithMessage(`Please select the ${dateType}.`);
      return false;
    }
    if (
      !date.isValid() ||
      date.isBefore(this.kpiFirstDateForExperiment) ||
      date.isAfter(this.kpiLastDateForExperiment)
    ) {
      this.openSnackBarWithMessage(`The ${dateType} selected is invalid.`);
      return false;
    }
    return true;
  }

  protected clearDateSelection(): void {
    this.preExperimentStartDate = null;
    this.preExperimentEndDate = null;
    this.experimentStartDate = null;
    this.experimentEndDate = null;
  }

  private buildCausalImpactAnalysisTaskRequest(): CausalImpactAnalysisRequest {
    return {
      post_experiment_kpi_file: this.kpiFile!,
      causal_impact_analysis_parameters: {
        pre_experiment_start_date:
          this.preExperimentStartDate!.format('YYYY-MM-DD'),
        pre_experiment_end_date:
          this.preExperimentEndDate!.format('YYYY-MM-DD'),
        experiment_start_date: this.experimentStartDate!.format('YYYY-MM-DD'),
        experiment_end_date: this.experimentEndDate!.format('YYYY-MM-DD'),
        kpi_aggregated_by_groups: true,
        test_groups: this.selectedTestGroups,
        groups_structure: {},
        members_to_exclude: this.getGroupMembersToExclude(),
        number_of_seasons: this.getNumberOfSeasons(),
        confidence_interval: this.confidenceInterval,
      },
    };
  }

  private getSelectedCovariates() {
    const selectedCovariates: Record<string, string[]> = {};
    for (const testGroup of this.selectedTestGroups) {
      selectedCovariates[testGroup] =
        this.testGroupCovariatesSelectionMappings.get(testGroup)?.value ?? [];
    }
    return selectedCovariates;
  }
}
