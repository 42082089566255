/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

import {HelpMessagesService} from '../../../service/help-messages.service';

/** Component for Selecting Test Groups for Experiment Continuation pages. */
@Component({
  standalone: false,
  selector: 'app-selector-for-test-groups',
  templateUrl: './selector-for-test-groups.component.html',
  styleUrls: ['./selector-for-test-groups.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class SelectorForTestGroupsComponent implements AfterViewInit {
  protected allSelected = false;
  @Input({required: true}) testGroupsSelection: string[] = [];
  @Output() readonly testGroupsSelectionModified = new EventEmitter<string[]>();
  @ViewChild('selectTestGroup') protected select!: MatSelect;

  constructor(protected readonly helpMessagesService: HelpMessagesService) {}

  private emitGroupSelection(): void {
    this.testGroupsSelectionModified.emit(
      this.select.options
        .filter((item: MatOption) => item.selected)
        .map((item) => item.value.trim()),
    );
  }

  protected toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    this.emitGroupSelection();
  }

  protected updateAllSelected() {
    this.allSelected = [...this.select.options].every(
      (item: MatOption) => item.selected,
    );
    this.emitGroupSelection();
  }

  protected getCurrentlySelectedTestGroups(): string[] {
    if (this.select && this.select.options) {
      return this.select.options
        .filter((item: MatOption) => item.selected)
        .map((item) => item.value.trim());
    } else {
      return [];
    }
  }

  protected onTestGroupRemoved(testGroup: string): void {
    this.select.options
      .filter((item: MatOption) => item.value === testGroup)
      .forEach((item: MatOption) => item.deselect());
    this.allSelected = false;
    this.emitGroupSelection();
  }

  ngAfterViewInit() {
    if (!this.select) {
      throw new Error('selectTestGroup child element does not exist.');
    }
  }
}
