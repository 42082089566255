/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {
  MatNativeDateModule,
  MatOptionModule,
  MatRippleModule,
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ChartDialogComponent} from './common/chart-dialog/chart-dialog.component';
import {CovariatesSelectorComponent} from './common/covariates-selector/covariates-selector.component';
import {DeleteDialogComponent} from './common/delete-dialog/delete-dialog.component';
import {ExperimentNagivatorComponent} from './common/experiment-nagivator/experiment-nagivator.component';
import {FilePickerComponent} from './common/file-picker/file-picker.component';
import {FilePreviewComponent} from './common/file-preview/file-preview.component';
import {FooterComponent} from './common/footer/footer.component';
import {HeaderComponent} from './common/header/header.component';
import {LongStringTruncatePipe} from './common/pipes/long-string-truncate.pipe';
import {RerunDialogComponent} from './common/rerun-dialog/rerun-dialog.component';
import {VegaChartComponent} from './common/vega-chart/vega-chart.component';
import {PreExperimentKpiAndTestGroupsSelectionComponent} from './continue-experiment/common/pre-experiment-kpi-and-test-groups-selection/pre-experiment-kpi-and-test-groups-selection.component';
import {ResultSetDisplayAndSelectionComponent} from './continue-experiment/common/result-set-display-and-selection/result-set-display-and-selection.component';
import {SelectorForMemberExclusionComponent} from './continue-experiment/common/selector-for-member-exclusion/selector-for-member-exclusion.component';
import {SelectorForTestGroupsComponent} from './continue-experiment/common/selector-for-test-groups/selector-for-test-groups.component';
import {ContinueExperimentToBudgetSimulationComponent} from './continue-experiment/continue-experiment-to-budget-simulation/continue-experiment-to-budget-simulation.component';
import {ContinueExperimentToImpactMeasurementComponent} from './continue-experiment/continue-experiment-to-impact-measurement/continue-experiment-to-impact-measurement.component';
import {ContinueExperimentComponent} from './continue-experiment/continue-experiment/continue-experiment.component';
import {ExperimentsComponent} from './experiments/experiments.component';
import {AdditionalInformationComponent} from './new-experiment/additional-information/additional-information.component';
import {NewExperimentDescriptionComponent} from './new-experiment/common/new-experiment-description/new-experiment-description.component';
import {NumericInputFieldComponent} from './new-experiment/common/numeric-input-field/numeric-input-field.component';
import {NumericValuesInputFieldComponent} from './new-experiment/common/numeric-values-input-field/numeric-values-input-field.component';
import {NewBudgetSimulationComponent} from './new-experiment/new-budget-simulation/new-budget-simulation.component';
import {NewExperimentComponent} from './new-experiment/new-experiment.component';
import {NewImpactMeasurementComponent} from './new-experiment/new-impact-measurement/new-impact-measurement.component';
import {NewSplittingComponent} from './new-experiment/new-splitting/new-splitting.component';
import {RerunBudgetSimulationComponent} from './rerun/rerun-budget-simulation/rerun-budget-simulation.component';
import {RerunSplittingComponent} from './rerun/rerun-splitting/rerun-splitting.component';
import {Ee4eApiService} from './service/ee4e-api.service';
import {FileReaderService} from './service/file-reader.service';
import {IndexedDBService} from './service/idb.service';
import {UtilService} from './service/util.service';
import {CancellationAndErrorMessageCardComponent} from './view-experiment/common/cancellation-and-error-message-card/cancellation-and-error-message-card.component';
import {DataTableComponent} from './view-experiment/common/data-table/data-table.component';
import {GroupMemberAllocationsTableComponent} from './view-experiment/common/group-member-allocations-table/group-member-allocations-table.component';
import {MapeAndImpactSummaryCardComponent} from './view-experiment/common/mape-and-impact-summary-card/mape-and-impact-summary-card.component';
import {RerunButtonComponent} from './view-experiment/common/rerun-button/rerun-button.component';
import {RoutingButtonComponent} from './view-experiment/common/routing-button/routing-button.component';
import {ExperimentAndTaskDetailsComponent} from './view-experiment/experiment-and-task-details/experiment-and-task-details.component';
import {BudgetSimulationInputFilesAndParametersComponent} from './view-experiment/experiment-input-items/budget-simulation-input-files-and-parameters/budget-simulation-input-files-and-parameters.component';
import {ImpactMeasurementInputFilesAndParametersComponent} from './view-experiment/experiment-input-items/impact-measurement-input-files-and-parameters/impact-measurement-input-files-and-parameters.component';
import {SplittingInputFilesAndParametersComponent} from './view-experiment/experiment-input-items/splitting-input-files-and-parameters/splitting-input-files-and-parameters.component';
import {BudgetSimulationGraphsResultsComponent} from './view-experiment/experiment-results/budget-simulation-graphs-results/budget-simulation-graphs-results.component';
import {BudgetSimulationResultsComponent} from './view-experiment/experiment-results/budget-simulation-results/budget-simulation-results.component';
import {CausalImpactResultsComponent} from './view-experiment/experiment-results/causal-impact-results/causal-impact-results.component';
import {DidResultsComponent} from './view-experiment/experiment-results/did-results/did-results.component';
import {ExperimentResultsComponent} from './view-experiment/experiment-results/experiment-results.component';
import {SplittingResultsComponent} from './view-experiment/experiment-results/splitting-results/splitting-results.component';
import {ViewExperimentComponent} from './view-experiment/view-experiment/view-experiment.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ExperimentsComponent,
    NewExperimentComponent,
    NewSplittingComponent,
    NewBudgetSimulationComponent,
    NewImpactMeasurementComponent,
    NewExperimentDescriptionComponent,
    NumericInputFieldComponent,
    FilePickerComponent,
    NumericValuesInputFieldComponent,
    ExperimentAndTaskDetailsComponent,
    SplittingInputFilesAndParametersComponent,
    ExperimentResultsComponent,
    BudgetSimulationInputFilesAndParametersComponent,
    SplittingResultsComponent,
    DataTableComponent,
    ViewExperimentComponent,
    ImpactMeasurementInputFilesAndParametersComponent,
    GroupMemberAllocationsTableComponent,
    CausalImpactResultsComponent,
    BudgetSimulationResultsComponent,
    VegaChartComponent,
    ResultSetDisplayAndSelectionComponent,
    ContinueExperimentToBudgetSimulationComponent,
    SelectorForMemberExclusionComponent,
    DidResultsComponent,
    RoutingButtonComponent,
    ContinueExperimentToImpactMeasurementComponent,
    ContinueExperimentComponent,
    SelectorForTestGroupsComponent,
    PreExperimentKpiAndTestGroupsSelectionComponent,
    MapeAndImpactSummaryCardComponent,
    BudgetSimulationGraphsResultsComponent,
    CancellationAndErrorMessageCardComponent,
    FilePreviewComponent,
    ExperimentNagivatorComponent,
    AdditionalInformationComponent,
    DeleteDialogComponent,
    FooterComponent,
    RerunButtonComponent,
    RerunSplittingComponent,
    RerunBudgetSimulationComponent,
    RerunDialogComponent,
    LongStringTruncatePipe,
    CovariatesSelectorComponent,
    ChartDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatSnackBarModule,
    MatChipsModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatListModule,
    MatPaginatorModule,
    MatRippleModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
  ],
  providers: [
    FileReaderService,
    Ee4eApiService,
    IndexedDBService,
    UtilService,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  ],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
