/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TopLevelSpec} from 'vega-lite';
import {NormalizedUnitSpec} from 'vega-lite/build/src/spec';
import {StandardType} from 'vega-lite/build/src/type';

const CHART_WIDTH = 1000;
const CHART_HEIGHT = 400;

interface ChartDialogData {
  dataSource: Array<Record<string, string | number>>;
  displayedColumns: string[];
}

interface TransformedData {
  group: string;
  date: string;
  value: number;
}

/**
 *  A dialog to display a KPI chart from the file preview.
 */
@Component({
  standalone: false,
  selector: 'app-chart-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss'],
})
export class ChartDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ChartDialogData) {}
  kpiChart: TopLevelSpec | null = null;

  ngOnInit() {
    if (this.data.displayedColumns[0] === 'Groups') {
      this.kpiChart = this.convertToLineChartSpec(
        this.data.dataSource,
        'Groups',
      );
    }
  }

  private convertToLineChartSpec(
    dataSource: Array<Record<string, string | number>>,
    firstColumnName: string,
  ): TopLevelSpec {
    const groupSet = new Set<string>();
    const transformedData: TransformedData[] = [];
    dataSource.forEach((item) => {
      const group = item[firstColumnName] as string;
      groupSet.add(group);
      Object.keys(item).forEach((key) => {
        if (key !== firstColumnName) {
          transformedData.push({
            group,
            date: key,
            value: item[key] as number,
          });
        }
      });
    });

    const vegaLiteSpec: TopLevelSpec = {
      '$schema': 'https://vega.github.io/schema/vega-lite/v5.json',
      description: 'A time series chart',
      data: {
        values: transformedData,
      },
      encoding: {
        x: {
          field: 'date',
          type: 'temporal',
          axis: {
            title: 'Date',
          },
        },
      },
      layer: [
        {
          mark: 'line',
          encoding: {
            y: {
              field: 'value',
              type: 'quantitative',
              axis: {
                title: 'KPI',
              },
            },
            color: {field: 'group', type: 'nominal', title: firstColumnName},
          },
        },
      ],
      width: CHART_WIDTH,
      height: CHART_HEIGHT,
    };

    const tooltipLayer: NormalizedUnitSpec = {
      transform: [{pivot: 'group', value: 'value', groupby: ['date']}],
      mark: 'rule',
      encoding: {
        opacity: {
          condition: {value: 0.3, param: 'hover', empty: false},
          value: 0,
        },
        tooltip: [
          {field: 'date', type: 'temporal', title: 'Date'},
          ...Array.from(groupSet, (group) => ({
            field: group,
            type: 'quantitative' as StandardType,
          })),
        ],
      },
      params: [
        {
          name: 'hover',
          select: {
            type: 'point',
            fields: ['date'],
            nearest: true,
            on: 'pointerover',
            clear: 'pointerout',
          },
        },
      ],
    };
    vegaLiteSpec.layer.push(tooltipLayer);

    return vegaLiteSpec;
  }
}
