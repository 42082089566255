/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {catchError, of as observableOf, switchMap} from 'rxjs';

import * as constants from '../../common/constants';
import * as routes from '../../common/routes';
import {
  experimentNameAndDescriptionAreValid,
  openSnackBarWithMessage,
} from '../../common/utils';
import {BudgetSimulationTableRequest} from '../../model/budget-simulation-table-task-request';
import {BudgetTableParameter} from '../../model/budget-table-parameter';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {TaskTypeEnum} from '../../model/task-type-enum';
import {BusinessLogicService} from '../../service/business-logic.service';
import {ExperimentOverwriteGuardService} from '../../service/experiment-overwrite-guard.service';
import {FileReaderService} from '../../service/file-reader.service';
import {HelpMessagesService} from '../../service/help-messages.service';

/** Component for creating a new Budget Simulation experiment. */
@Component({
  standalone: false,
  selector: 'app-new-budget-simulation',
  templateUrl: './new-budget-simulation.component.html',
  styleUrls: ['./new-budget-simulation.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY,
      },
    },
  ],
})
export class NewBudgetSimulationComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  @Input() experimentName = '';
  @Input() experimentDescription = '';
  @Input() rerun = false;
  @Input() experimentId = '';
  controlTestsGroupedKpiFile: File | null = null;

  readonly iCPALabel = 'Estimated Incremental CPA';
  iCPADefaultValue = 1000;
  readonly durationsLabel = 'Enter durations (in days)';
  readonly durationsPlaceHolder = 'New duration...';
  durationsDefaultValue = [7, 14, 28];
  readonly upliftsLabel = 'Enter uplifts';
  readonly upliftsPlaceHolder = 'New uplift...';
  upliftsDefaultValue = [1.03, 1.05, 1.1];

  allSelected = false;
  testGroupsSelection: string[] = [];
  @ViewChild('selectTestGroup') select!: MatSelect;

  readonly snackBarEmptyDurationsErrorMessage = 'Durations cannot be empty.';
  readonly snackBarEmptyUpliftsErrorMessage = 'Uplifts cannot be empty.';
  readonly snackBarInvalidIcpaErrorMessage = 'iCPA is invalid.';
  readonly snackBarSubmittingBudgetSimulationMessage =
    'Submitting budget simulation task to server.';
  readonly snackBarNoTestGroupsSelectedErrorMessage =
    'Please select at least one test group.';

  durations = this.durationsDefaultValue;
  uplifts = this.upliftsDefaultValue;
  icpaForm = new FormGroup({});

  @Input() budgetSimulationParametersForRerun: BudgetTableParameter | null =
    null;

  constructor(
    private readonly fileReaderService: FileReaderService,
    readonly submissionSnackBar: MatSnackBar,
    private businessLogicService: BusinessLogicService,
    private router: Router,
    private readonly experimentOverwriteGuardService: ExperimentOverwriteGuardService,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {}

  ngOnInit() {
    if (this.rerun && this.budgetSimulationParametersForRerun) {
      this.iCPADefaultValue = this.budgetSimulationParametersForRerun.icpa;
      this.durationsDefaultValue =
        this.budgetSimulationParametersForRerun.durations;
      this.upliftsDefaultValue =
        this.budgetSimulationParametersForRerun.uplifts;
      this.durations = this.durationsDefaultValue;
      this.uplifts = this.upliftsDefaultValue;
    }
  }

  storeExperimentName(name: string): void {
    this.experimentName = name;
  }

  storeExperimentDescription(description: string): void {
    this.experimentDescription = description;
  }

  storeDurations(modifiedDurations: Set<number>): void {
    this.durations = [...modifiedDurations];
  }

  storeUplifts(modifiedUplifts: Set<number>): void {
    this.uplifts = [...modifiedUplifts];
  }

  storeICPA(iCPAParameterRecord: Record<string, FormGroup>): void {
    const parameterName = Object.keys(iCPAParameterRecord)[0];
    this.icpaForm = iCPAParameterRecord[parameterName];
  }

  storeTestGroupsSelection(kpiFile: File | null): void {
    // Reset a checkbox and options.
    this.allSelected = false;
    this.select.options.forEach((item: MatOption) => item.deselect());
    if (!kpiFile) {
      this.testGroupsSelection = [];
      this.controlTestsGroupedKpiFile = null;
      return;
    }

    this.fileReaderService
      .extractAllTestGroups(kpiFile)
      .pipe(
        catchError((error) => {
          this.openSnackBarWithMessage(error.message);
          return observableOf([]);
        }),
      )
      .subscribe((testGroups) => {
        this.testGroupsSelection = testGroups;
        this.controlTestsGroupedKpiFile = kpiFile;
      });
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  updateAllSelected() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  protected getCurrentlySelectedTestGroups(): string[] {
    if (this.select && this.select.options) {
      return this.select.options
        .filter((item: MatOption) => item.selected)
        .map((item) => item.value.trim());
    } else {
      return [];
    }
  }

  protected onTestGroupRemoved(testGroup: string): void {
    this.select.options
      .filter((item: MatOption) => item.value === testGroup)
      .forEach((item: MatOption) => item.deselect());
    this.allSelected = false;
  }

  private openSnackBarWithMessage(message: string): void {
    openSnackBarWithMessage(message, this.submissionSnackBar);
  }

  submitOrRerunBudgetSimulation(): void {
    if (
      !experimentNameAndDescriptionAreValid(
        this.experimentName,
        this.experimentDescription,
      )
    ) {
      this.openSnackBarWithMessage(
        constants.SNACKBAR_INVALID_NAME_DESCRIPTION_ERROR_MESSAGE,
      );
      return;
    }

    // A KPI file must be attached.
    if (!this.controlTestsGroupedKpiFile) {
      this.openSnackBarWithMessage(constants.SNACKBAR_KPI_FILE_ERROR_MESSAGE);
      return;
    }

    const currentlySelectedTestGroups: string[] = this.select.options
      .filter((item: MatOption) => item.selected)
      .map((item) => item.value.trim());

    // At least one test group must be selected.
    if (currentlySelectedTestGroups.length === 0) {
      this.openSnackBarWithMessage(
        this.snackBarNoTestGroupsSelectedErrorMessage,
      );
      return;
    }

    // iCPA form should be valid.
    if (this.icpaForm != null && this.icpaForm.invalid) {
      this.openSnackBarWithMessage(this.snackBarInvalidIcpaErrorMessage);
      return;
    }

    // Durations and Uplifts are mandatory.
    if (this.durations.length === 0) {
      this.openSnackBarWithMessage(this.snackBarEmptyDurationsErrorMessage);
      return;
    }

    if (this.uplifts.length === 0) {
      this.openSnackBarWithMessage(this.snackBarEmptyUpliftsErrorMessage);
      return;
    }

    if (this.rerun) {
      const currentTaskType = TaskTypeEnum.BUDGET_TABLE;
      this.experimentOverwriteGuardService
        .confirmOverwrite(this.experimentId, currentTaskType)
        .pipe(
          switchMap((proceed) => {
            if (proceed) {
              // Submitting Budget Simulation experiment to server.
              return this.businessLogicService.createBudgetSimulationTableTaskForRerun(
                this.experimentId,
                this.buildBudgetSimulationTaskRequest(
                  currentlySelectedTestGroups,
                ),
              );
            } else {
              // Not sending a request to server.
              return observableOf(null);
            }
          }),
          takeUntilDestroyed(this.destroyedRef),
        )
        .subscribe(this.createRoutingObserver());
      return;
    }

    this.businessLogicService
      .createBudgetSimulationTableTaskAsNewTask(
        this.experimentName,
        this.experimentDescription,
        this.buildBudgetSimulationTaskRequest(currentlySelectedTestGroups),
      )
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(this.createRoutingObserver());
  }

  private buildBudgetSimulationTaskRequest(
    selectedTestGroups: string[],
  ): BudgetSimulationTableRequest {
    return {
      kpi_csv_file: this.controlTestsGroupedKpiFile!,
      budget_table_parameters: {
        icpa: this.icpaForm.get('fieldValue')?.value ?? this.iCPADefaultValue,
        durations: this.durations,
        uplifts: this.uplifts,
        test_groups: selectedTestGroups,
        has_external_kpi: true,
        groups_kpi: {},
      },
    };
  }

  private createRoutingObserver() {
    return {
      next: (response: unknown) => {
        if (response) {
          this.openSnackBarWithMessage(
            this.snackBarSubmittingBudgetSimulationMessage,
          );
          this.router.navigate(
            [
              routes.VIEW_EXPERIMENT_URL +
                `/${response}/${StartingPointEnum.BUDGET_SIMULATION}`,
            ],
            {skipLocationChange: true},
          );
        }
      },
      error: (error: Error) => {
        this.openSnackBarWithMessage(error.message);
      },
    };
  }
}
