/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AfterViewChecked, Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';

import {BudgetTableParameter} from '../../model/budget-table-parameter';
import {Experiment} from '../../model/experiment';
import {TaskTypeEnum} from '../../model/task-type-enum';
import {BusinessLogicService} from '../../service/business-logic.service';

/**
 * Component for rerunning budget simulation task when it is the starting
 * point of the experiment.
 */
@Component({
  selector: 'app-rerun-budget-simulation',
  templateUrl: './rerun-budget-simulation.component.html',
  styleUrls: ['./rerun-budget-simulation.component.scss'],
})
export class RerunBudgetSimulationComponent implements AfterViewChecked {
  private readonly destroyedRef = inject(DestroyRef);
  protected experiment!: Experiment;
  protected readonly snackBarActionLabel = 'OK';
  protected errorMessage = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly businessLogicService: BusinessLogicService,
    readonly snackBar: MatSnackBar,
  ) {
    const experimentId = this.route.snapshot.paramMap.get('experimentId')!;
    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment | null) => {
        if (!experiment) {
          this.errorMessage = `Experiment: ${experimentId} not found.`;
          return;
        }
        if (!experiment.tasks[TaskTypeEnum.BUDGET_TABLE]) {
          this.errorMessage =
            `Experiment: ${experimentId}` +
            ' has no budget simulation task to rerun.';
          return;
        }
        this.experiment = experiment;
      });
  }

  protected extractBudgetSimulationParameters(): BudgetTableParameter {
    return this.experiment.tasks[TaskTypeEnum.BUDGET_TABLE]!
      .taskParameters as BudgetTableParameter;
  }

  ngAfterViewChecked() {
    if (this.errorMessage) {
      this.snackBar.open(this.errorMessage, this.snackBarActionLabel);
      this.errorMessage = '';
    }
  }
}
