/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DidTaskResult} from 'src/app/model/did-task-result';
import {TaskResultExportService} from 'src/app/service/task-result-export.service';
import {BudgetSimulationTableTaskResult} from '../../model/budget-simulation-table-task-result';
import {BudgetTableParameter} from '../../model/budget-table-parameter';
import {CausalImpactAnalysisParameters} from '../../model/causal-impact-analysis-parameters';
import {CausalImpactTaskResult} from '../../model/causal-impact-task-result';
import {DidAnalysisParameters} from '../../model/did-analysis-parameters';
import {SplittingTaskResult} from '../../model/splitting-task-result';
import {Task} from '../../model/task';
import {TaskStatusEnum} from '../../model/task-status-enum';
import {TaskTypeEnum} from '../../model/task-type-enum';
import {BusinessLogicService} from '../../service/business-logic.service';

/** Component that is used to show the results of the experiment. */
@Component({
  standalone: false,
  selector: 'app-experiment-results',
  templateUrl: './experiment-results.component.html',
  styleUrls: ['./experiment-results.component.scss'],
})
export class ExperimentResultsComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  @Input({required: true}) task!: Task;
  @Input({required: true}) experimentId!: string;

  readonly spinnerDiameter = 110;

  readonly snackBarDuration = 2000;
  readonly snackBarActionLabel = 'OK';
  taskStatus!: TaskStatusEnum;
  taskType!: TaskTypeEnum;

  constructor(
    readonly cancellationSnackBar: MatSnackBar,
    private readonly businessLogicService: BusinessLogicService,
    private taskResultExportService: TaskResultExportService,
  ) {}

  ngOnInit(): void {
    // Set the information necessary to get meta data for exporing task results.
    this.taskResultExportService.setExperimentId(this.experimentId);
    this.taskResultExportService.setTask(this.task);
  }

  private openSnackBarWithMessage(message: string): void {
    this.cancellationSnackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDuration,
    });
  }

  // TODO b/316066430 - make as a PipeTransform and reuse here and in
  // cancellation-and-error-message-card.
  displayTypeOfResults(): string {
    let textToDisplay: string;
    switch (this.task.taskType) {
      case TaskTypeEnum.SPLIT:
        textToDisplay = 'Splitting';
        break;
      case TaskTypeEnum.ANALYSIS_DID:
        textToDisplay = 'Impact Analysis';
        break;
      case TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT:
        textToDisplay = 'Impact Analysis';
        break;
      default:
        textToDisplay = 'Budget Simulation';
        break;
    }
    return textToDisplay.concat(' Results');
  }

  isTaskRunning(): boolean {
    return (
      this.task.taskStatus === TaskStatusEnum.IN_PROGRESS ||
      this.task.taskStatus === TaskStatusEnum.QUEUED
    );
  }

  isCompleted(): boolean {
    return this.task.taskStatus === TaskStatusEnum.COMPLETED;
  }

  cancelRunningTask(): void {
    this.businessLogicService
      .cancelTask(this.experimentId, this.task.taskId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe({
        next: () => {
          this.openSnackBarWithMessage(`Task ${this.task.taskId} cancelled.`);
        },
        error: (error: Error) => {
          this.openSnackBarWithMessage(error.message);
        },
      });
  }

  isSplittingTask(): boolean {
    return this.task.taskType === TaskTypeEnum.SPLIT;
  }

  isCausalImpactTask(): boolean {
    return this.task.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT;
  }

  isDidTask(): boolean {
    return this.task.taskType === TaskTypeEnum.ANALYSIS_DID;
  }

  isBudgetSimulationTask(): boolean {
    return this.task.taskType === TaskTypeEnum.BUDGET_TABLE;
  }

  extractSplittingResultSets(): SplittingTaskResult {
    return this.task.taskResult as SplittingTaskResult;
  }

  extractCausalImpactResult(): CausalImpactTaskResult {
    return this.task.taskResult as CausalImpactTaskResult;
  }

  extractDidResult(): DidTaskResult {
    return this.task.taskResult as DidTaskResult;
  }

  extractBudgetSimulationResult(): BudgetSimulationTableTaskResult {
    return this.task.taskResult as BudgetSimulationTableTaskResult;
  }

  extractBudgetTableParameter(): BudgetTableParameter {
    return this.task.taskParameters as BudgetTableParameter;
  }

  areBudgetTablesEmpty(): boolean {
    if (!this.isBudgetSimulationTask()) {
      return true;
    }
    return (
      Object.keys(this.extractBudgetSimulationResult().budget_tables).length ===
      0
    );
  }

  extractCausalImpactAnalysisParameters(): CausalImpactAnalysisParameters {
    return this.task.taskParameters as CausalImpactAnalysisParameters;
  }

  extractDidAnalysisParameters(): DidAnalysisParameters {
    return this.task.taskParameters as DidAnalysisParameters;
  }
}
