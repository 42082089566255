/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

import {TaskResultExportService} from 'src/app/service/task-result-export.service';
import {HelpMessagesService} from '../../../service/help-messages.service';

interface MemberGroupAllocation {
  name: string;
  allocation: string;
}

/** Component to display the table with the group-member allocations. */
@Component({
  standalone: false,
  selector: 'app-group-member-allocations-table',
  templateUrl: './group-member-allocations-table.component.html',
  styleUrls: ['./group-member-allocations-table.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class GroupMemberAllocationsTableComponent
  implements OnInit, AfterViewInit
{
  displayedColumns: string[] = ['member', 'allocation'];
  dataSource!: MatTableDataSource<MemberGroupAllocation>;
  @Input({required: true}) groupMembers!: string[][];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSelect) matSelect!: MatSelect;
  selectedGroup = 'All';
  selectedMember = '';
  testGroupLabels: string[] = [];
  @Input() selectedPlan?: number;
  csvFileName = 'configuration.csv';
  @Input() isTableDownloadable = false;

  constructor(
    private taskResultExportService: TaskResultExportService,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {}

  ngOnInit() {
    const memberAllocations = new Array<MemberGroupAllocation>();
    this.groupMembers.forEach((group: string[], index) => {
      if (index === 0) {
        group.forEach((member) => {
          memberAllocations.push({name: member, allocation: 'Control'});
        });
      } else {
        group.forEach((member) => {
          memberAllocations.push({name: member, allocation: `Test_${index}`});
        });
      }
    });

    for (let i = 1; i < this.groupMembers.length; i++) {
      this.testGroupLabels.push(`Test_${i}`);
    }

    this.dataSource = new MatTableDataSource<{
      name: string;
      allocation: string;
    }>(memberAllocations);
  }

  setPaginatorAndFilter(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (member: {
      name: string;
      allocation: string;
    }) => {
      if (this.selectedMember.length === 0) {
        if (this.selectedGroup === 'All') return true;
        return member.allocation === this.selectedGroup;
      } else {
        const doesMemberNameStartWith = member.name
          .toLowerCase()
          .startsWith(this.selectedMember.toLowerCase().trim());
        if (this.selectedGroup === 'All') {
          return doesMemberNameStartWith;
        }
        return (
          member.allocation === this.selectedGroup && doesMemberNameStartWith
        );
      }
    };
  }

  ngAfterViewInit() {
    this.setPaginatorAndFilter();
  }

  applyGroupBasedFilter(event: MatSelectChange) {
    this.dataSource.filter = event.value;
  }

  applyMemberBasedFilter(event: Event) {
    if (
      !(event.target as HTMLInputElement).value &&
      (event.target as HTMLInputElement).value !== ''
    ) {
      throw new TypeError('Event target is not HTMLInputElement.');
    }
    this.selectedMember = (event.target as HTMLInputElement).value;
    if (this.selectedMember.length === 0) {
      this.dataSource.filter = this.selectedGroup;
    } else {
      this.dataSource.filter = this.selectedMember;
    }
  }

  exportToCsv(): void {
    if (!this.isTableDownloadable) {
      return;
    }

    const options: Record<string, string | number | Date> = {};
    if (this.selectedPlan != null) {
      options['Selected Plan'] = this.selectedPlan;
    }
    this.taskResultExportService.exportToCsv(
      this.csvFileName,
      this.dataSource.data as unknown as Array<Record<string, string>>,
      this.displayedColumns,
      options,
    );
  }
}
