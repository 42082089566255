/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, Input, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {catchError, of as observableOf} from 'rxjs';
import {FileReaderService} from '../../service/file-reader.service';
import {ChartDialogComponent} from '../chart-dialog/chart-dialog.component';

/**
 * Component to display a preview of a file, specifically focusing on CSV files.
 * It shows the first few rows and columns of the file content.
 */
@Component({
  standalone: false,
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  private readonly destroyRef = inject(DestroyRef);
  private fileToViewInternal: File | null = null;

  @Input()
  set fileToView(file: File | null) {
    this.fileToViewInternal = file;
    this.parseCSVFile();
  }
  get fileToView(): File | null {
    return this.fileToViewInternal;
  }

  @Input() numRowsToPreview = 5;
  @Input() numColumnsToPreview = null;
  @Input() isChartEnable = false;
  private readonly maxNumRowsToRead = 1000;

  readonly snackBarDurationMills = 2000;
  readonly snackBarActionLabel = 'OK';

  dataSource: Array<Record<string, string | number>> = [];
  displayedColumns: string[] = [];

  constructor(
    private readonly fileReaderService: FileReaderService,
    readonly snackBar: MatSnackBar,
    readonly dialog: MatDialog,
  ) {}

  private parseCSVFile(): void {
    if (!this.fileToViewInternal) {
      this.dataSource = [];
      this.displayedColumns = [];
      return;
    }

    // Read the rows up to the maximum when the chart functionality is enabled.
    this.fileReaderService
      .readCSV(
        this.fileToViewInternal,
        this.isChartEnable ? this.maxNumRowsToRead : this.numRowsToPreview,
        this.numColumnsToPreview,
      )
      .pipe(
        catchError((error) => {
          this.openSnackBar(error.message);
          return observableOf(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((parsedCsv) => {
        if (parsedCsv) {
          this.displayedColumns = parsedCsv.header;
          this.dataSource = parsedCsv.dataSource;
        }
      });
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDurationMills,
    });
  }

  protected showChart(): void {
    this.dialog.open(ChartDialogComponent, {
      data: {
        dataSource: this.dataSource,
        displayedColumns: this.displayedColumns,
      },
    });
    return;
  }
}
