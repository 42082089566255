/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import embed from 'vega-embed';
import {TopLevelSpec} from 'vega-lite';

/**
 * Component used to plot a Vega-lite format chart.
 */
@Component({
  standalone: false,
  selector: 'app-vega-chart',
  templateUrl: './vega-chart.component.html',
  styleUrls: ['./vega-chart.component.scss'],
})
export class VegaChartComponent implements AfterViewInit {
  @Input({required: true}) spec!: TopLevelSpec;
  @ViewChild('vegaChartContainer') vegaChartContainer!: ElementRef;

  ngAfterViewInit(): void {
    embed(this.vegaChartContainer.nativeElement, this.spec);
  }
}
