/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

/** Component to input members to exclude for Impact Measurement. */
@Component({
  standalone: false,
  selector: 'app-selector-for-member-exclusion',
  templateUrl: './selector-for-member-exclusion.component.html',
  styleUrls: ['./selector-for-member-exclusion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorForMemberExclusionComponent {
  @Input({required: true}) membersList: string[] = [];
  @Input({required: true}) groupLabel = '';
  @Input({required: true}) selectedMembersControl!: FormControl<
    string[] | null
  >;

  removeMember(member: string): void {
    const members = this.selectedMembersControl.value ?? [];
    this.removeFirstOccurrenceOfMemberFomMembers(members, member);
    this.selectedMembersControl.setValue(members);
  }

  private removeFirstOccurrenceOfMemberFomMembers(
    members: string[],
    member: string,
  ): void {
    const index = members.indexOf(member);
    if (index !== -1) {
      members.splice(index, 1);
    }
  }
}
