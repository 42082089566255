/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {StartingPointEnum} from './starting-point-enum';
import {TaskStatusEnum} from './task-status-enum';
import {TaskTypeEnum} from './task-type-enum';

/**
 *  The JSON Schema for a list of experiments.
 *  This is used to validate a JSON object for importing experiments.
 */
export const experimentsSchema = {
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'type': 'array',
  'items': {
    // Experiment.
    'type': 'object',
    'properties': {
      'id': {'type': 'string'},
      'name': {'type': 'string'},
      'description': {'type': 'string'},
      'created': {'type': 'object'},
      'lastModified': {'type': 'object'},
      'startingPoint': {
        'type': 'string',
        'enum': [
          StartingPointEnum.SPLITTING,
          StartingPointEnum.BUDGET_SIMULATION,
          StartingPointEnum.IMPACT_MEASUREMENT,
        ],
      },
      'tasks': {
        'type': 'object',
        'anyOf': [
          {'properties': {'SPLIT': {'$ref': '#/definitions/task'}}},
          {'properties': {'ANALYSIS_DID': {'$ref': '#/definitions/task'}}},
          {
            'properties': {
              'ANALYSIS_CAUSAL_IMPACT': {'$ref': '#/definitions/task'},
            },
          },
          {'properties': {'BUDGET_TABLE': {'$ref': '#/definitions/task'}}},
          {'properties': {'BUDGET_GRAPHS': {'$ref': '#/definitions/task'}}},
        ],
      },
      // Optional extra properties.
      'extra': {'type': 'object'},
    },
    'required': [
      'id',
      'name',
      'description',
      'created',
      'lastModified',
      'startingPoint',
      'tasks',
    ],
    'additionalProperties': false,
  },
  'definitions': {
    'task': {
      // Task.
      'type': 'object',
      'properties': {
        'taskId': {'type': 'string'},
        'taskStatus': {
          'type': 'string',
          'enum': [
            TaskStatusEnum.IN_PROGRESS,
            TaskStatusEnum.COMPLETED,
            TaskStatusEnum.CANCELLED,
            TaskStatusEnum.FAILURE,
            TaskStatusEnum.QUEUED,
          ],
        },
        'taskParameters': {
          // SplittingParameters | BudgetTableParameter | BudgetGraphParameter
          // | DidAnalysisParameters | CausalImpactAnalysisParameters.
          'type': 'object',
          'anyOf': [
            {'$ref': '#/definitions/splittingParameters'},
            {'$ref': '#/definitions/budgetTableParameters'},
            {'$ref': '#/definitions/budgetGraphParameters'},
            {'$ref': '#/definitions/didAnalysisParameters'},
            {'$ref': '#/definitions/causalImpactAnalysisParameters'},
          ],
        },
        'taskResult': {
          // SplittingTaskResult | CausalImpactTaskResult | DidTaskResult
          // | BudgetSimulationTableTaskResult | BudgetSimulationGraphTaskResult.
          'type': 'object',
          'anyOf': [
            {'$ref': '#/definitions/splittingTaskResult'},
            {'$ref': '#/definitions/budgetSimulationTableTaskResult'},
            {'$ref': '#/definitions/budgetSimulationGraphTaskResult'},
            {'$ref': '#/definitions/didTaskResult'},
            {'$ref': '#/definitions/causalImpactTaskResult'},
          ],
        },
        'taskTraceback': {'type': ['string', 'null']},
        'created': {'type': 'object'},
        'lastModified': {'type': 'object'},
        'taskType': {
          'type': 'string',
          'enum': [
            TaskTypeEnum.SPLIT,
            TaskTypeEnum.ANALYSIS_DID,
            TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
            TaskTypeEnum.BUDGET_TABLE,
            TaskTypeEnum.BUDGET_GRAPHS,
          ],
        },
        'fileInfos': {
          'type': 'object',
          'anyOf': [
            {'properties': {'KPI': {'$ref': '#/definitions/fileInfo'}}},
            {'properties': {'CONFIG': {'$ref': '#/definitions/fileInfo'}}},
          ],
        },
        'extra': {'type': ['object', 'null']},
      },
      'required': [
        'taskId',
        'taskStatus',
        'taskParameters',
        'taskTraceback',
        'created',
        'lastModified',
        'taskType',
        'fileInfos',
      ],
      'additionalProperties': false,
    },
    'splittingParameters': {
      // SplittingParameters.
      'type': 'object',
      'properties': {
        'number_of_result_sets': {'type': 'number'},
        'number_of_groups': {'type': 'number'},
        'number_of_members_per_group': {'type': 'number'},
        'correlation_coefficient': {'type': 'number'},
        'data_volume_coefficient': {'type': 'number'},
        'config_volume_ratios': {
          'type': 'object',
          'additionalProperties': {'type': 'number'},
        },
      },
      'required': [
        'number_of_result_sets',
        'number_of_groups',
        'number_of_members_per_group',
        'correlation_coefficient',
        'data_volume_coefficient',
      ],
      'additionalProperties': false,
    },
    'budgetTableParameters': {
      // BudgetTableParameter.
      'type': 'object',
      'properties': {
        'icpa': {'type': 'number'},
        'durations': {'type': 'array'},
        'uplifts': {'type': 'array'},
        'test_groups': {'type': 'array'},
        'has_external_kpi': {'type': 'boolean'},
        'groups_kpi': {'type': 'object'},
      },
      'required': [
        'icpa',
        'durations',
        'uplifts',
        'test_groups',
        'has_external_kpi',
        'groups_kpi',
      ],
      'additionalProperties': false,
    },
    'budgetGraphParameters': {
      // BudgetGraphParameter.
      'type': 'object',
      'properties': {
        'icpa': {'type': 'number'},
        'durations': {'type': 'array', 'items': {'type': 'number'}},
        'uplifts': {'type': 'array', 'items': {'type': 'number'}},
        'test_groups': {'type': 'array', 'items': {'type': 'string'}},
        'control_group_kpi': {'type': 'boolean'},
        'test_groups_kpi': {'type': 'object'},
      },
      'required': [
        'icpa',
        'durations',
        'uplifts',
        'test_groups',
        'control_group_kpi',
        'test_groups_kpi',
      ],
      'additionalProperties': false,
    },
    'didAnalysisParameters': {
      // DidAnalysisParameters.
      'type': 'object',
      'properties': {
        'experiment_start_date': {'type': 'string'},
        'kpi_aggregated_by_groups': {'type': 'boolean'},
        'test_groups_costs': {'type': 'array', 'items': {'type': 'number'}},
        'test_groups': {'type': 'array', 'items': {'type': 'string'}},
        'groups_structure': {'type': 'object'},
        'members_to_exclude': {'type': 'object'},
      },
      'required': [
        'experiment_start_date',
        'kpi_aggregated_by_groups',
        'test_groups_costs',
        'test_groups',
        'groups_structure',
        'members_to_exclude',
      ],
      'additionalProperties': false,
    },
    'causalImpactAnalysisParameters': {
      // CausalImpactAnalysisParameters.
      'type': 'object',
      'properties': {
        'experiment_start_date': {'type': 'string'},
        'kpi_aggregated_by_groups': {'type': 'boolean'},
        'test_groups': {'type': 'array', 'items': {'type': 'string'}},
        'groups_structure': {'type': 'object'},
        'members_to_exclude': {'type': 'object'},
        'number_of_seasons': {'type': 'number'},
        'confidence_interval': {'type': 'number'},
        'test_group_covariates': {'type': 'object'},
      },
      'required': [
        'experiment_start_date',
        'kpi_aggregated_by_groups',
        'test_groups',
        'groups_structure',
        'members_to_exclude',
      ],
      'additionalProperties': false,
    },
    'splittingTaskResult': {
      // SplittingTaskResult.
      'type': 'object',
      'properties': {
        'splitting_parameters': {'$ref': '#/definitions/splittingParameters'},
        'splitting_result_sets': {
          'type': 'array',
          'items': {'$ref': '#/definitions/splittingResultSet'},
        },
      },
      'required': ['splitting_parameters', 'splitting_result_sets'],
      'additionalProperties': false,
    },
    'budgetSimulationTableTaskResult': {
      // BudgetSimulationTableTaskResult.
      'type': 'object',
      'properties': {
        'budget_tables': {'type': 'object'},
        'groups_kpi': {'type': 'object'},
      },
      'required': ['budget_tables', 'groups_kpi'],
      'additionalProperties': false,
    },
    'budgetSimulationGraphTaskResult': {
      // BudgetSimulationGraphTaskResult.
      'type': 'object',
      'properties': {
        'causal_impact_summary': {'type': 'object'},
        'causal_impact_uplifted_graphs': {'type': 'object'},
        'causal_impact_non_uplifted_graphs': {'type': 'object'},
      },
      'required': [
        'causal_impact_summary',
        'causal_impact_uplifted_graphs',
        'causal_impact_non_uplifted_graphs',
      ],
      'additionalProperties': false,
    },
    'didTaskResult': {
      // DidTaskResult.
      'type': 'object',
      'properties': {
        'pre_vs_post_periods_comparison': {'type': 'object'},
        'did_report': {'type': 'object'},
        'pre_vs_post_period_daily_avg': {'type': 'object'},
      },
      'required': [
        'pre_vs_post_periods_comparison',
        'did_report',
        'pre_vs_post_period_daily_avg',
      ],
      'additionalProperties': false,
    },
    'causalImpactTaskResult': {
      // CausalImpactTaskResult.
      'type': 'object',
      'properties': {
        'causal_impact_summaries': {
          'type': 'array',
          'items': {'type': 'object'},
        },
        'causal_impact_graphs': {'type': 'array', 'items': {'type': 'string'}},
        'causal_impact_mapes': {'type': 'array', 'items': {'type': 'number'}},
        'causal_impact_covariate_weights': {
          'type': 'array',
          'items': {'type': 'array'},
        },
      },
      'required': [
        'causal_impact_summaries',
        'causal_impact_graphs',
        'causal_impact_mapes',
      ],
      'additionalProperties': false,
    },
    'fileInfo': {
      // FileInfo.
      'type': 'object',
      'properties': {
        'fileName': {'type': 'string'},
        'size': {'type': 'number'},
        'path': {'type': 'string'},
        'extra': {'type': 'object'},
      },
      'required': ['fileName', 'size', 'path'],
      'additionalProperties': false,
    },
    'splittingResultSet': {
      // SplittingResultSet.
      'type': 'object',
      'properties': {
        'group_mumbers': {'type': 'array'},
        'summary_dataframe': {'type': 'object'},
        'kpi_dataframe': {'type': 'object'},
        'normalized_kpi_dataframe': {'type': 'object'},
      },
      'required': [
        'group_mumbers',
        'summary_dataframe',
        'kpi_dataframe',
        'normalized_kpi_dataframe',
      ],
      'additionalProperties': false,
    },
  },
};
