/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

/**
 * Component for selecting covariates for Causal Impact in multi-control mode.
 */
@Component({
  standalone: false,
  selector: 'app-covariates-selector',
  templateUrl: './covariates-selector.component.html',
  styleUrls: ['./covariates-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CovariatesSelectorComponent {
  @Input({required: true}) covariatesList: string[] = [];
  @Input({required: true}) testGroupLabel = '';
  @Input({required: true}) selectedCovariatesFormControl!: FormControl<
    string[] | null
  >;

  removeCovariate(covariate: string): void {
    const covariates = this.selectedCovariatesFormControl.value ?? [];
    this.removeFirstOccurrenceOfCovariateFomCovariates(covariates, covariate);
    this.selectedCovariatesFormControl.setValue(covariates);
  }

  private removeFirstOccurrenceOfCovariateFomCovariates(
    covariates: string[],
    covariate: string,
  ): void {
    const index = covariates.indexOf(covariate);
    if (index !== -1) {
      covariates.splice(index, 1);
    }
  }
}
