<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <div class="view-result-container view-result-entry">
    <h2 i18n>Difference-in-Differences Summary</h2>
    <table mat-table [dataSource]="didReportTable" class="mat-elevation-z8">
      <!-- Group Column -->
      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef>Group</th>
        <td mat-cell *matCellDef="let element">{{element.group}}</td>
      </ng-container>

      <!-- Pre Period Daily Average Column -->
      <ng-container matColumnDef="prePeriodDailyAverage">
        <th mat-header-cell *matHeaderCellDef
          [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_PRE_PERIOD_AVG_ABSOLUTE"
          matTooltipClass="multi-line-tooltip max-width-tooltip"
          >Daily Average (Pre Period)</th>
        <td mat-cell *matCellDef="let element">{{element.prePeriodDailyAverage|number:'.1-1'}}</td>
      </ng-container>

      <!-- Post Period Daily Average Column -->
      <ng-container matColumnDef="postPeriodDailyAverage">
        <th mat-header-cell *matHeaderCellDef
          [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_EXP_PERIOD_AVG_ABSOLUTE"
          matTooltipClass="multi-line-tooltip max-width-tooltip"
          >Daily Average (Experiment Period)</th>
        <td mat-cell *matCellDef="let element">{{element.postPeriodDailyAverage|number:'.1-1'}}</td>
      </ng-container>

      <!-- Pre Period Sum Column -->
      <ng-container matColumnDef="prePeriodSum">
        <th mat-header-cell *matHeaderCellDef
          [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_PRE_PERIOD_SUM"
          matTooltipClass="max-width-tooltip"
          >Cumulative (Pre Period)</th>
        <td mat-cell *matCellDef="let element">{{element.prePeriodSum|number:'.1-1'}}</td>
      </ng-container>

      <!-- Post Period Sum Column -->
      <ng-container matColumnDef="postPeriodSum">
        <th mat-header-cell *matHeaderCellDef
          [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_EXP_PERIOD_SUM"
          matTooltipClass="max-width-tooltip"
          >Cumulative (Experiment Period)</th>
        <td mat-cell *matCellDef="let element">{{element.postPeriodSum|number:'.1-1'}}</td>
      </ng-container>

      <!-- Counterfactual Column -->
      <ng-container matColumnDef="counterfactual">
        <th mat-header-cell *matHeaderCellDef
        [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_COUNTERFACTUAL"
        matTooltipClass="multi-line-tooltip max-width-tooltip"
          >Counterfactual</th>
        <td mat-cell *matCellDef="let element">{{element.counterfactual|number:'.1-1'}}</td>
      </ng-container>

      <!-- Number of Lifed Conversions Column -->
      <ng-container matColumnDef="numberOfLiftedConversions">
        <th mat-header-cell *matHeaderCellDef
          [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_LIFTED_CONV"
          matTooltipClass="max-width-tooltip"
          >Absolute Effect</th>
        <td mat-cell *matCellDef="let element">{{element.numberOfLiftedConversions|number:'.1-1'}}</td>
      </ng-container>

      <!-- Relative Lift Percentage Column -->
      <ng-container matColumnDef="relativeLiftPercentage">
        <th mat-header-cell *matHeaderCellDef
        [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_REL_LIFT"
        matTooltipClass="max-width-tooltip"
          >Relative Effect [%]</th>
        <td mat-cell *matCellDef="let element">{{element.relativeLiftPercentage | percent:'1.2-2'}}</td>
      </ng-container>

      <!-- Cost per Lifted Conversion Column -->
      <ng-container matColumnDef="costPerLiftedConversion">
        <th mat-header-cell *matHeaderCellDef
          [matTooltip]="helpMessagesService.didResultsPage.TABLE_HEADER_COST_PER_LIFTED_CONV"
          matTooltipClass="unset-max-width-tooltip"
          >Cost per Lifted Conversion</th>
        <td mat-cell *matCellDef="let element">{{element.costPerLiftedConversion}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="view-result-container view-result-entry">
    <h2 [matTooltip]="helpMessagesService.didResultsPage.GRAPH_KPI_TREND"
      matTooltipClass="multi-line-tooltip max-width-tooltip"
      i18n>KPI Trend - Pre Period vs. Experiment Period</h2>
    <app-vega-chart [spec]="kpiGraph"/>
  </div>
  <div class="view-result-container view-result-entry">
    <h2 [matTooltip]="helpMessagesService.didResultsPage.GRAPH_AVG_KPI_DIFF"
      matTooltipClass="multi-line-tooltip unset-max-width-tooltip"
      i18n>Average KPI - Difference from Pre Period</h2>
    <app-vega-chart [spec]="averageKpiGraph"/>
    <div>
      <app-routing-button class="left-button" [buttonLabel]="'Rerun'"
        [matTooltip]="helpMessagesService.experimentCommonInputFields.BUTTON_RERUN"
        matTooltipClass="max-width-tooltip"
        [forceTransitionToContinueImpactMeasurement]="true"
        [experimentId]="experimentId" [action]="sendImpactMeasurementAction()"/>
    </div>
  </div>
</div>