<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <span class="edit-exp-details-btn">
    <h2 i18n class="exp-details-title">Experiment Details</h2>
    <button (click)="flipExperimentDetailsEditMode()" mat-icon-button i18n>
      <mat-icon>edit</mat-icon>
    </button>
  </span>
  <ng-container *ngIf="!editMode; else experimentEditForm">
    <mat-card class="view-experiment-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>Experiment ID</mat-card-subtitle>
            <p>{{experimentId}}</p>
          </mat-card-title-group>
        </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle class="card-subtitle-one-line"
              i18n>Experiment Name</mat-card-subtitle>
            <p class="wrap-to-fit-card">{{experimentName}}</p>
          </mat-card-title-group>
        </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Experiment Creation</mat-card-subtitle>
          <p>{{experimentCreationDate | date:dateDisplayFormat}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-last-field">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-subtitle i18n>Experiment Description</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content class="wrap-to-fit-card">
          <i>{{experimentDescription}}</i>
      </mat-card-content>
    </mat-card>
    </ng-container>
    <ng-template #experimentEditForm>
      <div class="edit-exp-details-input">
        <app-new-experiment-description
          (descriptionModified)="storeEditedExperimentDescription($event)"
          (nameModified)="storeEditedExperimentName($event)"
          [defaultName]="experimentName" [defaultDescription]="experimentDescription"/>
      </div>
      <div class="edit-exp-details-save-btn">
        <button (click)="saveEditedExperimentDetails()"
          mat-raised-button color="primary" i18n>
          <mat-icon>save</mat-icon>Save
        </button>
        <button (click)="closeExperimentDetailsEditMode()"
          mat-raised-button color="primary" style="margin-left: 1em;" i18n>
          <mat-icon>cancel</mat-icon>Cancel
        </button>
      </div>
    </ng-template>

  <h2 i18n>Execution Status</h2>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Task Status</mat-card-subtitle>
          <p [ngStyle]="styleTaskStatus()">{{getTaskStatusForDisplay()}}</p>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-footer *ngIf="isProgressBarVisible()">
          <!-- TODO(): Replace the aria-label with a better description, e.g. "Fetching user data" -->
          <mat-progress-bar mode="indeterminate" aria-label="Loading"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
    <mat-card class="view-experiment-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>Task ID</mat-card-subtitle>
            <p>{{taskId}}</p>
          </mat-card-title-group>
        </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Task Creation</mat-card-subtitle>
          <p>{{taskCreationDate | date:dateDisplayFormat}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Execution Time</mat-card-subtitle>
          <p *ngIf="isProgressBarVisible(); else completionTime"
            >{{(taskExecutionTime | async) ?? '0s'}}</p>
          <ng-template #completionTime>
            <p>{{updateTaskExecutionDuration(task.lastModified)}}</p>
          </ng-template>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
  <mat-divider></mat-divider>
</div>