/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component} from '@angular/core';
import * as labels from './labels';

/**
 * Component to display footer.
 */
@Component({
  standalone: false,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly ee4eClientVersion = labels.EE4E_CLIENT_VERSION;
  readonly ee4eBackendVersion = labels.EE4E_BACKEND_VERSION;
  readonly ee4eCorePackageVersion = labels.EE4E_CORE_PACKAGE_VERSION;
  readonly causalImpactURL = labels.CAUSAL_IMPACT_URL;
  readonly ee4eManualURL = labels.EE4E_MANUAL_URL;
}
