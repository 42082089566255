/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {ActivatedRoute} from '@angular/router';

import {Experiment} from '../../../model/experiment';
import {SplittingTaskResult} from '../../../model/splitting-task-result';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {TaskTypeEnum} from '../../../model/task-type-enum';
import {BusinessLogicService} from '../../../service/business-logic.service';
import {HelpMessagesService} from '../../../service/help-messages.service';
/**
 * Component displaying Splitting results set information like aggregated KPI
 * and group definitions. This component also allows user to select the plan
 * to use for the rest of the experiment.
 */
@Component({
  standalone: false,
  selector: 'app-result-set-display-and-selection',
  templateUrl: './result-set-display-and-selection.component.html',
  styleUrls: ['./result-set-display-and-selection.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class ResultSetDisplayAndSelectionComponent implements AfterViewChecked {
  private readonly destroyedRef = inject(DestroyRef);
  protected currentExperiment!: Experiment;
  protected splittingResults!: SplittingTaskResult;

  protected canDisplayResultSetInformation = true;
  /** Selected ResultSet (Plan), by default "Plan_1" (Result Set 0). */
  selectedResultSet = 0;
  protected groupMembers!: string[][];
  protected kpiDataframe!: Record<string, Record<string, number>>;

  private readonly snackBarActionLabel = 'OK';
  private errorMessage = '';
  private snackBarCalled = false;
  private action!: string;

  @Output()
  readonly planSelectionModified = new EventEmitter<number>();
  @Output() readonly testGroupsSelectionModified = new EventEmitter<string[]>();
  @Output() readonly groupMembersToExcludeModified = new EventEmitter<
    Map<string, FormControl<string[] | null>>
  >();
  /** All possible test groups that can be selected. */
  protected allPossibleTestGroupsForSelection: string[] = [];
  /** Currently selected test Groups (A subset of testGroupsSelection). */
  selectedTestGroups: string[] = [];
  groupMembersToExclude = new Map<string, FormControl<string[] | null>>();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly businessLogicService: BusinessLogicService,
    readonly errorSnackBar: MatSnackBar,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {
    const experimentId = this.route.snapshot.paramMap.get('experimentId')!;
    this.action = this.route.snapshot.paramMap.get('action')!;

    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment | null) => {
        if (!experiment) {
          this.errorMessage = `Experiment: ${experimentId} not found.`;
          return;
        }
        if (!experiment.tasks[TaskTypeEnum.SPLIT]) {
          this.errorMessage =
            'Invalid transition. There are no splitting' +
            ' results available for this experiment.';
          return;
        }
        if (!experiment.tasks[TaskTypeEnum.SPLIT].taskResult) {
          this.errorMessage =
            'Invalid transition. Splitting task is not yet completed.';
          return;
        }
        this.currentExperiment = experiment;
        this.splittingResults = experiment.tasks[TaskTypeEnum.SPLIT]
          .taskResult as SplittingTaskResult;
        this.groupMembers =
          this.splittingResults.splitting_result_sets[
            this.selectedResultSet
          ].group_members;
        this.kpiDataframe =
          this.splittingResults.splitting_result_sets[
            this.selectedResultSet
          ].kpi_dataframe;
        const numberOfGroups =
          this.splittingResults.splitting_parameters.number_of_groups;
        this.groupMembersToExclude.set(
          'Control',
          new FormControl<string[] | null>([]),
        );
        for (let i = 1; i < numberOfGroups; i++) {
          this.allPossibleTestGroupsForSelection.push(`Test_${i}`);
          this.groupMembersToExclude.set(
            `Test_${i}`,
            new FormControl<string[] | null>([]),
          );
        }
      });
  }

  ngAfterViewChecked(): void {
    if (this.errorMessage && !this.snackBarCalled) {
      this.snackBarCalled = true;
      this.errorSnackBar.open(this.errorMessage, this.snackBarActionLabel);
    }
  }

  /** Reflects plan selection by updating groupMembers and kpi tables. */
  updateTableDataWhenChangingPlan(): void {
    this.groupMembers =
      this.splittingResults.splitting_result_sets[
        this.selectedResultSet
      ].group_members;
    this.kpiDataframe =
      this.splittingResults.splitting_result_sets[
        this.selectedResultSet
      ].kpi_dataframe;
    this.canDisplayResultSetInformation = false;
    this.changeDetector.detectChanges();
    this.canDisplayResultSetInformation = true;
    // Reset all forms for members exclusions when the plan currently
    // selected changes.
    this.groupMembersToExclude.set(
      'Control',
      new FormControl<string[] | null>([]),
    );
    for (const testGroup of this.allPossibleTestGroupsForSelection) {
      this.groupMembersToExclude.set(
        testGroup,
        new FormControl<string[] | null>([]),
      );
    }
    this.planSelectionModified.emit(this.selectedResultSet);
  }

  emitGroupsSelection(selectedTestGroups: string[]) {
    this.testGroupsSelectionModified.emit(selectedTestGroups);

    this.selectedTestGroups = [...selectedTestGroups];
    // Reset the forms for members exclusions of groups that are not
    // selected anymore.
    const selectedTestGroupsAsSet = new Set(this.selectedTestGroups);
    for (const testGroup of this.allPossibleTestGroupsForSelection) {
      if (!selectedTestGroupsAsSet.has(testGroup)) {
        this.groupMembersToExclude.set(
          testGroup,
          new FormControl<string[] | null>([]),
        );
      }
    }
    this.groupMembersToExcludeModified.emit(this.groupMembersToExclude);
  }

  protected convertToTestGroupIndex(testGroup: string): number {
    // The Control group is always located at the 0 in `groupMembers`,
    // Members for the test group: Test_${i} will be located at the index i.
    // Test groups naming conventions: Test_${i} where i is an Integer
    // larger or equal to 1.
    const testGroupIndex = Number(testGroup.split('_')[1]);
    if (!isFinite(testGroupIndex)) {
      throw new Error(`Invalid test group: ${testGroup}`);
    }
    return testGroupIndex;
  }

  protected isImpactMeasurement(): boolean {
    return this.action === StartingPointEnum.IMPACT_MEASUREMENT;
  }
}
