<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <mat-tab-group color="accent">
    <mat-tab label="Plan_{{i+1}}" *ngFor="let resultSet of splittingResultSets; index as i">
      <div class="view-result-container">
        <app-group-member-allocations-table [groupMembers]="resultSet.group_members"
          [selectedPlan]="i+1" [isTableDownloadable]="true"/>
      </div>
      <div class="view-result-container">
        <app-data-table [tableTitle]="'Summary Information'"
        [matTooltip]="helpMessagesService.splittingResultsPage.TABLE_SUMMARY"
        matTooltipClass="multi-line-tooltip max-width-tooltip"
        [canOverflow]="true" [dataFrame]="resultSet.summary_dataframe"/>
      </div>
      <div class="view-result-container">
        <app-data-table [tableTitle]="'KPI per Group'"
          [matTooltip]="helpMessagesService.splittingResultsPage.TABLE_KPI_PER_GROUP"
          matTooltipClass="max-width-tooltip" [isTableDownloadable]="true"
          [canOverflow]="true" [dataFrame]="resultSet.kpi_dataframe"/>
      </div>
      <div class="view-result-container">
        <h2
          [matTooltip]="helpMessagesService.splittingResultsPage.GRAPH_KPI_PER_GROUP"
          matTooltipClass="unset-max-width-tooltip"
          i18n>KPI per Group Graph</h2>
        <app-vega-chart [spec]="kpiGraphs[i]"/>
      </div>
      <div class="view-result-container">
        <app-data-table [tableTitle]="'Normalized KPI per Group'"
          [matTooltip]="helpMessagesService.splittingResultsPage.TABLE_NORMALIZED_KPI_PER_GROUP"
          matTooltipClass="multi-line-tooltip max-width-tooltip"
          [canOverflow]="true" [dataFrame]="resultSet.normalized_kpi_dataframe"/>
      </div>
      <div class="view-result-container">
        <h2
          [matTooltip]="helpMessagesService.splittingResultsPage.GRAPH_NORMALIZED_KPI_PER_GROUP"
          matTooltipClass="max-width-tooltip"
          i18n>Normalized KPI per Group Graph</h2>
        <app-vega-chart [spec]="normalizedKpiGraphs[i]"/>
      </div>
      <div class="view-result-buttons">
        <app-rerun-button class="left-button" [task]="task" [experimentId]="experimentId"
          [matTooltip]="helpMessagesService.experimentCommonInputFields.BUTTON_RERUN"
          matTooltipClass="max-width-tooltip"
          />
        <app-routing-button
          [buttonLabel]="'Budget Simulation (Optional)'" class="left-button"
          [experimentId]="experimentId" [action]="sendBudgetSimulationAction()"
          [matTooltip]="helpMessagesService.splittingResultsPage.BUTTON_GO_TO_BUDGET_SIMULATION"
          matTooltipClass="multi-line-tooltip max-width-tooltip" />
        <app-routing-button [buttonLabel]="'Impact Measurement'"
          [experimentId]="experimentId" [action]="sendImpactMeasurementAction()"
          [matTooltip]="helpMessagesService.splittingResultsPage.BUTTON_GO_TO_IMPACT_MEASUREMENT"
          matTooltipClass="max-width-tooltip"/>
    </div>
    </mat-tab>
  </mat-tab-group>
</div>