/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

interface AdditionalPatternConstraint {
  regex: RegExp;
  errorMessage: string;
}

/** Component used as numeric input field when creating new experiments. */
@Component({
  standalone: false,
  selector: 'app-numeric-input-field',
  templateUrl: './numeric-input-field.component.html',
  styleUrls: ['./numeric-input-field.component.scss'],
})
export class NumericInputFieldComponent implements OnInit {
  @Input() fieldLabel = '';
  @Input() fieldMustBeInteger = false;
  @Input() defaultValue = 0.95;
  @Input() step = 0.01;
  @Input() maxVal = 0.99;
  @Input() minVal = 0.01;
  @Input() additionalPatternConstraints: AdditionalPatternConstraint[] = [];
  fieldValueForm = this.formBuilder.group({
    fieldValue: [this.defaultValue, []],
  });
  constructor(private formBuilder: FormBuilder) {}

  @Output()
  readonly fieldModified = new EventEmitter<Record<string, FormGroup>>();

  get fieldValue(): number | null {
    return this.fieldValueForm.controls.fieldValue.value;
  }

  set fieldValue(value: number) {
    this.fieldValueForm.controls['fieldValue'].setValue(value);
  }

  isValueOutOfBound(): boolean {
    if (this.fieldValue === null) return false;
    return this.fieldValue < this.minVal || this.fieldValue > this.maxVal;
  }

  isNotInteger(): boolean {
    if (this.fieldValue === null) return false;
    return !Number.isInteger(this.fieldValue);
  }

  getFirstErrorDueToAdditionalConstraints(): string {
    if (this.fieldValue === null) return '';

    const fieldValueAsString = this.fieldValue.toString();
    for (const constraint of this.additionalPatternConstraints) {
      if (!constraint.regex.test(fieldValueAsString)) {
        return constraint.errorMessage;
      }
    }
    return '';
  }

  ngOnInit() {
    const validators = [
      Validators.required,
      Validators.min(this.minVal),
      Validators.max(this.maxVal),
    ];
    if (this.fieldMustBeInteger) {
      validators.push(Validators.pattern('^-?\\d+$'));
    }

    for (const constraint of this.additionalPatternConstraints) {
      validators.push(Validators.pattern(constraint.regex));
    }

    this.fieldValueForm = this.formBuilder.group({
      fieldValue: [this.defaultValue, validators],
    });
  }

  sendModifiedForm(): void {
    this.fieldModified.emit({[this.fieldLabel]: this.fieldValueForm});
  }
}
