/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';

import {BudgetGraphParameter} from '../model/budget-graph-parameter';
import {BudgetSimulationGraphRequest} from '../model/budget-simulation-graph-task-request';
import {BudgetSimulationGraphTaskResult} from '../model/budget-simulation-table-graph-result';
import {BudgetSimulationTableRequest} from '../model/budget-simulation-table-task-request';
import {BudgetSimulationTableTaskResult} from '../model/budget-simulation-table-task-result';
import {BudgetTableParameter} from '../model/budget-table-parameter';
import {CausalImpactAnalysisParameters} from '../model/causal-impact-analysis-parameters';
import {CausalImpactAnalysisRequest} from '../model/causal-impact-analysis-request';
import {CausalImpactTaskResult} from '../model/causal-impact-task-result';
import {DidAnalysisParameters} from '../model/did-analysis-parameters';
import {DidAnalysisRequest} from '../model/did-analysis-request';
import {DidTaskResult} from '../model/did-task-result';
import {Experiment} from '../model/experiment';
import {FileInfo} from '../model/file-info';
import {FileTypeEnum} from '../model/file-type-enum';
import {SplittingParameters} from '../model/splitting-parameters';
import {SplittingTaskRequest} from '../model/splitting-task-request';
import {SplittingTaskResult} from '../model/splitting-task-result';
import {StartingPointEnum} from '../model/starting-point-enum';
import {Task} from '../model/task';
import {TaskCancellationResponse} from '../model/task-cancellation-response';
import {TaskStatusEnum} from '../model/task-status-enum';
import {TaskTypeEnum} from '../model/task-type-enum';
import {TaskidResponse} from '../model/taskid-response';

import * as _ from 'lodash';
import {Ee4eApiService} from './ee4e-api.service';
import {FileReaderService} from './file-reader.service';
import {IndexedDBService} from './idb.service';
import {UtilService} from './util.service';

const SELECTED_PLAN = 'selectedPlan';
const CONTROL_GROUP = 'Control';

type TaskRequest =
  | SplittingTaskRequest
  | BudgetSimulationTableRequest
  | BudgetSimulationGraphRequest
  | CausalImpactAnalysisRequest
  | DidAnalysisRequest;

type RequestParameter =
  | SplittingParameters
  | BudgetTableParameter
  | BudgetGraphParameter
  | DidAnalysisParameters
  | CausalImpactAnalysisParameters;

type TaskResult =
  | SplittingTaskResult
  | BudgetSimulationTableTaskResult
  | BudgetSimulationGraphTaskResult
  | CausalImpactTaskResult
  | DidTaskResult;

function isSplittingTaskRequest(
  request: TaskRequest,
): request is SplittingTaskRequest {
  const splittingTaskRequest = request as SplittingTaskRequest;
  return (
    splittingTaskRequest.splitting_parameters !== undefined &&
    splittingTaskRequest.kpi_csv_file !== undefined &&
    splittingTaskRequest.config_csv_file !== undefined
  );
}

function isCausalImpactAnalysisRequest(
  request: TaskRequest,
): request is CausalImpactAnalysisRequest {
  const causalImpactAnalysisRequest = request as CausalImpactAnalysisRequest;
  return (
    causalImpactAnalysisRequest.post_experiment_kpi_file !== undefined &&
    causalImpactAnalysisRequest.causal_impact_analysis_parameters !== undefined
  );
}

function isBudgetSimulationTableRequest(
  request: TaskRequest,
): request is BudgetSimulationTableRequest {
  return (
    (request as BudgetSimulationTableRequest).budget_table_parameters !==
    undefined
  );
}

function isBudgetSimulationGraphRequest(
  request: TaskRequest,
): request is BudgetSimulationGraphRequest {
  return (
    (request as BudgetSimulationGraphRequest).budget_graphs_parameters !==
    undefined
  );
}

function isDidAnalysisRequest(
  request: TaskRequest,
): request is DidAnalysisRequest {
  const didAnalysisRequest = request as DidAnalysisRequest;
  return (
    didAnalysisRequest.post_experiment_kpi_file !== undefined &&
    didAnalysisRequest.did_analysis_parameters !== undefined
  );
}

function setSampleDates(task: Task, sampleDates: [string, string]) {
  const firstSampleDate = moment.utc(sampleDates[0]);
  const lastSampleDate = moment.utc(sampleDates[1]);
  if (!(isNaN(firstSampleDate.date()) || isNaN(lastSampleDate.date()))) {
    const kpiFileInfo = task.fileInfos[FileTypeEnum.KPI];
    if (kpiFileInfo) {
      kpiFileInfo.extra = {
        firstSampleDate: firstSampleDate.toDate(),
        lastSampleDate: lastSampleDate.toDate(),
      };
    }
  }
}

function createExperiment(
  id: string,
  name: string,
  description: string,
  currentDate: Date,
  startingPoint: StartingPointEnum,
): Experiment {
  return {
    id,
    name,
    description,
    created: currentDate,
    lastModified: currentDate,
    startingPoint,

    tasks: {},
  };
}

function createTask(
  response: TaskidResponse,
  currentDate: Date,
  request: TaskRequest,
  taskType: TaskTypeEnum,
  taskParameter: RequestParameter,
  extra?: Record<string, unknown>,
) {
  return {
    taskId: response.task_id,
    taskStatus: TaskStatusEnum.QUEUED,
    taskParameters: taskParameter,
    taskResult: null,
    taskTraceback: null,
    created: currentDate,
    lastModified: currentDate,
    taskType,
    fileInfos: createFileInfos(request),
    extra: extra ?? null,
  };
}

function createFileInfo(file: File) {
  return {
    fileName: file.name,
    size: file.size,
    path: file.webkitRelativePath,
    extra: {},
  };
}

function createFileInfos(request: TaskRequest) {
  const fileInfos: Partial<{[K in FileTypeEnum]: FileInfo}> = {};
  if (isSplittingTaskRequest(request)) {
    fileInfos[FileTypeEnum.KPI] = createFileInfo(request.kpi_csv_file);
    if (request.config_csv_file) {
      fileInfos[FileTypeEnum.CONFIG] = createFileInfo(request.config_csv_file);
    }
  } else if (
    isCausalImpactAnalysisRequest(request) ||
    isDidAnalysisRequest(request)
  ) {
    fileInfos[FileTypeEnum.KPI] = createFileInfo(
      request.post_experiment_kpi_file,
    );
  } else if (isBudgetSimulationTableRequest(request)) {
    if (request.kpi_csv_file) {
      fileInfos[FileTypeEnum.KPI] = createFileInfo(request.kpi_csv_file);
    }
  } else if (isBudgetSimulationGraphRequest(request)) {
    return {};
  } else {
    throw new Error('Unsupported task request');
  }

  return fileInfos;
}

function createBudgetSimulationTableRequest(
  experiment: Experiment,
  icpa: number,
  durations: number[],
  uplifts: number[],
  testGroups: string[],
  selectedPlan: number,
): BudgetSimulationTableRequest {
  const task = experiment.tasks[TaskTypeEnum.SPLIT];
  if (task === undefined) {
    throw new Error('No splitting task found');
  }
  if (task.taskStatus !== TaskStatusEnum.COMPLETED) {
    throw new Error('Splitting task not completed');
  }
  const taskResult = task.taskResult as SplittingTaskResult;
  return {
    budget_table_parameters: {
      icpa,
      durations,
      uplifts,
      test_groups: testGroups,
      has_external_kpi: false,
      groups_kpi: taskResult.splitting_result_sets[selectedPlan].kpi_dataframe,
    },
  };
}

function retrieveTaskResult(
  experiment: Experiment,
  taskType: TaskTypeEnum,
): TaskResult {
  const task = experiment.tasks[taskType];
  if (task === undefined) {
    throw new Error(`No ${taskType} task found`);
  }
  if (task.taskStatus !== TaskStatusEnum.COMPLETED) {
    throw new Error(`${taskType} task not completed`);
  }
  return task.taskResult as TaskResult;
}

function retrieveGroupStructures(
  experiment: Experiment,
  testGroups: string[],
  selectedPlan: number,
): Record<string, string[]> {
  const taskResult = retrieveTaskResult(
    experiment,
    TaskTypeEnum.SPLIT,
  ) as SplittingTaskResult;
  const groupStructures: Record<string, string[]> = {
    [CONTROL_GROUP]:
      taskResult.splitting_result_sets[selectedPlan].group_members[0],
  };
  for (const testGroup of testGroups) {
    const index = Number(testGroup.split('_')[1]);
    groupStructures[testGroup] =
      taskResult.splitting_result_sets[selectedPlan].group_members[index];
  }
  return groupStructures;
}

function buildGroupRelatedRequestMetadata(
  experiment: Experiment,
  testGroups: string[],
  selectedPlan: number,
): [boolean, Record<string, string[]>] {
  let groupStructures = {};
  let kpiAggregatedByGroups = true;
  if (experiment.startingPoint === StartingPointEnum.SPLITTING) {
    groupStructures = retrieveGroupStructures(
      experiment,
      testGroups,
      selectedPlan,
    );
    kpiAggregatedByGroups = false;
  }
  return [kpiAggregatedByGroups, groupStructures];
}

function createDidTaskRequest(
  experiment: Experiment,
  postKPICSVFile: File,
  preExperimentStartDate: string,
  preExperimentEndDate: string,
  experimentStartDate: string,
  experimentEndDate: string,
  testGroupsCosts: number[],
  testGroups: string[],
  memberToExclude: Record<string, string[]>,
  selectedPlan: number,
): DidAnalysisRequest {
  const [kpiAggregatedByGroups, groupsStructures] =
    buildGroupRelatedRequestMetadata(experiment, testGroups, selectedPlan);
  return {
    post_experiment_kpi_file: postKPICSVFile,
    did_analysis_parameters: {
      pre_experiment_start_date: preExperimentStartDate,
      pre_experiment_end_date: preExperimentEndDate,
      experiment_start_date: experimentStartDate,
      experiment_end_date: experimentEndDate,
      kpi_aggregated_by_groups: kpiAggregatedByGroups,
      test_groups_costs: testGroupsCosts,
      test_groups: testGroups,
      groups_structure: groupsStructures,
      members_to_exclude: memberToExclude,
    },
  };
}

function createBudgetSimulationGraphTaskRequest(
  experiment: Experiment,
  icpa: number,
  duration: number,
  uplift: number,
  testGroup: string,
): BudgetSimulationGraphRequest {
  let controlGroupKpi;
  let testGroupKpi;
  if (experiment.startingPoint === StartingPointEnum.SPLITTING) {
    const budgetTableTask = experiment.tasks[TaskTypeEnum.BUDGET_TABLE];
    if (budgetTableTask === undefined) {
      throw new Error('No splitting task found');
    }
    const extra = budgetTableTask.extra;
    if (extra === null) {
      throw new Error('No extra metadata found');
    }
    const selectedPlan = extra[SELECTED_PLAN] as number;
    const taskResult = retrieveTaskResult(
      experiment,
      TaskTypeEnum.SPLIT,
    ) as SplittingTaskResult;
    const splittingResultSet = taskResult.splitting_result_sets[selectedPlan];
    controlGroupKpi = splittingResultSet.kpi_dataframe[CONTROL_GROUP];
    testGroupKpi = splittingResultSet.kpi_dataframe[testGroup];
  } else if (experiment.startingPoint === StartingPointEnum.BUDGET_SIMULATION) {
    const taskResult = retrieveTaskResult(
      experiment,
      TaskTypeEnum.BUDGET_TABLE,
    ) as BudgetSimulationTableTaskResult;
    controlGroupKpi = taskResult.groups_kpi[CONTROL_GROUP];
    testGroupKpi = taskResult.groups_kpi[testGroup];
  } else {
    throw new Error('Invalid starting point');
  }
  return {
    budget_graphs_parameters: {
      icpa,
      duration,
      uplift,
      test_group: testGroup,
      control_group_kpi: controlGroupKpi,
      test_group_kpi: testGroupKpi,
    },
  };
}

/** A service wrapper that aggregates multiple calls to underlying services. */
@Injectable({providedIn: 'root'})
export class BusinessLogicService {
  constructor(
    private ee4eApiService: Ee4eApiService,
    private idb: IndexedDBService,
    private fileReaderService: FileReaderService,
    private utilService: UtilService,
  ) {}

  private recordUsage(experiment: Experiment, field4: string, field5: string) {
    let fields: string[] = [];
    const metricsStr = window.localStorage.getItem('metrics') as string;
    if (!_.isEmpty(metricsStr)) {
      const metrics = JSON.parse(metricsStr);
      experiment.extra = metrics;
      fields = Object.values(metrics);
      window.localStorage.removeItem('metrics');
    } else if (experiment.extra !== undefined) {
      fields = Object.values(experiment.extra as string[]);
    }
    if (fields.length >= 3) {
      this.utilService.processMetrics(
        fields[0],
        fields[1],
        fields[2],
        field4,
        field5,
      );
    }
  }

  /**
   * Creates a splitting task and saves the metadata to the indexed db.
   *
   * @param name The name of the experiment.
   * @param description The description of the experiment.
   * @param request The request to create a splitting task.
   *
   * @return An observable emitting the key of the splitting task just created.
   */
  createSplittingTask(
    name: string,
    description: string,
    request: SplittingTaskRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.fileReaderService.extractFirstAndLastDates(
        request.kpi_csv_file,
      ),
      taskIdResponse: this.ee4eApiService.createSplitTask(request),
    }).pipe(
      map(({sampleDates, taskIdResponse}) => {
        const now = new Date();
        const experiment = createExperiment(
          this.utilService.generateId(),
          name,
          description,
          now,
          StartingPointEnum.SPLITTING,
        );
        const task = createTask(
          taskIdResponse,
          now,
          request,
          TaskTypeEnum.SPLIT,
          request.splitting_parameters,
        );
        if (sampleDates) {
          setSampleDates(task, sampleDates);
        }
        experiment.tasks[TaskTypeEnum.SPLIT] = task;

        this.recordUsage(
          experiment,
          TaskTypeEnum.SPLIT,
          request.splitting_parameters.number_of_result_sets.toString(),
        );

        return experiment;
      }),
      concatMap((experiment) => this.idb.saveExperiment(experiment)),
    );
  }

  /**
   * Reruns a splitting task and saves the metadata to the indexed db.
   *
   * @param experimentId The id of an existing experiment.
   * @param request The request to create a splitting task.
   *
   * @return An observable emitting the key of the splitting task just created.
   */
  createSplittingTaskForRerun(
    experimentId: string,
    request: SplittingTaskRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.fileReaderService.extractFirstAndLastDates(
        request.kpi_csv_file,
      ),
      taskIdResponse: this.ee4eApiService.createSplitTask(request),
      experiment: this.getExperiment(experimentId),
    }).pipe(
      map(({sampleDates, taskIdResponse, experiment}) => {
        const now = new Date();
        experiment.lastModified = now;
        const task = createTask(
          taskIdResponse,
          now,
          request,
          TaskTypeEnum.SPLIT,
          request.splitting_parameters,
        );
        if (sampleDates) {
          setSampleDates(task, sampleDates);
        }
        // remove all existing tasks.
        experiment.tasks = {};
        experiment.tasks[TaskTypeEnum.SPLIT] = task;

        this.recordUsage(
          experiment,
          TaskTypeEnum.SPLIT,
          request.splitting_parameters.number_of_result_sets.toString(),
        );

        return experiment;
      }),
      concatMap((experiment) => this.idb.saveExperiment(experiment)),
    );
  }

  /**
   * Creates a causal impact analysis task and saves the metadata to the indexed
   * db.
   *
   * @param name The name of the experiment.
   * @param description The description of the experiment.
   * @param request The request to create a causal impact analysis task.
   *
   * @return An observable emitting the key of the causal impact analysis task
   * just created.
   */
  createCausalImpactTaskAsNewTask(
    name: string,
    description: string,
    request: CausalImpactAnalysisRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.fileReaderService.extractFirstAndLastDates(
        request.post_experiment_kpi_file,
      ),
      taskIdResponse: this.ee4eApiService.createCausalImpactTask(request),
    }).pipe(
      map(({sampleDates, taskIdResponse}) => {
        const now = new Date();
        const experiment = createExperiment(
          this.utilService.generateId(),
          name,
          description,
          new Date(),
          StartingPointEnum.IMPACT_MEASUREMENT,
        );
        const task = createTask(
          taskIdResponse,
          now,
          request,
          TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
          request.causal_impact_analysis_parameters,
        );
        if (sampleDates) {
          setSampleDates(task, sampleDates);
        }
        experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT] = task;

        this.recordUsage(
          experiment,
          TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
          request.causal_impact_analysis_parameters.test_groups.length.toString(),
        );

        return experiment;
      }),
      concatMap((experiment) => this.idb.saveExperiment(experiment)),
    );
  }

  /**
   * Creates a budget simulation table task and saves the metadata to the
   * indexed db.
   *
   * @param name The name of the experiment.
   * @param description The description of the experiment.
   * @param request The request to create a budget simulation table task.
   *
   * @return An observable emitting the key of the budget simulation table task
   * just created.
   */
  createBudgetSimulationTableTaskAsNewTask(
    name: string,
    description: string,
    request: BudgetSimulationTableRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.tryGetSampleDates(request.kpi_csv_file),
      taskIdResponse:
        this.ee4eApiService.createBudgetSimulationTableTask(request),
    }).pipe(
      map(({sampleDates, taskIdResponse}) => {
        const now = new Date();
        const experiment = createExperiment(
          this.utilService.generateId(),
          name,
          description,
          now,
          StartingPointEnum.BUDGET_SIMULATION,
        );
        const task = createTask(
          taskIdResponse,
          now,
          request,
          TaskTypeEnum.BUDGET_TABLE,
          request.budget_table_parameters,
        );
        experiment.tasks[TaskTypeEnum.BUDGET_TABLE] = task;
        if (sampleDates) {
          setSampleDates(task, sampleDates);
        }

        this.recordUsage(experiment, TaskTypeEnum.BUDGET_TABLE, '1');

        return experiment;
      }),
      concatMap((experiment) => this.idb.saveExperiment(experiment)),
    );
  }

  /**
   * Reruns a budget simulation table task and saves the metadata to the
   * indexed db.
   *
   * @param experimentId The id of the experiment.
   * @param request The request to create a budget simulation table task.
   *
   * @return An observable emitting the key of the budget simulation table task
   * just created.
   */
  createBudgetSimulationTableTaskForRerun(
    experimentId: string,
    request: BudgetSimulationTableRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.tryGetSampleDates(request.kpi_csv_file),
      taskIdResponse:
        this.ee4eApiService.createBudgetSimulationTableTask(request),
      experiment: this.getExperiment(experimentId),
    }).pipe(
      map(({sampleDates, taskIdResponse, experiment}) => {
        const now = new Date();
        experiment.lastModified = now;
        const task = createTask(
          taskIdResponse,
          now,
          request,
          TaskTypeEnum.BUDGET_TABLE,
          request.budget_table_parameters,
        );
        if (sampleDates) {
          setSampleDates(task, sampleDates);
        }

        // remove all existing tasks after splitting.
        delete experiment.tasks[TaskTypeEnum.BUDGET_TABLE];
        delete experiment.tasks[TaskTypeEnum.BUDGET_GRAPHS];
        delete experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT];
        delete experiment.tasks[TaskTypeEnum.ANALYSIS_DID];
        experiment.tasks[TaskTypeEnum.BUDGET_TABLE] = task;

        this.recordUsage(experiment, TaskTypeEnum.BUDGET_TABLE, '1');

        return experiment;
      }),
      concatMap((experiment) => this.idb.saveExperiment(experiment)),
    );
  }

  /**
   * Creates a budget simulation table task for a selected splitting result and
   * appends the analysis result to the experiment.
   *
   * @param experimentId The id of the experiment.
   * @param icpa Estimated Incremental CPA.
   * @param durations The list of input durations in days.
   * @param uplifts The list of input uplifts.
   * @param testGroups Indicates if a CSV KPI file is provided or not.
   * @param selectedPlan The index of the selected plan.
   * @param rerun Indicates if the task is in rerun mode.
   *
   * @return An observable emitting the key of the causal impact analysis task
   * just created.
   */
  createBudgetSimulationTableTaskAsContinuation(
    experimentId: string,
    icpa: number,
    durations: number[],
    uplifts: number[],
    testGroups: string[],
    selectedPlan: number,
    rerun = false,
  ): Observable<unknown> {
    const now = new Date();
    return this.idb.getExperiment(experimentId).pipe(
      concatMap((experiment) => {
        const request = createBudgetSimulationTableRequest(
          experiment,
          icpa,
          durations,
          uplifts,
          testGroups,
          selectedPlan,
        );
        return this.ee4eApiService
          .createBudgetSimulationTableTask(request)
          .pipe(
            concatMap((taskIdResponse) => {
              delete experiment.tasks[TaskTypeEnum.BUDGET_GRAPHS];
              if (rerun) {
                delete experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT];
                delete experiment.tasks[TaskTypeEnum.ANALYSIS_DID];
              }
              experiment.tasks[TaskTypeEnum.BUDGET_TABLE] = createTask(
                taskIdResponse,
                now,
                request,
                TaskTypeEnum.BUDGET_TABLE,
                request.budget_table_parameters,
                {[SELECTED_PLAN]: selectedPlan},
              );
              this.recordUsage(experiment, TaskTypeEnum.BUDGET_TABLE, '1');
              return this.idb.saveExperiment(experiment);
            }),
          );
      }),
    );
  }

  /**
   * Creates a budget simulation graph task for a selected row in the
   * budget simulation table result in the experiment.
   *
   * @param experimentId The id of the experiment.
   * @param icpa The Estimated Incremental CPA.
   * @param duration The input duration in days.
   * @param uplift The input uplift.
   * @param testGroup The key of the selected test group.
   *
   * @return An observable that emits the key of the budget simulation graph
   * task just created.
   */
  createBudgetSimulationGraphTask(
    experimentId: string,
    icpa: number,
    duration: number,
    uplift: number,
    testGroup: string,
  ): Observable<unknown> {
    return this.idb.getExperiment(experimentId).pipe(
      concatMap((experiment) => {
        const request = createBudgetSimulationGraphTaskRequest(
          experiment,
          icpa,
          duration,
          uplift,
          testGroup,
        );
        return this.ee4eApiService
          .createBudgetSimulationGraphTask(request)
          .pipe(
            concatMap((taskIdResponse) => {
              experiment.tasks[TaskTypeEnum.BUDGET_GRAPHS] = createTask(
                taskIdResponse,
                new Date(),
                request,
                TaskTypeEnum.BUDGET_GRAPHS,
                request.budget_graphs_parameters,
              );

              this.recordUsage(experiment, TaskTypeEnum.BUDGET_GRAPHS, '1');

              return this.idb.saveExperiment(experiment);
            }),
          );
      }),
    );
  }

  /**
   * Creates a causal impact analysis task for a selected splitting result and
   * appends the analysis result to the experiment.
   *
   * @param experimentId The id of the experiment.
   * @param selectedPlan The index of the selected plan.
   * @param request The request to create a causal impact analysis task.
   *
   * @return An observable emitting the key of the causal impact analysis task
   * just created.
   */
  createCausalImpactAnalysisTaskAsContinuation(
    experimentId: string,
    selectedPlan: number,
    request: CausalImpactAnalysisRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.fileReaderService.extractFirstAndLastDates(
        request.post_experiment_kpi_file,
      ),
      experiment: this.idb.getExperiment(experimentId),
    }).pipe(
      concatMap(({sampleDates, experiment}) => {
        const [kpiAggregatedByGroups, groupsStructures] =
          buildGroupRelatedRequestMetadata(
            experiment,
            request.causal_impact_analysis_parameters.test_groups,
            selectedPlan,
          );

        request.causal_impact_analysis_parameters.kpi_aggregated_by_groups =
          kpiAggregatedByGroups;
        request.causal_impact_analysis_parameters.groups_structure =
          groupsStructures;

        return this.ee4eApiService.createCausalImpactTask(request).pipe(
          concatMap((taskidResponse) => {
            const task = createTask(
              taskidResponse,
              new Date(),
              request,
              TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
              request.causal_impact_analysis_parameters,
              {[SELECTED_PLAN]: selectedPlan},
            );
            experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT] = task;
            setSampleDates(task, sampleDates);

            this.recordUsage(
              experiment,
              TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
              request.causal_impact_analysis_parameters.test_groups.length.toString(),
            );

            return this.idb.saveExperiment(experiment);
          }),
        );
      }),
    );
  }

  /**
   * Creates a DID task for a selected splitting result and appends the analysis
   * result to the experiment.
   *
   * @param experimentId The id of the experiment.
   * @param postKPICSVFile The post-experiment KPI CSV File.
   * @param preExperimentStartDate The start date of the pre-period.
   * @param preExperimentEndDate The end date of the pre-period.
   * @param experimentStartDate The start date of the experiment.
   * @param experimentEndDate The start date of the experiment.
   * @param testGroupsCosts Costs for each Test group.
   * @param testGroups The key of the selected test group.
   * @param membersToExclude The excluded members per group.
   * @param selectedPlan The index of the selected plan.
   *
   * @return An observable emitting the key of the causal impact analysis task
   * just created.
   */
  createDidAnalysisTaskAsContinuation(
    experimentId: string,
    postKPICSVFile: File,
    preExperimentStartDate: string,
    preExperimentEndDate: string,
    experimentStartDate: string,
    experimentEndDate: string,
    testGroupsCosts: number[],
    testGroups: string[],
    membersToExclude: Record<string, string[]>,
    selectedPlan: number,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates:
        this.fileReaderService.extractFirstAndLastDates(postKPICSVFile),
      experiment: this.idb.getExperiment(experimentId),
    }).pipe(
      concatMap(({sampleDates, experiment}) => {
        const request = createDidTaskRequest(
          experiment,
          postKPICSVFile,
          preExperimentStartDate,
          preExperimentEndDate,
          experimentStartDate,
          experimentEndDate,
          testGroupsCosts,
          testGroups,
          membersToExclude,
          selectedPlan,
        );
        return this.ee4eApiService.createDidTask(request).pipe(
          concatMap((taskIdResponse) => {
            const task = createTask(
              taskIdResponse,
              new Date(),
              request,
              TaskTypeEnum.ANALYSIS_DID,
              request.did_analysis_parameters,
              {[SELECTED_PLAN]: selectedPlan},
            );
            experiment.tasks[TaskTypeEnum.ANALYSIS_DID] = task;
            setSampleDates(task, sampleDates);

            this.recordUsage(experiment, TaskTypeEnum.ANALYSIS_DID, '1');

            return this.idb.saveExperiment(experiment);
          }),
        );
      }),
    );
  }

  /**
   * Creates a DID analysis task and saves the metadata to the
   * indexed db.
   *
   * @param name The name of the experiment.
   * @param description The description of the experiment.
   * @param request The request to create a DID analysis table task.
   *
   * @return An observable emitting the key of the DID analysis task just
   * created.
   */
  createDidAnalysisTaskAsNewTask(
    name: string,
    description: string,
    request: DidAnalysisRequest,
  ): Observable<unknown> {
    return forkJoin({
      sampleDates: this.fileReaderService.extractFirstAndLastDates(
        request.post_experiment_kpi_file,
      ),
      taskIdResponse: this.ee4eApiService.createDidTask(request),
    }).pipe(
      map(({sampleDates, taskIdResponse}) => {
        const now = new Date();
        const experiment = createExperiment(
          this.utilService.generateId(),
          name,
          description,
          now,
          StartingPointEnum.IMPACT_MEASUREMENT,
        );
        const task = createTask(
          taskIdResponse,
          now,
          request,
          TaskTypeEnum.ANALYSIS_DID,
          request.did_analysis_parameters,
        );
        if (sampleDates) {
          setSampleDates(task, sampleDates);
        }
        experiment.tasks[TaskTypeEnum.ANALYSIS_DID] = task;

        this.recordUsage(experiment, TaskTypeEnum.ANALYSIS_DID, '1');

        return experiment;
      }),
      concatMap((experiment) => this.idb.saveExperiment(experiment)),
    );
  }

  /**
   * Lists all experiments.
   *
   * @return An observable emitting all experiments.
   */
  listExperiments(): Observable<Experiment[]> {
    return this.idb.getAllExperiments();
  }

  /**
   * Gets all exportable experiments.
   *
   * @return An observable emitting all exportable experiments.
   */
  getExportableExperiments(): Observable<Experiment[]> {
    return this.idb.getAllExperiments().pipe(
      map((experiments: Experiment[]) =>
        experiments
          .map((experiment: Experiment) => {
            experiment.tasks = this.buildCompletedTaskList(experiment.tasks);
            return experiment;
          })
          .filter((experiments) => Object.keys(experiments.tasks).length > 0),
      ),
    );
  }

  /**
   * Gets exportable experiments with selected ids.
   *
   * @param idList The list of experiment ids to export.
   * @return An observable emitting all selected exportable experiments.
   */
  getSelectedExportableExperiments(idList: string[]): Observable<Experiment[]> {
    return forkJoin(
      idList.map((id) =>
        this.idb.getExperiment(id).pipe(
          map((experiment) => {
            experiment.tasks = this.buildCompletedTaskList(experiment.tasks);
            return experiment;
          }),
        ),
      ),
    ).pipe(
      map((experiments) =>
        experiments.filter(
          (experiment) => Object.keys(experiment.tasks).length > 0,
        ),
      ),
    );
  }

  /**
   * Gets the experiment with the given id.
   *
   * @param id The id of the experiment to get.
   *
   * @return An observable emitting the experiment with the given id.
   */
  getExperiment(id: string): Observable<Experiment> {
    return this.idb.getExperiment(id);
  }

  /**
   * Save an experiment to the indexed db.
   * The function is used for importing experiment via the UI.
   *
   * @param experiment The experiment to save.
   *
   * @return An observable emitting the key of the saved experiment.
   */
  saveExperiment(experiment: Experiment): Observable<unknown> {
    return this.idb.saveExperiment(experiment);
  }

  /**
   * Deletes the experiment with the given list of experiment ids from the IndexedDB database.
   * The function is used for deleting experiment(s) via the UI.
   *
   * @param idList The list of experiment ids to delete.
   *
   * @return An observable emitting the list of ids successfully deleted.
   */
  deleteSelectedExperiments(idList: string[]): Observable<string[]> {
    return forkJoin(
      idList.map((id) =>
        this.idb.deleteExperiment(id).pipe(
          map(() => id),
          // Return an empty string when an error occurs.
          // The experiment id is supposed to be not empty, so the conflict does not happen.
          catchError(() => of('')),
        ),
      ),
    ).pipe(map((results) => results.filter((result) => result !== '')));
  }

  /**
   * Cancels the task with the given id included in a specific experiment.
   *
   * @param experimentId The id of the experiment.
   * @param taskId The id of the task to cancel.
   */
  cancelTask(
    experimentId: string,
    taskId: string,
  ): Observable<TaskCancellationResponse> {
    return this.idb.getExperiment(experimentId).pipe(
      concatMap((experiment) => {
        const task = Object.values(experiment.tasks).filter(
          (task) => task.taskId === taskId,
        )[0];
        if (task === undefined) {
          return throwError(() => new Error(`Task ${taskId} not found.`));
        }
        if (
          task.taskStatus === TaskStatusEnum.COMPLETED ||
          task.taskStatus === TaskStatusEnum.CANCELLED ||
          task.taskStatus === TaskStatusEnum.FAILURE
        ) {
          return throwError(
            () => new Error(`Task ${taskId} cannot be cancelled.`),
          );
        }
        const cancellationResp = this.ee4eApiService.cancelTask(taskId);
        this.ee4eApiService.deleteTask(taskId);
        return cancellationResp;
      }),
    );
  }

  private tryGetSampleDates(
    file: File | undefined,
  ): Observable<[string, string]> {
    if (file) {
      return this.fileReaderService.extractFirstAndLastDates(file);
    } else {
      return new Observable((observer) => {
        observer.next(['', '']);
        observer.complete();
      });
    }
  }

  /**
   * Builds a list with only the completed tasks.
   *
   * @param allTasks All tasks associated to an experiment.
   * @return A list of completed tasks.
   */
  buildCompletedTaskList(
    allTasks: Partial<{[K in TaskTypeEnum]: Task}>,
  ): Partial<{[K in TaskTypeEnum]: Task}> {
    return Object.values(allTasks).reduce(
      (completedTasks, task) => {
        if (task.taskStatus === TaskStatusEnum.COMPLETED) {
          completedTasks[task.taskType] = task;
        }
        return completedTasks;
      },
      {} as {[type in TaskTypeEnum]: Task},
    );
  }
}
