<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <div>
    <h2 i18n>Input Files</h2>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-title>KPI File</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Filename</mat-card-subtitle>
              <p>{{kpiFileName | longStringTruncate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Size</mat-card-subtitle>
              <p>{{kpiFileSize}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>First sample date</mat-card-subtitle>
              <p>{{kpiFirstSampleDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Last sample date</mat-card-subtitle>
              <p>{{kpiLastSampleDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <ng-container *ngIf="configFileInfo">
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-title i18n>Configuration File</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Filename</mat-card-subtitle>
              <p>{{configFileName | longStringTruncate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Size</mat-card-subtitle>
              <p>{{configFileSize}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
      </mat-card-content>
    </mat-card>
    </ng-container>
  </div>
  <div>
    <h2 i18n>Splitting Parameters</h2>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Number of Plans</mat-card-subtitle>
          <p>{{numberOfPlans}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Number of Groups</mat-card-subtitle>
          <p>{{numberOfGroups}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Number of Members per Group</mat-card-subtitle>
          <p>{{displayNumberOfMembersPerGroup()}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Correlation Coefficient</mat-card-subtitle>
          <p>{{correlationCoefficient}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-last-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Data Volume Coefficient</mat-card-subtitle>
          <p>{{dataVolumeCoefficient}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
  </div>
  <div>
    <ng-container *ngIf="additionalVolumeRatios.size > 0">
      <h2 i18n>Configuration Volume Ratios</h2>
      <mat-card class="view-experiment-field" *ngFor="let ratio of additionalVolumeRatios | keyvalue">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>{{ratio.key}}</mat-card-subtitle>
            <p>{{ratio.value}}</p>
          </mat-card-title-group>
        </mat-card-header>
      </mat-card>
    </ng-container>
  </div>
  <mat-divider></mat-divider>
</div>