/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, Input, OnInit, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';

import {Experiment} from '../../../model/experiment';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {TaskTypeEnum} from '../../../model/task-type-enum';
import {BusinessLogicService} from '../../../service/business-logic.service';

import * as routes from '../../../common/routes';

/** Button for routing to Continue Experiment pages. */
@Component({
  standalone: false,
  selector: 'app-routing-button',
  templateUrl: './routing-button.component.html',
  styleUrls: ['./routing-button.component.scss'],
})
export class RoutingButtonComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  /** The experiment ID of the currently viewed experiment. */
  @Input({required: true}) experimentId = '';
  /** The type of continuation page to route to. */
  @Input({required: true}) action: StartingPointEnum =
    StartingPointEnum.SPLITTING;
  /** The label of the button. */
  @Input({required: true}) buttonLabel = '';
  /**
   * This input is to force an Impact Measurement -> Impact Measurement
   * transition to the continuation screen even if a
   * DID or CI task does already exist.
   */
  @Input() forceTransitionToContinueImpactMeasurement = false;
  private routeToNavigate = routes.CONTINUE_EXPERIMENT_URL;

  constructor(
    private router: Router,
    private readonly businessLogicService: BusinessLogicService,
  ) {}

  ngOnInit() {
    // There is no possible transition to the continuation page for
    // a Splitting Task as this task is always the starting point if it exists.
    if (this.action === StartingPointEnum.SPLITTING) {
      this.routeToNavigate = routes.VIEW_EXPERIMENT_URL;
    }
    this.businessLogicService
      .getExperiment(this.experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment) => {
        if (
          this.action === StartingPointEnum.BUDGET_SIMULATION &&
          experiment.tasks[TaskTypeEnum.BUDGET_TABLE]
        ) {
          this.routeToNavigate = routes.VIEW_EXPERIMENT_URL;
        } else if (
          this.action === StartingPointEnum.IMPACT_MEASUREMENT &&
          (experiment.tasks[TaskTypeEnum.ANALYSIS_DID] ||
            experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT])
        ) {
          if (!this.forceTransitionToContinueImpactMeasurement) {
            this.routeToNavigate = routes.VIEW_EXPERIMENT_URL;
          }
        }
      });
  }

  protected navigateToContinueExperimentForCurrentExperiment(): void {
    // Required since Angular Router ignores "OnSameUrlNavigation" by default.
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(
      [`${this.routeToNavigate}/${this.experimentId}/${this.action}`],
      {
        skipLocationChange: true,
      },
    );
  }
}
