/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, Input, OnInit, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';

import * as routes from '../../../common/routes';
import {Experiment} from '../../../model/experiment';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import * as Task from '../../../model/task';
import {TaskTypeEnum} from '../../../model/task-type-enum';
import {BusinessLogicService} from '../../../service/business-logic.service';

/**
 * Component to rerun a task from an experiment.
 */
@Component({
  standalone: false,
  selector: 'app-rerun-button',
  templateUrl: './rerun-button.component.html',
  styleUrls: ['./rerun-button.component.scss'],
})
export class RerunButtonComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  @Input({required: true}) task!: Task.Task;
  @Input({required: true}) experimentId!: string;
  private experimentToRerunTask!: Experiment;

  constructor(
    private readonly businessLogicService: BusinessLogicService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.loadExperiment(this.experimentId);
  }

  private loadExperiment(experimentId: string) {
    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment) => {
        this.experimentToRerunTask = experiment;
      });
  }

  protected rerunTask(): void {
    let rerunTask: StartingPointEnum;
    /** When the current task is DID or CI, we can simply use the
     * current continuation logic as there is no difference if the starting
     * point is Impact Measurement or not for such cases.
     */
    if (
      this.task.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT ||
      this.task.taskType === TaskTypeEnum.ANALYSIS_DID
    ) {
      rerunTask = StartingPointEnum.IMPACT_MEASUREMENT;
      this.router.navigate(
        [`${routes.CONTINUE_EXPERIMENT_URL}/${this.experimentId}/${rerunTask}`],
        {skipLocationChange: true},
      );
    } else if (
      /** For a Budget Simulation task, if the starting point of the experiment
       * is the splitting we can also use the current continuation logic for
       * transitions from Splitting to Budget Simulation.
       */
      this.task.taskType === TaskTypeEnum.BUDGET_TABLE &&
      this.experimentToRerunTask.startingPoint === StartingPointEnum.SPLITTING
    ) {
      rerunTask = StartingPointEnum.BUDGET_SIMULATION;
      this.router.navigate(
        [`${routes.CONTINUE_EXPERIMENT_URL}/${this.experimentId}/${rerunTask}`],
        {skipLocationChange: true},
      );
    } else if (this.task.taskType === TaskTypeEnum.SPLIT) {
      this.router.navigate([`${routes.RERUN_SPLIT_URL}/${this.experimentId}`], {
        skipLocationChange: true,
      });
    } else if (this.task.taskType === TaskTypeEnum.BUDGET_TABLE) {
      this.router.navigate(
        [`${routes.RERUN_BUDGET_SIMULATION_URL}/${this.experimentId}`],
        {
          skipLocationChange: true,
        },
      );
    }
    return;
  }
}
