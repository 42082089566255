/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {DestroyRef, Injectable, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Experiment} from '../model/experiment';
import {SplittingParameters} from '../model/splitting-parameters';
import {Task} from '../model/task';
import {BusinessLogicService} from './business-logic.service';

/**
 * Service to export a task result to a local file.
 */
@Injectable({
  providedIn: 'root',
})
export class TaskResultExportService {
  private experimentId: string | null = null;
  private task: Task | null = null;
  private comments = '';

  private readonly destroyedRef = inject(DestroyRef);
  constructor(private readonly businessLogicService: BusinessLogicService) {}

  setExperimentId(experimentId: string) {
    this.experimentId = experimentId;
  }

  setTask(task: Task) {
    this.task = task;
  }

  private resetComments(): void {
    this.comments = '';
  }

  private addSeparatorComment(): void {
    this.comments += '# ----------------------------------------\n';
  }

  private addBlankLine(): void {
    this.comments += '\n';
  }

  private addComment(key: string, value: string | number | Date): void {
    this.comments += `# ${key}: ${value}\n`;
  }

  private addCommentsForExperiment(experiment: Experiment): void {
    this.addComment('Experiment ID', experiment.id);
    this.addComment('Experiment Name', experiment.name);
    this.addComment('Experiment Creation', experiment.created);
    this.addComment('Experiment Description', experiment.description);
  }

  private addCommentsForTask(task: Task): void {
    this.addComment('Task ID', task.taskId);
    this.addComment('Task Creation', task.created);
  }

  private addCommentsForSplitting(task: Task): void {
    const splittingParameters: SplittingParameters =
      task.taskParameters as SplittingParameters;
    this.addComment('KPI File', task.fileInfos?.KPI?.fileName ?? '');
    this.addComment(
      'Configuration File',
      task.fileInfos?.CONFIG?.fileName ?? '',
    );
    this.addComment(
      'Number of Plans',
      splittingParameters.number_of_result_sets,
    );
    this.addComment('Number of Groups', splittingParameters.number_of_groups);
    this.addComment(
      'Number of Members per Group',
      splittingParameters.number_of_members_per_group,
    );
    this.addComment(
      'Correlation Coefficient',
      splittingParameters.correlation_coefficient,
    );
    this.addComment(
      'Data Volume Coefficient',
      splittingParameters.data_volume_coefficient,
    );
    Object.entries(splittingParameters.config_volume_ratios).forEach(
      ([config, ratio]) => this.addComment(`Volume Ratio for ${config}`, ratio),
    );
  }

  /**
   * Gerenates CSV content consisted of comment lines and table data.
   * An example of CSV content:
   *  # ----------------------------------------
   *  # Experiment ID: 00000000-1111-2222-3333-000000010000
   *  # Experiment Name: Splitting
   *  # Experiment Creation: Fri Nov 24 2023 14:37:40 GMT+0900 (Japan Standard Time)
   *  # Experiment Description: Description
   *  # ----------------------------------------
   *  # Task ID: c3e22382-4a9c-470a-b2ac-52412bb65309
   *  # Task Creation: Fri Nov 24 2023 14:37:40 GMT+0900 (Japan Standard Time)
   *  # ----------------------------------------
   *  # KPI File: kpis.csv
   *  # Configuration File: config.csv
   *  # Number of Plans: 2
   *  # Number of Groups: 4
   *  # Number of Members per Group: -1
   *  # Correlation Coefficient: 0.95
   *  # Data Volume Coefficient: 0.95
   *  # Volume Ratio for Population: 0.9
   *  # Volume Ratio for Area: 0.9
   *  # Selected Plan: 1
   *
   *  member  allocation
   *  Tokushima Control
   *  Shimane Test_1
   *  Gunma Test_2
   *
   * @param {Experiment} experiment - The metadata for tracking an experiment.
   * @param {Task} task - The metadata for a specific type of task.
   * @param {Array<Record<string, string | number | Date>>} dataSource - The
   * table data with the form of [{column1: value1, column2: value2}, {...}]
   * @param {string[]} displayedColumns - The column names for the table.
   * @param {Record<string, string | number | Date>} options - Optional comments.
   * @return {string} The CSV content consisted of comment lines and table data.
   */
  generateCsvContent(
    experiment: Experiment,
    task: Task,
    dataSource: Array<Record<string, string | number | Date>>,
    displayedColumns: string[],
    options?: Record<string, string | number | Date>,
  ): string {
    this.resetComments();

    // Experiment related info.
    this.addSeparatorComment();
    this.addCommentsForExperiment(experiment);

    // Task related info.
    this.addSeparatorComment();
    this.addCommentsForTask(task);

    // Input related info. Currently only splitting tasks are supported.
    this.addSeparatorComment();
    this.addCommentsForSplitting(task);

    // Optional input such as selected plan indices.
    if (options) {
      Object.entries(options).forEach(([key, value]) =>
        this.addComment(key, value),
      );
    }

    // Table data.
    this.addBlankLine();
    const tableData =
      displayedColumns.join(',') +
      '\n' +
      dataSource
        .map((row) => Object.values(row))
        .map((e) => e.join(','))
        .join('\n');

    return this.comments + tableData;
  }

  /**
   * Export the given table data to a CSV file by generating the CSV content
   * from the experiment and task information.
   *
   * @param {string} fileName - The metadata for tracking an experiment.
   * @param {Array<Record<string, string | number | Date>>} dataSource - The
   * table data with the form of [{column1: value1, column2: value2}, {...}]
   * @param {string[]} displayedColumns - The column names for the table.
   * @param {Record<string, string | number | Date>} options - Optional comments.
   */
  exportToCsv(
    fileName: string,
    dataSource: Array<Record<string, string | number | Date>>,
    displayedColumns: string[],
    options?: Record<string, string | number | Date>,
  ): void {
    if (!this.experimentId || !this.task) {
      return;
    }

    this.businessLogicService
      .getExperiment(this.experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment) => {
        const csvContent = this.generateCsvContent(
          experiment,
          this.task!,
          dataSource,
          displayedColumns,
          options,
        );

        // Concatenate comments and content.
        const fileData = 'data:text/csv;charset=utf-8,' + csvContent;
        // Replace # with %23 to properly encode the comments.
        const encodedUri = encodeURI(fileData).replaceAll('#', '%23');

        // Download file.
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  }
}
