/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AfterViewChecked, Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';

import {Experiment} from '../../model/experiment';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {BusinessLogicService} from '../../service/business-logic.service';
/** Root Component for Continuing an experiment.  */
@Component({
  standalone: false,
  selector: 'app-continue-experiment',
  templateUrl: './continue-experiment.component.html',
  styleUrls: ['./continue-experiment.component.scss'],
})
export class ContinueExperimentComponent implements AfterViewChecked {
  private readonly destroyedRef = inject(DestroyRef);

  protected canDisplayBudgetSimulation = false;
  protected canDisplayImpactMeasurement = false;
  protected readonly snackBarActionLabel = 'OK';
  protected errorMessage = '';
  protected action: StartingPointEnum;
  protected experimentToView!: Experiment;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly businessLogicService: BusinessLogicService,
    readonly snackBar: MatSnackBar,
  ) {
    this.action = this.route.snapshot.paramMap.get(
      'action',
    )! as StartingPointEnum;
    const experimentId = this.route.snapshot.paramMap.get('experimentId')!;
    switch (this.action) {
      case StartingPointEnum.BUDGET_SIMULATION: {
        this.canDisplayBudgetSimulation = true;
        break;
      }
      case StartingPointEnum.IMPACT_MEASUREMENT: {
        this.canDisplayImpactMeasurement = true;
        break;
      }
      default: {
        this.errorMessage =
          'Action must be Budget Simulation or Impact Measurement.' +
          ` Received Action: ${this.action}.`;
        break;
      }
    }

    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment | null) => {
        if (!experiment) {
          this.errorMessage = `Experiment: ${experimentId} not found.`;
          return;
        }
        this.experimentToView = experiment;
      });
    // TODO b/313802172 - Consolidate all validation logic here for experiment,
    // and possible transitions.
  }

  ngAfterViewChecked() {
    if (this.errorMessage) {
      this.snackBar.open(this.errorMessage, this.snackBarActionLabel);
      this.errorMessage = '';
    }
  }
}
