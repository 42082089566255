/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Experiment} from '../../model/experiment';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {TaskStatusEnum} from '../../model/task-status-enum';
import {TaskTypeEnum} from '../../model/task-type-enum';

import * as routes from '../routes';

/**
 * Nagivator to browse through the tasks of an existing experiment.
 */
@Component({
  standalone: false,
  selector: 'app-experiment-nagivator',
  templateUrl: './experiment-nagivator.component.html',
  styleUrls: ['./experiment-nagivator.component.scss'],
})
export class ExperimentNagivatorComponent implements OnInit {
  @Input({required: true}) experimentToView!: Experiment;
  @Input({required: true}) action!: StartingPointEnum;

  steps: string[] = [];
  initialStep = 0;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.steps = this.populateSteps(this.experimentToView);
    this.initialStep = this.findInitialStepIndex(
      this.experimentToView,
      this.action,
    );
  }

  selectionChanged(event: StepperSelectionEvent): void {
    this.navigate(event.selectedIndex);
  }

  private navigate(selectedIndex: number): void {
    // Required since Angular Router ignores OnSameUrlNavigation by default.
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(
      [
        `${this.viewOrContinueExperiment(
          this.steps[selectedIndex] as StartingPointEnum,
        )}/${this.experimentToView.id}/${this.steps[selectedIndex]}`,
      ],
      {skipLocationChange: true},
    );
  }

  private viewOrContinueExperiment(action: StartingPointEnum): string {
    const viewExperiment = routes.VIEW_EXPERIMENT_URL;
    const continueExperiment = routes.CONTINUE_EXPERIMENT_URL;
    if (action === StartingPointEnum.SPLITTING) {
      return viewExperiment;
    } else if (action === StartingPointEnum.BUDGET_SIMULATION) {
      if (this.experimentToView.tasks[TaskTypeEnum.BUDGET_TABLE]) {
        return viewExperiment;
      } else return continueExperiment;
    } else {
      if (
        this.experimentToView.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT] ||
        this.experimentToView.tasks[TaskTypeEnum.ANALYSIS_DID]
      ) {
        return viewExperiment;
      } else {
        return continueExperiment;
      }
    }
  }

  private populateSteps(experiment: Experiment): string[] {
    if (
      experiment.startingPoint === StartingPointEnum.SPLITTING &&
      experiment.tasks[TaskTypeEnum.SPLIT]?.taskStatus ===
        TaskStatusEnum.COMPLETED
    ) {
      return [
        StartingPointEnum.SPLITTING,
        StartingPointEnum.BUDGET_SIMULATION,
        StartingPointEnum.IMPACT_MEASUREMENT,
      ];
    } else if (
      experiment.startingPoint === StartingPointEnum.BUDGET_SIMULATION &&
      experiment.tasks[TaskTypeEnum.BUDGET_TABLE]?.taskStatus ===
        TaskStatusEnum.COMPLETED
    ) {
      return [
        StartingPointEnum.BUDGET_SIMULATION,
        StartingPointEnum.IMPACT_MEASUREMENT,
      ];
    } else return [];
  }

  private findInitialStepIndex(experiment: Experiment, action: string): number {
    const steps = this.populateSteps(experiment);
    return steps.findIndex((step) => step === action);
  }
}
