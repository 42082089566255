/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input} from '@angular/core';

import * as constants from '../../../common/constants';
import {formatBytes} from '../../../common/utils';
import {CausalImpactAnalysisParameters} from '../../../model/causal-impact-analysis-parameters';
import {DidAnalysisParameters} from '../../../model/did-analysis-parameters';
import {FileInfo} from '../../../model/file-info';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {TaskTypeEnum} from '../../../model/task-type-enum';

// TODO() use the wrapper instead of directly the IO JSON interface
// used to communicate with the backend (this will allow to not use snake case
// for the following variables).

/**
 * Component displaying Impact Measurement input parameters when viewing an
 * existing Causal Impact or Difference-in-Differences experiment.
 */
@Component({
  standalone: false,
  selector: 'app-impact-measurement-input-files-and-parameters',
  templateUrl: './impact-measurement-input-files-and-parameters.component.html',
  styleUrls: ['./impact-measurement-input-files-and-parameters.component.scss'],
})
export class ImpactMeasurementInputFilesAndParametersComponent {
  readonly methods = ['Causal Impact', 'Difference-in-Differences'];
  readonly controlGroupKeyAndLabel = 'Control';
  selectedMethod!: string;
  preExperimentStartDate!: string;
  preExperimentEndDate!: string;
  experimentStartDate!: string;
  experimentEndDate!: string;
  @Input({required: true}) selectedPlan: string | null = null;
  isMemberBased = false;
  isCausalImpact = false;
  testGroups: string[] = [];
  testGroupsCosts: number[] = [];
  excludedMembers = new Map<string, string[]>();
  @Input({required: true}) startingPoint!: StartingPointEnum;
  @Input({required: true}) taskType!: TaskTypeEnum;
  @Input({required: true})
  analysisParameters!: CausalImpactAnalysisParameters | DidAnalysisParameters;
  @Input({required: true}) kpiFileInfo!: FileInfo;
  kpiFileName!: string;
  kpiFileSize!: string;
  kpiFirstSampleDate!: string;
  kpiLastSampleDate!: string;
  numberOfSeasons = constants.DEFAULT_NUMBER_OF_SEASONS;
  confidenceInterval = constants.DEFAULT_CONFIDENCE_INTERVAL;

  ngOnInit() {
    if (this.startingPoint === StartingPointEnum.SPLITTING) {
      this.isMemberBased = true;
    }
    if (this.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT) {
      this.selectedMethod = this.methods[0];
      this.isCausalImpact = true;
    } else if (this.taskType === TaskTypeEnum.ANALYSIS_DID) {
      this.selectedMethod = this.methods[1];
      this.testGroupsCosts = (
        this.analysisParameters as DidAnalysisParameters
      ).test_groups_costs;
    } else {
      throw new TypeError('Invalid Analysis method.');
    }
    // TODO: b/310811626 remore the if/else after adding testGroups to DID
    // and just use this argument.
    if ('test_groups' in this.analysisParameters) {
      this.testGroups = this.analysisParameters.test_groups;
    } else {
      this.testGroups = (
        this.analysisParameters as DidAnalysisParameters
      ).test_groups_costs.map((value, index) => {
        return `Test_${index + 1}`;
      });
    }
    if (this.isCausalImpact) {
      this.numberOfSeasons =
        (this.analysisParameters as CausalImpactAnalysisParameters)
          ?.number_of_seasons ?? constants.DEFAULT_NUMBER_OF_SEASONS;
      this.confidenceInterval =
        (this.analysisParameters as CausalImpactAnalysisParameters)
          ?.confidence_interval ?? constants.DEFAULT_CONFIDENCE_INTERVAL;
    }
    this.preExperimentStartDate =
      this.analysisParameters.pre_experiment_start_date;
    this.preExperimentEndDate = this.analysisParameters.pre_experiment_end_date;
    this.experimentStartDate = this.analysisParameters.experiment_start_date;
    this.experimentEndDate = this.analysisParameters.experiment_end_date;
    Object.entries(this.analysisParameters.members_to_exclude).forEach(
      ([key, value]) => {
        this.excludedMembers.set(key, value);
      },
    );
    this.kpiFileName = this.kpiFileInfo.fileName;
    this.kpiFileSize = formatBytes(this.kpiFileInfo.size, 1);
    if (this.kpiFileInfo.extra) {
      const firstSampleDate = this.kpiFileInfo.extra['firstSampleDate'] as Date;
      this.kpiFirstSampleDate = firstSampleDate.toISOString().split('T')[0];
      const lastSampleDate = this.kpiFileInfo.extra['lastSampleDate'] as Date;
      this.kpiLastSampleDate = lastSampleDate.toISOString().split('T')[0];
    } else {
      this.kpiFirstSampleDate = '-';
      this.kpiLastSampleDate = '-';
    }
  }
}
