/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {HttpErrorResponse} from '@angular/common/http';

interface ServerValidationErrorDetail {
  loc: string[];
  msg: string;
  type: string;
}

interface ServerValidationError {
  detail: ServerValidationErrorDetail[];
}

/**
 * A data interface that contains the detail of a specific data validation
 * error.
 */
export declare interface ValidationError {
  location: string[];
  message: string;
  type: string;
}

/**
 * An exception class that represents data validation errors from server-side.
 */
export class ValidationErrorResponse extends HttpErrorResponse {
  detail: ValidationError[];
  constructor(errorResponse: HttpErrorResponse) {
    super({
      error: errorResponse.error,
      headers: errorResponse.headers,
      status: errorResponse.status,
      statusText: errorResponse.statusText,
      url: errorResponse.url as string | undefined,
    });
    const serverValidationErrors = JSON.parse(
      errorResponse.error,
    ) as ServerValidationError;

    this.detail = serverValidationErrors.detail.map((o) => {
      return {
        location: o.loc,
        message: o.msg,
        type: o.type,
      };
    });
  }
}
