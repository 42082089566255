/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {TopLevelSpec} from 'vega-lite';
import {
  NormalizedLayerSpec,
  NormalizedUnitSpec,
} from 'vega-lite/build/src/spec';
import {StandardType} from 'vega-lite/build/src/type';

const CHART_WIDTH = 1000;
const CHART_HEIGHT = 400;

/** Converts the KPI time series into the Vega-Lite specification.
 * Additinal layers for highlighting the experiment period are applied
 * when the experiment start date is given.
 *
 * @param kpiData The KPI time series data for each group.
 * @param experimentStartDate The start date of the experiment.
 * @return The Vega-Lite specification.
 */
export function convertToLineChartSpec(
  kpiData: Record<string, Record<string, number>>,
  preExperimentStartDate: string | null,
  preExperimentEndDate: string | null,
  experimentStartDate: string | null,
  experimentEndDate: string | null,
): TopLevelSpec {
  const vegaLiteData = [];

  // Transform the data.
  const groupSet = Object.keys(kpiData);
  for (const group of groupSet) {
    for (const date of Object.keys(kpiData[group])) {
      vegaLiteData.push({
        group,
        date,
        value: kpiData[group][date],
      });
    }
  }

  // Define a base Vega-Lite specification.
  const vegaLiteSpec: TopLevelSpec = {
    '$schema': 'https://vega.github.io/schema/vega-lite/v5.json',
    description: 'A time series chart',
    data: {values: vegaLiteData},
    encoding: {
      x: {
        field: 'date',
        type: 'temporal',
        axis: {
          title: 'Date',
        },
      },
    },
    layer: [
      {
        encoding: {
          y: {
            field: 'value',
            type: 'quantitative',
            'axis': {
              'title': 'KPI',
            },
          },
          color: {field: 'group', type: 'nominal', title: 'Groups'},
        },
        layer: [
          {
            mark: 'line',
            params: [{name: 'grid', select: 'interval', bind: 'scales'}],
          },
        ],
      },
    ],
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
  };

  // Generate a tooltip based on the groups and apply it to the specification.
  const tooltipLayer: NormalizedUnitSpec = {
    transform: [{pivot: 'group', value: 'value', groupby: ['date']}],
    mark: 'rule',
    encoding: {
      opacity: {
        condition: {value: 0.3, param: 'hover', empty: false},
        value: 0,
      },
      tooltip: [
        {field: 'date', type: 'temporal', title: 'Date'},
        ...groupSet.map((group) => ({
          field: group,
          type: 'quantitative' as StandardType,
        })),
      ],
    },
    params: [
      {
        name: 'hover',
        select: {
          type: 'point',
          fields: ['date'],
          nearest: true,
          on: 'pointerover',
          clear: 'pointerout',
        },
      },
    ],
  };
  vegaLiteSpec.layer.push(tooltipLayer);

  if (preExperimentStartDate && preExperimentEndDate) {
    addExperimentPeriodLayer(
      vegaLiteSpec as NormalizedLayerSpec,
      preExperimentStartDate,
      preExperimentEndDate,
      'skyblue',
    );
  }

  // Apply the layers for highlighting the experiment period when the start date is given.
  if (experimentStartDate && experimentEndDate) {
    addExperimentPeriodLayer(
      vegaLiteSpec as NormalizedLayerSpec,
      experimentStartDate,
      experimentEndDate,
      'orange',
    );
  }

  return vegaLiteSpec;
}

function addExperimentPeriodLayer(
  spec: NormalizedLayerSpec,
  startDate: string,
  endDate: string,
  color: string,
) {
  // Add the highlight showing the period between start and end dates.
  const experimentPeriod: NormalizedUnitSpec = {
    mark: {
      type: 'rect',
      color,
      fillOpacity: 0.1,
    },
    data: {
      values: [
        {
          start: startDate,
          end: endDate,
        },
      ],
    },
    encoding: {
      x: {
        field: 'start',
        timeUnit: {unit: 'yearmonthdate', utc: false},
      },
      x2: {
        field: 'end',
        timeUnit: {unit: 'yearmonthdate', utc: false},
      },
    },
  };
  spec.layer.push(experimentPeriod);
}
