/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * The enum of all task types provided by the EE4E backend
 */
export enum TaskTypeEnum {
  // Group splitting task
  SPLIT = 'SPLIT',
  // Difference-in-differences analysis task
  ANALYSIS_DID = 'ANALYSIS_DID',
  // Causal impact analysis task
  ANALYSIS_CAUSAL_IMPACT = 'ANALYSIS_CAUSAL_IMPACT',
  // Budget simulation task for tabular data output
  BUDGET_TABLE = 'BUDGET_TABLE',
  // Budget simulation task for graph data output
  BUDGET_GRAPHS = 'BUDGET_GRAPHS',
}
