/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {SelectionModel} from '@angular/cdk/collections';
import {
  Component,
  DestroyRef,
  ElementRef,
  ViewChild,
  inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {finalize, tap} from 'rxjs';

import {DeleteDialogComponent} from '../common/delete-dialog/delete-dialog.component';
import * as routes from '../common/routes';
import {Experiment} from '../model/experiment';
import {StartingPointEnum} from '../model/starting-point-enum';
import {TaskStatusEnum} from '../model/task-status-enum';
import {TaskTypeEnum} from '../model/task-type-enum';
import {BusinessLogicService} from '../service/business-logic.service';
import {ExperimentIoService} from '../service/experiment-io.service';
import {HelpMessagesService} from '../service/help-messages.service';

/** Component for listing the existing experiments on the landing page. */
@Component({
  standalone: false,
  selector: 'app-experiments',
  templateUrl: './experiments.component.html',
  styleUrls: ['./experiments.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class ExperimentsComponent {
  displayedColumns: string[] = [
    'select',
    'name',
    'description',
    'created',
    'lastModified',
    'startingPoint',
    'actions',
    'icons',
  ];
  dataSource!: MatTableDataSource<Experiment>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('fileInput') fileInput!: ElementRef;
  experimentsFileName = 'experiments.json';
  selection = new SelectionModel<Experiment>(true, []);
  clickedExperiment: Experiment | null = null;

  readonly snackBarDurationMills = 2000;
  readonly snackBarActionLabel = 'OK';

  private readonly destroyedRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private readonly experimentIo: ExperimentIoService,
    public dialog: MatDialog,
    readonly snackBar: MatSnackBar,
    private readonly businessLogicService: BusinessLogicService,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {
    this.loadExperiments(true);
  }

  navigateToNewExperiment() {
    this.router.navigate([routes.ADDITIONAL_INFORMATION_URL], {
      skipLocationChange: true,
    });
  }

  generateActions(experiment: Experiment): string[] {
    if (experiment.startingPoint === StartingPointEnum.SPLITTING) {
      return [
        StartingPointEnum.SPLITTING,
        StartingPointEnum.BUDGET_SIMULATION,
        StartingPointEnum.IMPACT_MEASUREMENT,
      ];
    } else if (
      experiment.startingPoint === StartingPointEnum.BUDGET_SIMULATION
    ) {
      return [
        StartingPointEnum.BUDGET_SIMULATION,
        StartingPointEnum.IMPACT_MEASUREMENT,
      ];
    } else {
      return [StartingPointEnum.IMPACT_MEASUREMENT];
    }
  }

  viewSubmittedTask(experiment: Experiment, action: string): void {
    if (!this.canViewSubmittedTask(experiment, action)) {
      return;
    }
    this.router.navigate([`view-experiment/${experiment.id}/${action}`], {
      skipLocationChange: true,
    });
  }

  canViewSubmittedTask(experiment: Experiment, action: string): boolean {
    // As soon as an experiment is created, the initial task is attached to it
    // and users can view the task even if it's still pending.
    if (action === experiment.startingPoint) {
      return true;
    }
    // A budget simulation task is accessible if it exists in the experiment as
    // a record and if there exists a completed splitting task.
    if (
      action === StartingPointEnum.BUDGET_SIMULATION &&
      experiment.tasks[TaskTypeEnum.SPLIT]?.taskStatus ===
        TaskStatusEnum.COMPLETED &&
      experiment.tasks[TaskTypeEnum.BUDGET_TABLE]
    ) {
      // TODO() similar comment to the TODO below to allow
      // transitions to continuation screens.
      return true;
    }
    // An Impact measurement task is accessible if it exists in the experiment
    // as a record and if there exists a completed splitting task since budget
    // simulation is optional in this situation.
    if (
      action === StartingPointEnum.IMPACT_MEASUREMENT &&
      experiment.tasks[TaskTypeEnum.SPLIT]?.taskStatus ===
        TaskStatusEnum.COMPLETED &&
      (experiment.tasks[TaskTypeEnum.ANALYSIS_DID] ||
        experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT])
    ) {
      // TODO() Remove `(experiment.tasks[TaskTypeEnum.ANALYSIS_DID]
      // || experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT])` when the
      // continuation screens are available to allow users to make the
      // transition to these input screens for going to the next part of the
      // experiment.
      return true;
    }
    // An Impact measurement task is accessible if it exists in the experiment
    // as a record and if there exists a completed budget table simulation task.
    // This is only the case for an experiment starting from Budget Simulation.
    if (
      action === StartingPointEnum.IMPACT_MEASUREMENT &&
      experiment.startingPoint === StartingPointEnum.BUDGET_SIMULATION &&
      experiment.tasks[TaskTypeEnum.BUDGET_TABLE]?.taskStatus ===
        TaskStatusEnum.COMPLETED &&
      (experiment.tasks[TaskTypeEnum.ANALYSIS_DID] ||
        experiment.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT])
    ) {
      // TODO() similar to above to allow users to transition to the
      // continuation input forms.
      return true;
    }
    // For other cases the task can't be accessed.
    return false;
  }

  importExperiments(files: FileList): void {
    this.selection.clear();
    const experimentIds = this.dataSource.data.map(
      (experiment) => experiment.id,
    );

    if (files?.length) {
      // Assume single file is selected as input form doesn't allow multiple files.
      const file = files[0];
      // Count imported experiments.
      let experimentCount = 0;
      this.experimentIo
        .importExperiments(file, experimentIds)
        .pipe(
          tap(() => {
            experimentCount++;
          }),
          takeUntilDestroyed(this.destroyedRef),
          finalize(() => {
            // Refresh the table by reloading experiments.
            this.loadExperiments();
            // Reset the selected file in the input form.
            this.fileInput.nativeElement.value = '';
          }),
        )
        .subscribe({
          error: (error) => {
            this.openSnackBar(
              `Failed to import the experiment(s): ${error.message}`,
            );
          },
          complete: () => {
            this.openSnackBar(
              `Successfully imported ${experimentCount} experiment(s).`,
            );
          },
        });
    }
  }

  exportExperiments(): void {
    if (this.selection.isEmpty() || this.isAllSelected()) {
      this.experimentIo.exportExperiments(this.experimentsFileName);
    } else {
      const idList = this.selection.selected.map((experiment) => experiment.id);
      this.experimentIo.exportSelectedExperiments(
        idList,
        this.experimentsFileName,
      );
    }
  }

  exportOneExperiment(experimentId: string): void {
    this.experimentIo.exportSelectedExperiments(
      [experimentId],
      this.experimentsFileName,
    );
  }

  deleteSelectedExperiments(): void {
    const idList = this.selection.selected.map((experiment) => experiment.id);
    // Open the confirmation dialog.
    const dialogRef = this.dialog.open(DeleteDialogComponent, {data: {idList}});
    dialogRef.afterClosed().subscribe((confirmed) => {
      // Exist when the user cancels the deletion.
      if (!confirmed) return;
      // Clear the selection before deleting the experiments.
      this.selection.clear();
      this.businessLogicService
        .deleteSelectedExperiments(idList)
        .pipe(
          takeUntilDestroyed(this.destroyedRef),
          // Refresh the table by reloading experiments.
          finalize(() => this.loadExperiments()),
        )
        .subscribe((results) => {
          if (results.length > 0) {
            this.openSnackBar(
              `Successfully deleted ${results.length} experiment(s).`,
            );
          }
          const failedCounts = idList.length - results.length;
          if (failedCounts > 0) {
            this.openSnackBar(
              `Failed in deleting ${failedCounts} experiment(s).`,
            );
          }
        });
    });
  }

  deleteOneExperiment(experiment: Experiment): void {
    // Highlight the row to help users identify the experiment to delete.
    this.clickedExperiment = experiment;
    // Open the confirmation dialog.
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {idList: [experiment.id]},
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      // Remove highlight.
      this.clickedExperiment = null;
      // Exist when the user cancels the deletion.
      if (!confirmed) return;
      // Clear the selection before deleting the experiments.
      this.selection.clear();
      this.businessLogicService
        .deleteSelectedExperiments([experiment.id])
        .pipe(
          takeUntilDestroyed(this.destroyedRef),
          // Refresh the table by reloading experiments.
          finalize(() => this.loadExperiments()),
        )
        .subscribe({
          next: (result) => {
            if (result.length > 0) {
              this.openSnackBar(`Successfully deleted ${experiment.id}.`);
            } else {
              this.openSnackBar(`Failed in deleting ${experiment.id}.`);
            }
          },
          error: (error) => {
            this.openSnackBar(error.message);
          },
        });
    });
  }

  loadExperiments(needInitialSorting = false): void {
    this.businessLogicService.listExperiments().subscribe((experiments) => {
      this.dataSource = new MatTableDataSource(experiments);
      this.dataSource.sort = this.sort;
      if (needInitialSorting) {
        this.sort.sort({id: 'created', start: 'desc', disableClear: false});
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDurationMills,
    });
  }
}
