/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

import * as constants from '../../../common/constants';
import {HelpMessagesService} from '../../../service/help-messages.service';

/**
 * Component used when creating new experiments allowing users to enter
 * title and description.
 */
@Component({
  standalone: false,
  selector: 'app-new-experiment-description',
  templateUrl: './new-experiment-description.component.html',
  styleUrls: ['./new-experiment-description.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class NewExperimentDescriptionComponent {
  readonly experimentNameMaxLength = constants.EXPERIMENT_NAME_LENGTH_LIMIT;
  readonly experimentDescriptionMaxLength =
    constants.EXPERIMENT_DESCRIPTION_LENGTH_LIMIT;
  experimentDetailsForm = this.formBuilder.group({
    experimentName: [
      '',
      [Validators.required, Validators.maxLength(this.experimentNameMaxLength)],
    ],
    experimentDescription: [
      '',
      [
        Validators.required,
        Validators.maxLength(this.experimentDescriptionMaxLength),
      ],
    ],
  });

  @Output() readonly nameModified = new EventEmitter<string>();
  @Output() readonly descriptionModified = new EventEmitter<string>();

  @Input() defaultName = '';
  @Input() defaultDescription = '';
  @Input() isDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {}
  ngOnInit() {
    this.experimentName = this.defaultName;
    this.experimentDescription = this.defaultDescription;
  }

  get experimentName(): string {
    return this.experimentDetailsForm.controls.experimentName.value!;
  }

  set experimentName(value: string) {
    this.experimentDetailsForm.controls['experimentName'].setValue(value);
  }

  isExperimentNameTooLong(): boolean {
    return this.experimentName.length > this.experimentNameMaxLength;
  }

  get experimentDescription(): string {
    return this.experimentDetailsForm.controls.experimentDescription.value!;
  }

  set experimentDescription(value: string) {
    this.experimentDetailsForm.controls['experimentDescription'].setValue(
      value,
    );
  }

  isExperimentDescriptionTooLong(): boolean {
    return (
      this.experimentDescription.length > this.experimentDescriptionMaxLength
    );
  }

  clearExperimentName(): void {
    this.experimentName = '';
    this.nameModified.emit(this.experimentName);
  }

  clearExperimentDescription(): void {
    this.experimentDescription = '';
    this.descriptionModified.emit(this.experimentDescription);
  }

  sendExperimentName(): void {
    this.nameModified.emit(this.experimentName);
  }

  sendExperimentDescription(): void {
    this.descriptionModified.emit(this.experimentDescription);
  }
}
