/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Injectable} from '@angular/core';

enum ExperimentsPageHelpMessages {
  BUTTON_NEW_EXPERIMENT = 'Create a new experiment.',
  BUTTON_IMPORT_EXPERIMENTS = 'Import experiments from your computer.',
  BUTTON_EXPORT_EXPERIMENTS = 'Export selected experiments.',
  BUTTON_DELETE_EXPERIMENTS = 'Delete selected experiments.',
  TABLE_HEADER_NAME = 'Order by experiment name.',
  TABLE_HEADER_DESCRIPTION = 'Order by experiment description.',
  TABLE_HEADER_CREATION_DATE = 'Order by creation date.',
  TABLE_HEADER_LAST_MODIFICATION_DATE = 'Order by last modification date.',
  TABLE_HEADER_STARTING_POINT = 'Order by starting point.',
  TABLE_CELL_GO_TO_TASK = 'Go to ',
  TABLE_ACTION_EXPORT_EXPERIMENT = 'Export experiment.',
  TABLE_ACTION_DELETE_EXPERIMENT = 'Delete experiment.',
}

enum ExperimentCommonInputFieldsHelpMessages {
  INPUT_NAME = 'The name of the experiment.',
  INPUT_DESCRIPTION = 'The description of the experiment.',
  SELECT_TEST_GROUPS = 'Select test groups for analysis.',
  INPUT_MEMBER_FILTER = 'Filter allocations by member.',
  SELECT_GROUP_FILTER = 'Filter allocations by group.',
  SELECT_PLAN = 'Plan selection to choose which splitting configuration ' +
    'to use for the rest of the experiment.',
  TABLE_MEMBER_GROUP_MAPPINGS = 'Table displaying the member-group allocations' +
    ' by taking into account the filters applied.',
  BUTTON_GROUP_MEMBER_DOWNLOAD = 'Download groups configuration as CSV file.',
  BUTTON_RERUN = 'Rerun the current task (as current results will be lost,' +
    ' export the experiment before rerunning if they are needed).',
}

enum NewSplittingPageHelpMessages {
  FILE_PICKER_KPI = 'Member-based KPI file (.csv).',
  FILE_PICKER_CONFIG = 'Optional configuration file (.csv).',
  INPUT_NUMBER_OF_PLANS = 'Each plan corresponds to a possible experiment' +
    ' scenario and all plans are mutually exclusive.\n You can only run the' +
    ' rest of the experiment with one scenario/plan.',
  INPUT_NUMBER_OF_GROUPS = 'The number of groups needed (including the' +
    ' Control Group) in an experiment scenario.',
  INPUT_NUMBER_OF_MEMBERS_PER_GROUP = 'The number of members in a group,' +
    ' by default this is automatically taken into account by EE4E.\n' +
    ' The number of members per group can be manually input by disabling' +
    ' the auto-calculation.',
  INPUT_CORRELATION_COEFFICIENT = 'The correlation coefficient that needs to' +
    ' be fulfilled between the control and test groups for a plan to be' +
    ' considered as valid.',
  INPUT_DATA_VOLUME_COEFFICIENT = 'The data volume coefficient (ratio) that' +
    ' needs to be reached by a plan to be considered as valid.',
  INPUT_ADDITIONAL_CONFIGURATION_VOLUME_RATIO = 'If a configuration file' +
    ' is uploaded, data volume ratios can be configured in the section.\n' +
    'The number of data volume ratios depends on the number of' +
    ' additional columns (from 3rd to the end) in the configuration file.',
  BUTTON_SUBMIT = 'Submit splitting task to server.',
}

enum NewBudgetSimulationPageHelpMessages {
  FILE_PICKER_KPI = 'Group-based KPI file (.csv).',
  INPUT_ICPA = 'The estimated ICPA (Incremental Cost Per Action) for' +
    ' the experiment.',
  INPUT_DURATIONS = 'The durations of the simulation/experiment in days' +
    ' (max 28 days).\n Duplicate values are ignored.',
  INPUT_UPLIFTS = 'The desired uplifts for the simulation/experiment.\n' +
    ' Duplicate values are ignored.',
  BUTTON_SUBMIT = 'Submit budget simulation task to server.',
}

enum NewImpactMeasurementPageHelpMessages {
  FILE_PICKER_KPI = 'Group-based KPI file containing both pre-experiment' +
    ' and KPI sampled during the experiment (.csv).',
  INPUT_EXP_START_DATE = 'The start date of the experiment.' +
    ' Please double check that the correct date is configured as it is' +
    ' impossible for the platform to validate this date' +
    ' (except if it is outside of the range of the KPI file).',
  INPUT_EXP_END_DATE = 'The end date of the experiment.' +
    ' Please double check that the correct date is configured as it is' +
    ' impossible for the platform to validate this date' +
    ' (except if it is outside of the range of the KPI file).',
  SELECT_ANALYSIS_METHOD = 'Select the analysis method to use.',
  INPUT_DID_ESTIMATED_COST = 'The estimated cost for the selected test group.',
  BUTTON_SUBMIT = 'Submit impact measurement task to server.',
}

enum ContinueExperimentToBudgetSimulationPageHelpMessages {
  INPUT_ICPA = NewBudgetSimulationPageHelpMessages.INPUT_ICPA,
  INPUT_DURATIONS = NewBudgetSimulationPageHelpMessages.INPUT_DURATIONS,
  INPUT_UPLIFTS = NewBudgetSimulationPageHelpMessages.INPUT_UPLIFTS,
  BUTTON_SUBMIT = NewBudgetSimulationPageHelpMessages.BUTTON_SUBMIT,
}

enum ContinueExperimentToImpactMeasurementPageHelpMessages {
  FILE_PICKER_MEMBER_KPI = 'Member-based KPI file containing both pre-experiment' +
    ' and KPI sampled during the experiment (.csv).',
  SELECT_MEMBERS_EXCLUDE = 'Select the members to exclude from the analysis' +
    ' for the control and test groups.',
  TABLE_EXTERNAL_GROUPS_KPI = 'Table displaying the KPI per group for each' +
    ' sampling date for the control group and each test group.',
  FILE_PICKER_GROUP_KPI = NewImpactMeasurementPageHelpMessages.FILE_PICKER_KPI,
  INPUT_EXP_START_DATE = NewImpactMeasurementPageHelpMessages.INPUT_EXP_START_DATE,
  INPUT_EXP_END_DATE = NewImpactMeasurementPageHelpMessages.INPUT_EXP_END_DATE,
  SELECT_ANALYSIS_METHOD = NewImpactMeasurementPageHelpMessages.SELECT_ANALYSIS_METHOD,
  INPUT_DID_ESTIMATED_COST = NewImpactMeasurementPageHelpMessages.INPUT_DID_ESTIMATED_COST,
  BUTTON_SUBMIT = NewImpactMeasurementPageHelpMessages.BUTTON_SUBMIT,
}

enum SplittingResultsPageHelpMessages {
  TABLE_SUMMARY = 'The correlation coefficient' +
    ' between the control and test groups meeting your requirements.\n\n' +
    'The total data volume' +
    ' of the control and test groups (Sum of the KPI of all members in' +
    ' the group over the pre-experiment period).\n\n' +
    'The data volume ratio between the control and test groups.\n\n' +
    'The total volume of the additional configuration criteria defined in' +
    ' the configuration file.\n\n' +
    'The data volume ratio between the control and test groups for the' +
    ' additional configuration criteria defined in the configuration file.\n\n',
  TABLE_KPI_PER_GROUP = 'Table displaying the KPI per group for each sampling' +
    ' date for the control group and each test group of the selected plan.',
  GRAPH_KPI_PER_GROUP = 'The representation of the KPI per Group table.',
  TABLE_NORMALIZED_KPI_PER_GROUP = 'Table displaying the normalized KPI per' +
    ' group for each sampling date for the control group and each test' +
    ' group of the selected plan.\n\n' +
    'The normalized KPI per group is calculated' +
    ' based on the KPI per Group: for each group, find the maximum value for' +
    ' the encountered KPI and divide all aggregated KPI by this value.',
  GRAPH_NORMALIZED_KPI_PER_GROUP = 'The representation of the' +
    ' normalized KPI per Group table.',
  BUTTON_GO_TO_BUDGET_SIMULATION = '(Optional) Navigate to budget' +
    ' simulation page to: \n\n' +
    '- 1) estimate the budget needed during the experiment' +
    ' for a possible scenario (= combination of a testing periond duration' +
    ' and uplift).\n\n - 2) Validate the quality of the grouping and confirm' +
    ' if Causal Impact can be used for the post-experiment impact measurement.',
  BUTTON_GO_TO_IMPACT_MEASUREMENT = 'Navigate to impact measurement' +
    ' after having performed the experiment with one of the plans/scenarios.',
}

enum BudgetSimulationResultsPageHelpMessages {
  TABLE_HEADER_DAYS = 'The duration of the simulated experiment' +
    ' (used to run Causal Impact on the last N days of the Test KPI).',
  TABLE_HEADER_BUDGET = 'The estimated budget to carry out the experiment.\n' +
    ' The budget is defined as `Budget` = `Absolute Effect` * `iCPA`' +
    ' where `Absolute Effect` is the simulated (by Causal Impact)' +
    ' total absolute effect of the intervention on the Test Group.',
  TABLE_HEADER_IMPACT = 'The input uplift in percent.',
  TABLE_HEADER_MAPE = 'Mean Absolute Percentage Error (MAPE) between' +
    ' pre-period (observed and sampled) Test Group KPI and pre-period' +
    ' inferred KPI via Causal Impact where pre-period is defined as all' +
    ' sample dates present in the KPI File (before the experiment) without' +
    ' the last N days = Input duration used to run Causal Impact.',
  TABLE_HEADER_ABS_EFFECT = 'The Absolute Effect returned by the Causal' +
    ' Impact Module for the artificially created experiment period of' +
    ' duration N days.',
  TABLE_HEADER_REL_EFFECT = 'The Relative Effect returned by Causal Impact' +
    ' for the artificially created experiment period of duration N days.',
  TABLE_HEADER_EFFECT_DIFF = 'The difference between the Relative Effect' +
    ' returned by Causal Impact Module and the artificially applied uplift' +
    ' on the Test Group KPI during the duration N days.',
  TABLE_HEADER_DIFF_IMPACT = 'Is equal to EffectDiff/Impact or' +
    ' EffectDiff/Uplift which is a measure of the relative error between' +
    ' the relative effect inferred by the Causal Impact module and the actual' +
    ' uplift that was artificially applied during the simulation of duration' +
    ' N days.',
  TABLE_HEADER_MEAN = 'The mean returned by Causal Impact.',
  TABLE_HEADER_P_VALUE = 'Probability of observing sample data as extreme' +
    ' or more if the null hypothesis were true.\n The smaller the p-value,' +
    ' the more incompatible the data are with the null hypothesis.\n' +
    ' By convention, a p-value ≤ 0.05 is used for determining whether an ' +
    ' observed effect is "statistically significant" or not.',
  BUTTON_DISPLAY_SIMULATION_GRAPHS = 'Display Causal Impact graphs for the ' +
    ' selected simulation scenario.',
  BUTTON_GO_TO_IMPACT_MEASUREMENT = SplittingResultsPageHelpMessages.BUTTON_GO_TO_IMPACT_MEASUREMENT,
  TITLE_BUDGET_SIMULATION_DETAILS = 'On top of finding an A/B testing' +
    ' scenario that meets your budget/uplift/duration requirements, this step' +
    ' also validates that the model used by Causal Impact which relies on' +
    ' Bayesian Structural Time-Series does properly fit your pre-experiment' +
    ' KPI and can measure with high precision the artificial uplift applied' +
    ' during the simulation duration.\n\n This strengthens the confidence that' +
    ' the impact of the intervention on the treatment group during the actual' +
    ' A/B test will be correctly measured by the Causal Impact module when' +
    ' evaluating the results of the experiment after carrying it out' +
    ' (e.g. post-experiment impact measurement).',
  GRAPH_CI_WITH_UPLIFT = 'The Causal Impact graphs with the simulated' +
    ' uplift applied during the simulation duration.',
  GRAPH_CI_WITHOUT_UPLIFT = 'The Causal Impact graphs without' +
    ' any uplift applied during the simulation duration (for comparison).',
}

enum CausalImpactResultsPageHelpMessages {
  CARD_MAPE = 'Mean Absolute Percentage Error (MAPE) between pre-period' +
    ' (observed and sampled) Test Group KPI and pre-period inferred KPI' +
    ' via Causal Impact.',
  CARD_P_VALUE = BudgetSimulationResultsPageHelpMessages.TABLE_HEADER_P_VALUE,
  CARD_ABSOLUTE_EFFECT = 'Absolute Effect returned by Causal Impact Module.',
  CARD_RELATIVE_EFFECT = 'Relative Effect returned by Causal Impact Module.',
  CARD_CAUSAL_IMPACT_GRAPHS = 'The dotted line represents the start of the' +
    ' experiment.\n\n On the "Original" graph, the Observed curve displays' +
    ' the KPI of the Test Group and the Mean curve represents' +
    ' the counterfactual for the Test Group (e.g. if there were' +
    ' no intervention, the values that would have observed).\n' +
    ' This counterfactual is constructed by the Causal Impact module relying' +
    ' on Bayesian Structural Time-Series.\n\n The "Pointwise" graph' +
    ' shows the difference between the observed Test Group KPI and the' +
    ' counterfactual response (assuming no intervention happened).\n\n' +
    ' The "Cumulative" graph shows the sum of the differences between the' +
    ' observed KPI and the counterfactual. By accumulating these differences,' +
    ' it is possible to compute the total Impact of the intervention.',
}

enum DidResultsPageHelpMessages {
  TABLE_HEADER_PRE_PERIOD_AVG_ABSOLUTE = 'The daily average of' +
    ' the KPI measured before the experiment.\n This is the absolute value' +
    ' equal to the sum of the KPI over the pre-experiment period divided' +
    ' by the number of days of the pre-experiment period.',
  TABLE_HEADER_EXP_PERIOD_AVG_ABSOLUTE = 'The daily average of' +
    ' the KPI observed during the experiment (absolute value).\n This value is' +
    ' equal to the sum of the KPI over the experiment period divided' +
    ' by the number of days of the experiment period.',
  TABLE_HEADER_PRE_PERIOD_SUM = 'Sum of the KPI before the experiment.',
  TABLE_HEADER_EXP_PERIOD_SUM = 'Sum of the KPI during the experiment.',
  TABLE_HEADER_COUNTERFACTUAL = 'The counterfactual for each test group.\n\n' +
    ' This is computed by the DID module by multiplying the sum of the KPI' +
    ' of the control group measured during experiment period by the ratio' +
    ' of the sum of the KPI of the test group measured during' +
    ' the pre-experiment period over the sum of the KPI of the control group' +
    ' measured during the pre-experiment period.\n\n' +
    ' Exp Period Sum<Ctrl> * (Pre Period Sum<Test> / Pre Period Sum<Ctrl>',
  TABLE_HEADER_REL_LIFT = 'Incremental responses divided by' +
    ' counterfactual responses.',
  // TODO(): shall we rename this column to follow the internal
  // official conventions.
  TABLE_HEADER_LIFTED_CONV = 'Incremental responses: treatment responses' +
    ' minus counterfactual responses.',
  // TODO(): rephrase this hint of this column if we rename
  // previous columns.
  TABLE_HEADER_COST_PER_LIFTED_CONV = 'This represents the cost for each' +
    ' test group to run the experiment divided by the incremental responses.',
  GRAPH_KPI_TREND = 'This graph shows the KPI for the control and test' +
    ' groups before and during the experiment period.\n' +
    ' The dotted line represents the start of the experiment' +
    ' which zone is displayed in orange.',
  GRAPH_AVG_KPI_DIFF = 'This graph represents:\n\n -1) The difference of the' +
    ' daily average in percent of the control group between pre-experiment' +
    ' and experiment periods.\n -2) For each test group, the difference of' +
    ' the daily average in percent between pre-experiment and experiment' +
    ' periods.\n -3) For each test group, the Difference-in-Difference ' +
    ' between the test group and the control group during the experiment.',
}

/**
 * Service that provides help messages used in the tooltips
 * of the different components of EE4E.
 */
@Injectable({
  providedIn: 'root',
})
export class HelpMessagesService {
  readonly experimentsListPage: typeof ExperimentsPageHelpMessages =
    ExperimentsPageHelpMessages;
  readonly experimentCommonInputFields: typeof ExperimentCommonInputFieldsHelpMessages =
    ExperimentCommonInputFieldsHelpMessages;
  readonly newSplittingPage: typeof NewSplittingPageHelpMessages =
    NewSplittingPageHelpMessages;
  readonly newBudgetSimulationPage: typeof NewBudgetSimulationPageHelpMessages =
    NewBudgetSimulationPageHelpMessages;
  readonly newImpactMeasurementPage: typeof NewImpactMeasurementPageHelpMessages =
    NewImpactMeasurementPageHelpMessages;
  readonly continueToBudgetSimulationPage: typeof ContinueExperimentToBudgetSimulationPageHelpMessages =
    ContinueExperimentToBudgetSimulationPageHelpMessages;
  readonly continueToImpactMeasurementPage: typeof ContinueExperimentToImpactMeasurementPageHelpMessages =
    ContinueExperimentToImpactMeasurementPageHelpMessages;
  readonly splittingResultsPage: typeof SplittingResultsPageHelpMessages =
    SplittingResultsPageHelpMessages;
  readonly budgetSimulationResultsPage: typeof BudgetSimulationResultsPageHelpMessages =
    BudgetSimulationResultsPageHelpMessages;
  readonly causalImpactResultsPage: typeof CausalImpactResultsPageHelpMessages =
    CausalImpactResultsPageHelpMessages;
  readonly didResultsPage: typeof DidResultsPageHelpMessages =
    DidResultsPageHelpMessages;
  static readonly HELP_MESSAGES_SHOW_DELAY = 750;
}
