/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {DestroyRef, Injectable, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import Ajv, {ValidateFunction} from 'ajv';
import {Observable, concatMap, from, map, of as observableOf} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {Experiment} from '../model/experiment';
import {experimentsSchema} from '../model/experimentsSchema';
import {StartingPointEnum} from '../model/starting-point-enum';
import {Task} from '../model/task';
import {TaskTypeEnum} from '../model/task-type-enum';
import {BusinessLogicService} from './business-logic.service';
import {FileReaderService} from './file-reader.service';

/**
 * Service to import, export and delete experiments.
 */
@Injectable({
  providedIn: 'root',
})
export class ExperimentIoService {
  private readonly destroyedRef = inject(DestroyRef);
  readonly snackBarDurationMills = 2000;
  readonly snackBarActionLabel = 'OK';
  readonly jsonValidator: ValidateFunction;

  constructor(
    private readonly businessLogicService: BusinessLogicService,
    private readonly fileReaderService: FileReaderService,
    readonly snackBar: MatSnackBar,
  ) {
    this.jsonValidator = new Ajv().compile(experimentsSchema);
  }

  private saveToFile(experiments: Experiment[], fileName: string) {
    const num_experiments = experiments.length;
    if (num_experiments === 0) {
      this.openSnackBar('There is no experiment to export.');
      return;
    }
    // Encode experiments object into Blob URL.
    const blob = new Blob([JSON.stringify(experiments, null, 2)], {
      type: 'application/json',
    });
    const encodedUrl = URL.createObjectURL(blob);

    // Download file.
    const link = document.createElement('a');
    link.setAttribute('href', encodedUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (num_experiments === 1) {
      this.openSnackBar('Finished exporting 1 experiment.');
    } else {
      this.openSnackBar(`Finished exporting ${num_experiments} experiments.`);
    }
  }

  /**
   * Exports the exportable experiments into a json file.
   *
   * @param fileName The file name for exporting.
   */
  exportExperiments(fileName: string): void {
    this.businessLogicService
      .getExportableExperiments()
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiments) => {
        this.saveToFile(experiments, fileName);
      });
  }

  /**
   * Exports the exportable experiments into a json file.
   *
   * @param idList The list of experiment IDs to be exported.
   * @param fileName The file name for exporting.
   */
  exportSelectedExperiments(idList: string[], fileName: string): void {
    this.businessLogicService
      .getSelectedExportableExperiments(idList)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiments) => {
        this.saveToFile(experiments, fileName);
      });
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDurationMills,
    });
  }

  /**
   * Generates a random experiment ID with UUID (ES5 module syntax)
   * to resolve the ID conflict.
   *
   * @return An experiment ID randomly generated.
   */
  getNewExperimentId(): string {
    return uuidv4();
  }

  /**
   * Updates Experiment ID, name, and Task ID considering collision
   * with existing Experiments.
   *
   * @param experiment The experiment to be imported.
   * @param idListInDb The list of experiment IDs stored in IDB.
   * @return An observable emitting the updated experiment.
   */
  private updateExperimentIdAndName(
    experiment: Experiment,
    idListInDb: string[],
  ): Observable<Experiment> {
    // Overwrite the conflicted Experiment ID.
    if (idListInDb.includes(experiment.id)) {
      experiment.id = this.getNewExperimentId();
    }

    // Add prefix indicating import.
    experiment.name = `[Imported] ${experiment.name}`;
    // Overwrite the Task ID of the starting point.
    const startingPoint = experiment.startingPoint;

    let startTask: Task | undefined;
    if (
      startingPoint === StartingPointEnum.SPLITTING &&
      experiment.tasks[TaskTypeEnum.SPLIT]
    ) {
      startTask = experiment.tasks[TaskTypeEnum.SPLIT];
    } else if (
      startingPoint === StartingPointEnum.BUDGET_SIMULATION &&
      experiment.tasks[TaskTypeEnum.BUDGET_TABLE]
    ) {
      startTask = experiment.tasks[TaskTypeEnum.BUDGET_TABLE];
    } else if (startingPoint === StartingPointEnum.IMPACT_MEASUREMENT) {
      // Does not enforce the Task ID at the starting point to be the same with the Experiment ID.
    } else {
      throw new Error(
        'The task does not contain the task marked as the starting point.',
      );
    }
    // Make the Task ID for the starting point the same with the Experiment ID.
    if (startTask) {
      startTask.taskId = experiment.id;
    }

    return observableOf(experiment);
  }

  /**
   * Imports the experiments from the JSON file.
   * When an Experiment ID in the JSON file already exists in IDB,
   * a newly generated ID is assigned.
   * Assumes the IDs in the JSON file are different from each other.
   *
   * @param file The JSON file.
   * @param idListInDb The list of experiment IDs stored in IDB.
   * @return An observable emitting the result of saving experiment to IDB.
   */
  importExperiments(file: File, idListInDb: string[]): Observable<unknown> {
    return this.fileReaderService.readJson(file).pipe(
      map((json) => {
        if (this.jsonValidator(json)) {
          return json as unknown as Experiment[];
        } else {
          console.error('Validation errors:', this.jsonValidator.errors);
          throw new Error('Input file has an invalid format.');
        }
      }),
      concatMap((experimentList: Experiment[]) => from(experimentList)),
      concatMap((experiment) =>
        this.updateExperimentIdAndName(experiment, idListInDb),
      ),
      concatMap((experiment) =>
        this.businessLogicService.saveExperiment(experiment),
      ),
    );
  }
}
