/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

import {createPlaceholderBudgetGraphTask} from '../../../common/utils';
import {BudgetSimulationGraphTaskResult} from '../../../model/budget-simulation-table-graph-result';
import {BudgetSimulationTableTaskResult} from '../../../model/budget-simulation-table-task-result';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {Task} from '../../../model/task';
import {TaskStatusEnum} from '../../../model/task-status-enum';
import {TaskTypeEnum} from '../../../model/task-type-enum';
import {BusinessLogicService} from '../../../service/business-logic.service';
import {HelpMessagesService} from '../../../service/help-messages.service';
import {TaskWorkerClient} from '../../../service/task-status-worker-client';
import {
  FromWorkerEvent,
  WORKER_EVENT_ERROR,
  WORKER_EVENT_UPDATED,
} from '../../../task-status.worker-events';

interface SimulationEntry {
  days: number;
  impact: number;
  mape: number;
  absEffect: number;
  relEffect: number;
  effectDiff: number;
  diffImpact: number;
  mean: number;
  budget: number;
  pValue: number;
}

/** Component to display the results of the Budget Simulation. */
@Component({
  standalone: false,
  selector: 'app-budget-simulation-results',
  templateUrl: './budget-simulation-results.component.html',
  styleUrls: ['./budget-simulation-results.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class BudgetSimulationResultsComponent implements OnInit, OnDestroy {
  private readonly destroyedRef = inject(DestroyRef);
  @Input({required: true})
  budgetSimulationResults!: BudgetSimulationTableTaskResult;
  @Input({required: true}) icpa!: number;
  @Input({required: true}) experimentId!: string;
  @Input({required: true}) task!: Task;
  selectedTestGroup!: string;
  budgetSimulationGraphTask: Task | null = null;

  readonly spinnerDiameter = 100;
  readonly snackBarDurationMillis = 2000;
  readonly snackBarActionLabel = 'OK';
  readonly snackBarSubmittingBudgetGraphTaskMessage =
    'Submitting budget simulation graph task to server.';

  readonly simulationEntryLabels = {
    days: 'Days',
    budget: 'Budget',
    impact: 'Impact',
    mape: 'MAPE',
    absEffect: 'AbsEffect',
    relEffect: 'RelEffect',
    effectDiff: 'EffectDiff',
    diffImpact: 'Diff/Impact[%]',
    mean: 'Mean',
    pValue: 'p_value',
  };

  displayedColumns = Object.keys(this.simulationEntryLabels);
  dataSources: SimulationEntry[][] = [];

  simulationEntryClicked = false;
  selectedSimulationEntry!: SimulationEntry;

  constructor(
    protected readonly snackBar: MatSnackBar,
    private readonly businessLogicService: BusinessLogicService,
    private readonly taskWorkerClient: TaskWorkerClient,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {}

  ngOnInit() {
    Object.values(this.budgetSimulationResults.budget_tables).forEach(
      (budgetTableForGroup: Record<string, Record<string, number>>) => {
        const data: SimulationEntry[] = [];
        Object.values(budgetTableForGroup).forEach(
          (simulationEntry: Record<string, number>) => {
            data.push({
              days: simulationEntry[this.simulationEntryLabels.days],
              budget: simulationEntry[this.simulationEntryLabels.budget],
              impact: simulationEntry[this.simulationEntryLabels.impact],
              mape: simulationEntry[this.simulationEntryLabels.mape],
              absEffect: simulationEntry[this.simulationEntryLabels.absEffect],
              relEffect: simulationEntry[this.simulationEntryLabels.relEffect],
              effectDiff:
                simulationEntry[this.simulationEntryLabels.effectDiff],
              diffImpact:
                simulationEntry[this.simulationEntryLabels.diffImpact],
              mean: simulationEntry[this.simulationEntryLabels.mean],
              pValue: simulationEntry[this.simulationEntryLabels.pValue],
            });
          },
        );
        this.dataSources.push(data);
      },
    );
    // Select the test group corresponding to the first tab by default.
    this.selectedTestGroup = Object.keys(
      this.budgetSimulationResults.budget_tables,
    )[0];
  }

  ngOnDestroy() {
    this.taskWorkerClient.stop();
  }

  private startWorker(experimentId: string) {
    this.taskWorkerClient.start();
    this.taskWorkerClient.register((event: MessageEvent<FromWorkerEvent>) =>
      this.taskStatusUpdatedHandler(experimentId, event),
    );
  }

  private taskStatusUpdatedHandler(
    experimentId: string,
    event: MessageEvent<FromWorkerEvent>,
  ) {
    const data = event.data;
    const {type} = data;
    switch (type) {
      case WORKER_EVENT_UPDATED:
        this.businessLogicService.getExperiment(experimentId).subscribe({
          next: (experiment) => {
            this.budgetSimulationGraphTask =
              experiment.tasks[TaskTypeEnum.BUDGET_GRAPHS]!;
          },
        });
        break;
      case WORKER_EVENT_ERROR:
        this.openSnackBarWithMessage(data.message);
        break;
      default:
        this.openSnackBarWithMessage(`Unknown event type: ${type}`);
        break;
    }
  }
  private openSnackBarWithMessage(message: string): void {
    this.snackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDurationMillis,
    });
  }

  selectSimulationEntryForCausalImpactAnalysis(
    simulationEntry: SimulationEntry,
  ): void {
    this.simulationEntryClicked = true;
    this.selectedSimulationEntry = simulationEntry;
  }

  displayCausalImpactSimulationGraphs(): void {
    this.businessLogicService
      .createBudgetSimulationGraphTask(
        this.experimentId,
        this.icpa,
        this.selectedSimulationEntry.days,
        this.selectedSimulationEntry.impact + 1,
        this.selectedTestGroup,
      )
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe({
        next: () => {
          this.budgetSimulationGraphTask = createPlaceholderBudgetGraphTask();
          this.openSnackBarWithMessage(
            this.snackBarSubmittingBudgetGraphTaskMessage,
          );
          this.startWorker(this.experimentId);
        },
        error: (error: Error) => {
          this.openSnackBarWithMessage(error.message);
        },
      });
  }

  resetOnTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTestGroup = tabChangeEvent.tab.textLabel;
    this.simulationEntryClicked = false;
  }

  private raiseExceptionWhenGraphTaskIsNull(): void {
    if (!this.budgetSimulationGraphTask) {
      throw new Error('Budget Simulation Graph Task is null.');
    }
  }

  protected isTaskRunning(): boolean {
    this.raiseExceptionWhenGraphTaskIsNull();
    return (
      this.budgetSimulationGraphTask!.taskStatus ===
        TaskStatusEnum.IN_PROGRESS ||
      this.budgetSimulationGraphTask!.taskStatus === TaskStatusEnum.QUEUED
    );
  }

  protected isCompleted(): boolean {
    this.raiseExceptionWhenGraphTaskIsNull();
    return (
      this.budgetSimulationGraphTask!.taskStatus === TaskStatusEnum.COMPLETED
    );
  }

  cancelRunningTask(): void {
    this.raiseExceptionWhenGraphTaskIsNull();

    this.businessLogicService
      .cancelTask(this.experimentId, this.budgetSimulationGraphTask!.taskId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe({
        next: () => {
          this.openSnackBarWithMessage(
            `Task ${this.budgetSimulationGraphTask!.taskId} cancelled.`,
          );
        },
        error: (error: Error) => {
          this.openSnackBarWithMessage(error.message);
        },
      });
  }

  protected extractBudgetSimulationGraphResult(): BudgetSimulationGraphTaskResult {
    this.raiseExceptionWhenGraphTaskIsNull();
    return this.budgetSimulationGraphTask!
      .taskResult as BudgetSimulationGraphTaskResult;
  }

  protected sendImpactMeasurementAction(): StartingPointEnum {
    return StartingPointEnum.IMPACT_MEASUREMENT;
  }
}
