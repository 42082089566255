/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This file contains all labels for versions of EE4E client, EE4E backend and
 * EE4E core package for footer components.
 */

/** The label of EE4E client version. */
export const EE4E_CLIENT_VERSION = 'f123a19';
/** The label of EE4E backend version. */
export const EE4E_BACKEND_VERSION = 'd0dc4f9';
/** The label of EE4E core package version. */
export const EE4E_CORE_PACKAGE_VERSION = 'c789c6c';
/** The label of causal impact URL. */
export const CAUSAL_IMPACT_URL =
  'https://research.google/pubs/inferring-causal-impact-using-bayesian-structural-time-series-models/';
/** The label of EE4E manual URL. */
export const EE4E_MANUAL_URL = 'http://go/ee4e-manual';
