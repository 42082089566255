/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe to truncate long strings overflowing the container.
 */
@Pipe({
  name: 'longStringTruncate',
})
export class LongStringTruncatePipe implements PipeTransform {
  private static readonly MAX_DEFAULT_LENGTH = 20;
  private static readonly ELLIPSIS = '...';
  private static readonly PREFIX_LENGTH = 10;
  private static readonly SUFFIX_LENGTH = 5;
  transform(
    stringToTruncate: string,
    maxLength = LongStringTruncatePipe.MAX_DEFAULT_LENGTH,
  ): string {
    // Fallback to int if maxLength is a float.
    maxLength = Math.floor(maxLength);
    if (
      stringToTruncate.length <= maxLength ||
      maxLength <=
        LongStringTruncatePipe.PREFIX_LENGTH +
          LongStringTruncatePipe.SUFFIX_LENGTH +
          LongStringTruncatePipe.ELLIPSIS.length
    ) {
      return stringToTruncate;
    }

    const prefix = stringToTruncate.substring(
      0,
      LongStringTruncatePipe.PREFIX_LENGTH,
    );
    const suffix = stringToTruncate.substring(
      stringToTruncate.length - LongStringTruncatePipe.SUFFIX_LENGTH,
    );
    return `${prefix}${LongStringTruncatePipe.ELLIPSIS}${suffix}`;
  }
}
