/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {MatSnackBar} from '@angular/material/snack-bar';

import {BudgetGraphParameter} from '../model/budget-graph-parameter';
import {Task} from '../model/task';
import {TaskStatusEnum} from '../model/task-status-enum';
import {TaskTypeEnum} from '../model/task-type-enum';
import * as constants from './constants';

/**  Converts bytes into human-readable string. */
export function formatBytes(bytes: number, decimals = 2): string {
  if (!Number.isInteger(bytes) || bytes < 0) return 'Invalid value';
  if (bytes === 0) return '0 Bytes';

  const bytesInKilobyte = 1024;
  const decimalPoints = decimals < 0 ? 0 : decimals;
  const sizeUnits = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  // Select the right unit. The maximum unit is YB.
  const unitIndex = Math.min(
    Math.floor(Math.log(bytes) / Math.log(bytesInKilobyte)),
    8,
  );

  const bytesAsConvertedUnit = Number(
    (bytes / Math.pow(bytesInKilobyte, unitIndex)).toFixed(decimalPoints),
  );
  return `${bytesAsConvertedUnit} ${sizeUnits[unitIndex]}`;
}

/** Converts selected plan from index representation to string for display. */
export function convertSelectedPlanFromIndexToStr(
  selectedPlanAsIndex: number,
): string {
  if (!Number.isInteger(selectedPlanAsIndex) || selectedPlanAsIndex < 0) {
    throw new TypeError('selectedPlan is not a positive integer.');
  }
  return `Plan_${selectedPlanAsIndex + 1}`;
}

/** Converts selected plan from display string represensation to index. */
export function convertSelectedPlanFromStrToIndex(
  selectedPlanAsString: string,
): number {
  const selectedPlanAsIndex = Number(selectedPlanAsString.split('_')[1]);
  if (!isFinite(selectedPlanAsIndex) || selectedPlanAsIndex < 1) {
    throw new TypeError('selectedPlan cannot be converted to index.');
  }
  return selectedPlanAsIndex - 1;
}

/** Creates a placeholder budget graph task when the task is being processed. */
export function createPlaceholderBudgetGraphTask(): Task {
  const now = new Date();
  return {
    taskId: '',
    taskStatus: TaskStatusEnum.QUEUED,
    taskParameters: {} as unknown as BudgetGraphParameter,
    taskResult: null,
    taskTraceback: null,
    created: now,
    lastModified: now,
    taskType: TaskTypeEnum.BUDGET_GRAPHS,
    fileInfos: {},
    extra: null,
  };
}

/** Opens a snack bar with the given message. */
export function openSnackBarWithMessage(
  message: string,
  snackBar: MatSnackBar,
): void {
  snackBar.open(message, constants.SNACKBAR_ACTION_LABEL, {
    duration: constants.SNACKBAR_DURATION,
  });
}

/** Validates experiment name and description. */
export function experimentNameAndDescriptionAreValid(
  experimentName: string,
  experimentDescription: string,
): boolean {
  // Experiment Name and Description are mandatory.
  if (!experimentName || !experimentDescription) {
    return false;
  }
  // Experiment Name or Description are too long.
  if (
    experimentName.length > constants.EXPERIMENT_NAME_LENGTH_LIMIT ||
    experimentDescription.length > constants.EXPERIMENT_DESCRIPTION_LENGTH_LIMIT
  ) {
    return false;
  }
  return true;
}
