/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TaskTypeEnum} from '../../model/task-type-enum';

interface RerunDialogData {
  affectedTaskTypes: TaskTypeEnum[];
}

/**
 *  Confirmation dialog for overwriting a part of the experiment when rerunning a task.
 */
@Component({
  standalone: false,
  selector: 'app-rerun-dialog',
  templateUrl: './rerun-dialog.component.html',
  styleUrls: ['./rerun-dialog.component.scss'],
})
export class RerunDialogComponent implements OnInit {
  displayTaskNames: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: RerunDialogData) {}
  ngOnInit() {
    // Converts the task types to readable names.
    // BUDGET_TABLE and BUDGET_GRAPH are merged into Budget Simulation.
    for (const taskType of this.data.affectedTaskTypes) {
      if (taskType === TaskTypeEnum.SPLIT) {
        this.displayTaskNames.push('Splitting');
      } else if (taskType === TaskTypeEnum.BUDGET_TABLE) {
        // Checks the existence of BUDGET_TABLE since
        // BUDGET_GRAPH never appear without BUDGET_TABLE.
        this.displayTaskNames.push('Budget Simulation');
      } else if (taskType === TaskTypeEnum.ANALYSIS_DID) {
        this.displayTaskNames.push('Difference in Differences');
      } else if (taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT) {
        this.displayTaskNames.push('Causal Impact');
      }
    }
  }
}
