/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

/**
 * Default Component to display dataframe returned by the backend.
 */
@Component({
  standalone: false,
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  readonly groupsLabel = 'Groups';
  readonly controlgroupLabel = 'Control';
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<Record<string, string>>();
  @Input({required: true}) tableTitle!: string;
  @Input({required: true}) canOverflow = false;
  @Input({required: true}) dataFrame!: Record<string, Record<string, number>>;
  @Input() isTableDownloadable = false;
  private fileNamePrefix = 'aggregated_kpi_';

  ngOnInit() {
    // The first column in the data table should display the groups: Control,
    // Test_1, Test_2,... Control Group related information is always present in
    // response from the backend so it is safe to get the columns from this
    // entry.
    this.displayedColumns = [this.groupsLabel].concat(
      Object.keys(this.dataFrame[this.controlgroupLabel]),
    );
    this.dataSource = new MatTableDataSource(
      [...Object.entries(this.dataFrame)].map(([group, groupData]) => {
        const dataEntry: Record<string, string> = {};
        // Enter the group (Control, Test_1,...) in the first cell of the table.
        dataEntry[this.groupsLabel] = group;
        // Enter the rest of the data after this first column.
        for (const [key, value] of Object.entries(groupData)) {
          dataEntry[key] = String(value);
        }
        return dataEntry;
      }),
    );
  }

  displayOverflow(): Record<string, string> | null {
    if (this.canOverflow) {
      return {'overflow-x': 'scroll'};
    }
    return null;
  }

  isColumnSticky(columnIndex: number): boolean {
    return columnIndex === 0;
  }

  generateTimestampToAppendToFilename(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
  }

  generateContentOfCsvToDownload(delimiter = ','): string {
    const headers = [this.groupsLabel].concat(
      Object.keys(this.dataFrame[Object.keys(this.dataFrame)[0]]),
    );

    const rows: string[] = [];
    for (const [key, value] of Object.entries(this.dataFrame)) {
      rows.push([key, ...Object.values(value)].join(delimiter));
    }

    return [headers.join(delimiter), ...rows].join('\n');
  }

  exportToCsv(): void {
    if (!this.isTableDownloadable) {
      return;
    }

    const fileContent = this.generateContentOfCsvToDownload();

    const blob = new Blob([fileContent], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      this.fileNamePrefix + this.generateTimestampToAppendToFilename() + '.csv',
    );
    a.click();
    URL.revokeObjectURL(url);
  }
}
