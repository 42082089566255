/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AfterViewInit, Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {ActivatedRoute, Router} from '@angular/router';
import {of as observableOf, switchMap} from 'rxjs';

import * as routes from '../../common/routes';
import {BudgetTableParameter} from '../../model/budget-table-parameter';
import {Experiment} from '../../model/experiment';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {TaskTypeEnum} from '../../model/task-type-enum';
import {BusinessLogicService} from '../../service/business-logic.service';
import {ExperimentOverwriteGuardService} from '../../service/experiment-overwrite-guard.service';
import {HelpMessagesService} from '../../service/help-messages.service';

/**
 * Component displaying the input forms to submit task for transitions
 * from Splitting to Budget Simulation.
 */
@Component({
  selector: 'app-continue-experiment-to-budget-simulation',
  templateUrl: './continue-experiment-to-budget-simulation.component.html',
  styleUrls: ['./continue-experiment-to-budget-simulation.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class ContinueExperimentToBudgetSimulationComponent
  implements AfterViewInit
{
  private errorMessage = '';
  protected canDisplayPage = false;
  protected rerun = false;

  protected readonly iCPALabel = 'Estimated Incremental CPA';
  protected iCPADefaultValue = 1000;
  protected readonly durationsLabel = 'Enter durations (in days)';
  protected readonly durationsPlaceHolder = 'New duration...';
  protected durationsDefaultValue = [7, 14, 28];
  protected readonly upliftsLabel = 'Enter uplifts';
  protected readonly upliftsPlaceHolder = 'New uplift...';
  protected upliftsDefaultValue = [1.03, 1.05, 1.1];

  protected readonly snackBarDuration = 2000;
  protected readonly snackBarActionLabel = 'OK';
  protected readonly snackBarEmptyDurationsErrorMessage =
    'Durations cannot be empty.';
  protected readonly snackBarEmptyUpliftsErrorMessage =
    'Uplifts cannot be empty.';
  protected readonly snackBarInvalidIcpaErrorMessage = 'iCPA is invalid.';
  protected readonly snackBarNoTestGroupsSelectedErrorMessage =
    'Please select at least one test group.';

  durations = this.durationsDefaultValue;
  uplifts = this.upliftsDefaultValue;
  icpaForm = new FormGroup({});
  selectedResultSet = 0;
  experimentId = '';
  selectedTestGroups: string[] = [];

  private readonly destroyedRef = inject(DestroyRef);
  constructor(
    private readonly route: ActivatedRoute,
    readonly snackbar: MatSnackBar,
    private readonly businessLogicService: BusinessLogicService,
    private readonly router: Router,
    private readonly experimentOverwriteGuardService: ExperimentOverwriteGuardService,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {
    const action = this.route.snapshot.paramMap.get('action')!;
    this.experimentId = this.route.snapshot.paramMap.get('experimentId')!;

    if (action !== StartingPointEnum.BUDGET_SIMULATION) {
      this.errorMessage =
        'Action must be Budget Simulation. ' + `Received Action: ${action}.`;
      return;
    }
    this.businessLogicService
      .getExperiment(this.experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment | null) => {
        if (!experiment) {
          this.errorMessage = `Experiment: ${this.experimentId} not found.`;
          return;
        }
        if (experiment.tasks[TaskTypeEnum.BUDGET_TABLE]) {
          const budgetSimulationParametersForRerun = experiment.tasks[
            TaskTypeEnum.BUDGET_TABLE
          ].taskParameters as BudgetTableParameter;
          this.iCPADefaultValue = budgetSimulationParametersForRerun.icpa;
          this.durationsDefaultValue =
            budgetSimulationParametersForRerun.durations;
          this.upliftsDefaultValue = budgetSimulationParametersForRerun.uplifts;
          this.durations = this.durationsDefaultValue;
          this.uplifts = this.upliftsDefaultValue;
          this.rerun = true;
        }
        this.canDisplayPage = true;
      });
  }

  ngAfterViewInit() {
    if (this.errorMessage) {
      this.snackbar.open(this.errorMessage, this.snackBarActionLabel);
    }
  }

  storeDurations(modifiedDurations: Set<number>): void {
    this.durations = [...modifiedDurations];
  }

  storeUplifts(modifiedUplifts: Set<number>): void {
    this.uplifts = [...modifiedUplifts];
  }

  storeICPA(iCPAParameterRecord: Record<string, FormGroup>): void {
    const parameterName = Object.keys(iCPAParameterRecord)[0];
    this.icpaForm = iCPAParameterRecord[parameterName];
  }

  storeResultSet(resultSet: number): void {
    this.selectedResultSet = resultSet;
  }

  storeTestGroups(testGroups: string[]): void {
    this.selectedTestGroups = testGroups;
  }

  private openSnackBarWithMessage(message: string): void {
    this.snackbar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDuration,
    });
  }

  submitBudgetSimulationExperiment(): void {
    if (this.selectedTestGroups.length === 0) {
      this.openSnackBarWithMessage(
        this.snackBarNoTestGroupsSelectedErrorMessage,
      );
      return;
    }

    // iCPA form should be valid.
    if (this.icpaForm != null && this.icpaForm.invalid) {
      this.openSnackBarWithMessage(this.snackBarInvalidIcpaErrorMessage);
      return;
    }

    // Durations and Uplifts are mandatory.
    if (this.durations.length === 0) {
      this.openSnackBarWithMessage(this.snackBarEmptyDurationsErrorMessage);
      return;
    }

    if (this.uplifts.length === 0) {
      this.openSnackBarWithMessage(this.snackBarEmptyUpliftsErrorMessage);
      return;
    }

    // Submitting Budget Simulation experiment after overwrite confirmation.
    const currentTaskType = TaskTypeEnum.BUDGET_TABLE;
    this.experimentOverwriteGuardService
      .confirmOverwrite(this.experimentId, currentTaskType)
      .pipe(
        switchMap((proceed) => {
          if (proceed) {
            // Submitting Budget Simulation experiment to server.
            return this.businessLogicService.createBudgetSimulationTableTaskAsContinuation(
              this.experimentId,
              this.icpaForm.get('fieldValue')?.value ?? this.iCPADefaultValue,
              this.durations,
              this.uplifts,
              this.selectedTestGroups,
              this.selectedResultSet,
              this.rerun,
            );
          } else {
            // Not sending a request to server.
            return observableOf(null);
          }
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.openSnackBarWithMessage(
              `Submitting budget simulation task ${
                this.rerun ? 'to rerun ' : ''
              }to server.`,
            );
            const targetUrl = `${routes.VIEW_EXPERIMENT_URL}/${this.experimentId}/${StartingPointEnum.BUDGET_SIMULATION}`;
            this.router.navigate([targetUrl], {skipLocationChange: true});
          }
        },
        error: (error: Error) => {
          this.openSnackBarWithMessage(error.message);
        },
      });
  }
}
