/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  AfterViewChecked,
  Component,
  DestroyRef,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ActivatedRoute} from '@angular/router';
import {BudgetSimulationTableTaskResult} from '../../../model/budget-simulation-table-task-result';
import {Experiment} from '../../../model/experiment';
import {TaskTypeEnum} from '../../../model/task-type-enum';
import {BusinessLogicService} from '../../../service/business-logic.service';
/**
 * Component used to display the pre-experiment KPI and test groups for
 * transitions from Budget Simulation to Impact Measurement.
 */
@Component({
  standalone: false,
  selector: 'app-pre-experiment-kpi-and-test-groups-selection',
  templateUrl: './pre-experiment-kpi-and-test-groups-selection.component.html',
  styleUrls: ['./pre-experiment-kpi-and-test-groups-selection.component.scss'],
})
export class PreExperimentKpiAndTestGroupsSelectionComponent
  implements AfterViewChecked
{
  private readonly destroyedRef = inject(DestroyRef);
  protected currentExperiment!: Experiment;
  protected budgetSimulationTableTaskResult!: BudgetSimulationTableTaskResult;

  private readonly snackBarActionLabel = 'OK';
  private errorMessage = '';
  private snackBarCalled = false;

  @Output() readonly testGroupsSelectionModified = new EventEmitter<string[]>();
  protected testGroupsSelection: string[] = [];
  protected groupsKpi!: Record<string, Record<string, number>>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly businessLogicService: BusinessLogicService,
    readonly errorSnackBar: MatSnackBar,
  ) {
    const experimentId = this.route.snapshot.paramMap.get('experimentId')!;
    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment | null) => {
        if (!experiment) {
          this.errorMessage = `Experiment: ${experimentId} not found.`;
          return;
        }
        if (!experiment.tasks[TaskTypeEnum.BUDGET_TABLE]) {
          this.errorMessage =
            'Invalid transition. There are no budget simulation' +
            ' results available for this experiment.';
          return;
        }
        if (!experiment.tasks[TaskTypeEnum.BUDGET_TABLE].taskResult) {
          this.errorMessage =
            'Invalid transition. Budget Simulation task is not yet completed.';
          return;
        }
        this.currentExperiment = experiment;
        this.budgetSimulationTableTaskResult = experiment.tasks[
          TaskTypeEnum.BUDGET_TABLE
        ].taskResult as BudgetSimulationTableTaskResult;
        this.groupsKpi = this.budgetSimulationTableTaskResult.groups_kpi;
        for (let i = 1; i < Object.keys(this.groupsKpi).length; i++) {
          this.testGroupsSelection.push(`Test_${i}`);
        }
      });
  }

  ngAfterViewChecked(): void {
    if (this.errorMessage && !this.snackBarCalled) {
      this.snackBarCalled = true;
      this.errorSnackBar.open(this.errorMessage, this.snackBarActionLabel);
    }
  }

  protected emitGroupsSelection(testGroups: string[]) {
    this.testGroupsSelectionModified.emit(testGroups);
  }
}
