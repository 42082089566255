/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';

import {formatBytes} from '../../../common/utils';
import {FileInfo} from '../../../model/file-info';
import {SplittingParameters} from '../../../model/splitting-parameters';

/** Component for input files and parameters in view splitting experiment. */
@Component({
  standalone: false,
  selector: 'app-splitting-input-files-and-parameters',
  templateUrl: './splitting-input-files-and-parameters.component.html',
  styleUrls: ['./splitting-input-files-and-parameters.component.scss'],
})
export class SplittingInputFilesAndParametersComponent implements OnInit {
  @Input({required: true}) splittingParameters!: SplittingParameters;
  @Input({required: true}) kpiFileInfo!: FileInfo;
  @Input({required: true}) configFileInfo: FileInfo | null = null;
  additionalVolumeRatios = new Map<string, number>();
  numberOfPlans!: number;
  numberOfGroups!: number;
  numberOfMembersPerGroup!: number;
  correlationCoefficient!: number;
  dataVolumeCoefficient!: number;
  // KPI file
  kpiFileName!: string;
  kpiFileSize!: string;
  kpiFirstSampleDate!: string;
  kpiLastSampleDate!: string;
  // Config file
  configFileName!: string;
  configFileSize!: string;

  ngOnInit() {
    this.numberOfPlans = this.splittingParameters.number_of_result_sets;
    this.numberOfGroups = this.splittingParameters.number_of_groups;
    this.numberOfMembersPerGroup =
      this.splittingParameters.number_of_members_per_group;
    this.correlationCoefficient =
      this.splittingParameters.correlation_coefficient;
    this.dataVolumeCoefficient =
      this.splittingParameters.data_volume_coefficient;
    Object.entries(this.splittingParameters.config_volume_ratios).forEach(
      ([key, value]) => {
        this.additionalVolumeRatios.set(key, value);
      },
    );
    this.kpiFileName = this.kpiFileInfo.fileName;
    this.kpiFileSize = formatBytes(this.kpiFileInfo.size, 1);
    if (this.kpiFileInfo.extra) {
      const firstSampleDate = this.kpiFileInfo.extra['firstSampleDate'] as Date;
      this.kpiFirstSampleDate = firstSampleDate.toISOString().split('T')[0];
      const lastSampleDate = this.kpiFileInfo.extra['lastSampleDate'] as Date;
      this.kpiLastSampleDate = lastSampleDate.toISOString().split('T')[0];
    } else {
      this.kpiFirstSampleDate = '-';
      this.kpiLastSampleDate = '-';
    }

    if (this.configFileInfo) {
      this.configFileName = this.configFileInfo.fileName;
      this.configFileSize = formatBytes(this.configFileInfo.size, 1);
    }
  }

  displayNumberOfMembersPerGroup(): string {
    if (this.numberOfMembersPerGroup === -1) {
      return 'dynamic';
    }
    return this.numberOfMembersPerGroup.toString();
  }
}
