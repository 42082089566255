/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {Experiment} from '../model/experiment';

const DATABASE_NAME = 'ee4e';
const EXPERIMENTS_STORE_NAME = 'experiments';

/**
 * This service provides utility functions to access the IndexedDB database, and
 * it will be used either as an injectable instance in the business logic
 * service or as an instance created by new operator in a web worker.
 */
export class IndexedDBService {
  /**
   * Saves the given experiment to the IndexedDB database.
   * @param {Experiment} obj - The experiment to save.
   * @return {Observable<Experiment>} An observable emitting the saved
   */
  saveExperiment(obj: Experiment): Observable<string> {
    return this.openDB().pipe(
      switchMap(
        (db: IDBDatabase) =>
          this.openRequest(
            db,
            this.createPutDBRequest(db, obj),
          ) as Observable<string>,
      ),
    );
  }

  /**
   * Gets the experiment with the given id from the IndexedDB database.
   * @param {string} id - The id of the experiment to get.
   * @return {Observable<Experiment>} An observable emitting the experiment with
   */
  getExperiment(id: string): Observable<Experiment> {
    return this.openDB().pipe(
      switchMap(
        (db: IDBDatabase) =>
          this.openRequest(
            db,
            this.createGetDBRequest(db, id),
          ) as Observable<Experiment>,
      ),
    );
  }

  /**
   * Gets all experiments from the IndexedDB database.
   * @return {Observable<Experiment[]>} An observable emitting all experiments
   *    from the IndexedDB database.
   */
  getAllExperiments(): Observable<Experiment[]> {
    return this.openDB().pipe(
      switchMap(
        (db: IDBDatabase) =>
          this.openRequest(db, this.createGetAllDBRequest(db)) as Observable<
            Experiment[]
          >,
      ),
    );
  }

  /**
   * Deletes the experiment with the given id from the IndexedDB database.
   * @param {string} id - The id of experiment to delete.
   */
  deleteExperiment(id: string) {
    return this.openDB().pipe(
      switchMap((db: IDBDatabase) =>
        this.openRequest(db, this.createDeleteDBRequest(db, id)),
      ),
    );
  }

  private openDB(): Observable<IDBDatabase> {
    return new Observable<IDBDatabase>((subscriber) => {
      const dbOpenRequest = indexedDB.open(DATABASE_NAME, 1);
      dbOpenRequest.onsuccess = () => {
        subscriber.next(dbOpenRequest.result);
        subscriber.complete();
      };
      dbOpenRequest.onerror = () => {
        subscriber.error(dbOpenRequest.error);
      };
      dbOpenRequest.onupgradeneeded = () => {
        const db = dbOpenRequest.result;
        db.createObjectStore(EXPERIMENTS_STORE_NAME, {keyPath: 'id'});
      };
    });
  }

  private getObjectStore(
    db: IDBDatabase,
    mode: IDBTransactionMode,
  ): IDBObjectStore {
    const transaction = db.transaction([EXPERIMENTS_STORE_NAME], mode);
    return transaction.objectStore(EXPERIMENTS_STORE_NAME);
  }

  private createGetDBRequest(db: IDBDatabase, id: string): IDBRequest {
    return this.getObjectStore(db, 'readonly').get(id);
  }

  private createGetAllDBRequest(db: IDBDatabase): IDBRequest {
    return this.getObjectStore(db, 'readonly').getAll();
  }

  private createPutDBRequest(db: IDBDatabase, obj: Experiment): IDBRequest {
    return this.getObjectStore(db, 'readwrite').put(obj);
  }

  private createDeleteDBRequest(db: IDBDatabase, id: string): IDBRequest {
    return this.getObjectStore(db, 'readwrite').delete(id);
  }

  private openRequest(
    db: IDBDatabase,
    request: IDBRequest,
  ): Observable<unknown> {
    return new Observable<unknown>((subscriber) => {
      request.onsuccess = () => {
        subscriber.next(request.result);
        db.close();
        subscriber.complete();
      };
      request.onerror = () => {
        subscriber.error(request.error);
      };
    });
  }
}
