<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<form [formGroup]="experimentDetailsForm">
  <mat-form-field class="new-exp-form-field">
      <mat-label i18n>Experiment Name</mat-label>
      <input matInput type="text" formControlName="experimentName" (input)="sendExperimentName()"
        [matTooltip]="helpMessagesService.experimentCommonInputFields.INPUT_NAME"
        [value]="defaultName" [readonly]="isDisabled"
        placeholder="Enter the name of the new experiment..." i18n-placeholder/>
      <button *ngIf="experimentName" matSuffix mat-icon-button (click)="clearExperimentName()"
      [disabled]="isDisabled">
          <mat-icon>close</mat-icon>
      </button>
      <mat-error
          *ngIf="!experimentName" i18n>The experiment name is mandatory.</mat-error>
          <mat-error *ngIf="isExperimentNameTooLong()" i18n
            >Experiment name longer than {{experimentNameMaxLength}}.</mat-error>
      <mat-hint align="end">{{experimentName.length}}/{{experimentNameMaxLength}}</mat-hint>
  </mat-form-field>

  <mat-form-field class="new-exp-form-field">
      <mat-label for="experiment_description" i18n>Experiment Description</mat-label>
      <textarea matInput type="text" formControlName="experimentDescription" novalidate
        [matTooltip]="helpMessagesService.experimentCommonInputFields.INPUT_DESCRIPTION"
        matTooltipClass="max-width-tooltip" (input)="sendExperimentDescription()"
        [value]="defaultDescription" [readonly]="isDisabled"
        placeholder="Enter the description of the new experiment..." i18n-placeholder>
      </textarea>
      <button *ngIf="experimentDescription" matSuffix mat-icon-button [disabled]="isDisabled"
        (click)="clearExperimentDescription()">
          <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="!experimentDescription" i18n
        >The experiment description is mandatory.</mat-error>
        <mat-error *ngIf="isExperimentDescriptionTooLong()" i18n
        >Experiment description longer than {{experimentDescriptionMaxLength}}.</mat-error>
      <mat-hint
          align="end">{{experimentDescription.length}}/{{experimentDescriptionMaxLength}}</mat-hint>
  </mat-form-field>
</form>