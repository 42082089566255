/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Constants for EE4E Web Client.
 */

/** The maximum length of an experiment name. */
export const EXPERIMENT_NAME_LENGTH_LIMIT = 100;
/** The maximum length of an experiment description. */
export const EXPERIMENT_DESCRIPTION_LENGTH_LIMIT = 500;

/** Snackbar messages related constants */

/** The duration of the snackbar message. */
export const SNACKBAR_DURATION = 2000;
/** The label of the snackbar action button. */
export const SNACKBAR_ACTION_LABEL = 'OK';
/**
 * Snackbar error message when experiment name or description
 * are empty or too long.
 */
export const SNACKBAR_INVALID_NAME_DESCRIPTION_ERROR_MESSAGE =
  'Invalid Experiment name or description.';
/** Snackbar error message when KPI file is not attached. */
export const SNACKBAR_KPI_FILE_ERROR_MESSAGE = 'A KPI file must be attached.';

/** The minimum number of seasons for Causal Impact analysis. */
export const MINIMUM_NUMBER_OF_SEASONS = 1;
/** The maximum number of seasons for Causal Impact analysis. */
export const MAXIMUM_NUMBER_OF_SEASONS = 20;
/** The default value of the number of seasons for Causal Impact analysis. */
export const DEFAULT_NUMBER_OF_SEASONS = 1;
/** The default value of the confidence interval for Causal Impact analysis. */
export const DEFAULT_CONFIDENCE_INTERVAL = 0.95;
/** The values of the confidence interval for Causal Impact analysis. */
export const CONFIDENCE_INTERVAL_VALUES = [90, 95, 99] as const;
