<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div class="experiments_header">
  <button id="new-experiment-button" (click)="navigateToNewExperiment()"
    [matTooltip]="helpMessagesService.experimentsListPage.BUTTON_NEW_EXPERIMENT"
    mat-raised-button color="accent" i18n>
    <mat-icon>add</mat-icon>
    New Experiment</button>
  <div>
    <button id="import-experiments-button" (click)="fileInput.click()"
      [matTooltip]="helpMessagesService.experimentsListPage.BUTTON_IMPORT_EXPERIMENTS"
      matTooltipClass="max-width-tooltip"
      mat-raised-button class="right-btn" color="accent" i18n>
      <mat-icon>upload</mat-icon>
      Import Experiments
      <input #fileInput type="file" style="display:none"
        (change)="fileInput.files && importExperiments(fileInput.files)" accept=".json" />
    </button>
    <button id="export-experiments-button" (click)="exportExperiments()"
      [matTooltip]="helpMessagesService.experimentsListPage.BUTTON_EXPORT_EXPERIMENTS"
      mat-raised-button class="right-btn" color="accent" i18n>
      <mat-icon>file_download</mat-icon>
      Export Experiments</button>
    <button id="delete-experiments-button" (click)="deleteSelectedExperiments()"
      [matTooltip]="helpMessagesService.experimentsListPage.BUTTON_DELETE_EXPERIMENTS"
      mat-raised-button class="right-btn" color="accent" i18n>
      <mat-icon>delete</mat-icon>
      Delete Experiments</button>
  </div>
</div>
<div class="experiments_list">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell
        [matTooltip]="helpMessagesService.experimentsListPage.TABLE_HEADER_NAME"
        *matHeaderCellDef mat-sort-header i18n>Experiment Name</th>
      <td mat-cell *matCellDef="let experiment">{{experiment.name}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell
        [matTooltip]="helpMessagesService.experimentsListPage.TABLE_HEADER_DESCRIPTION"
        matTooltipClass="max-width-tooltip"
        *matHeaderCellDef mat-sort-header i18n>Experiment Description</th>
      <td mat-cell *matCellDef="let experiment">{{experiment.description}}</td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell
        [matTooltip]="helpMessagesService.experimentsListPage.TABLE_HEADER_CREATION_DATE"
        *matHeaderCellDef mat-sort-header i18n>Creation Date</th>
      <td mat-cell
        *matCellDef="let experiment">{{experiment.created | date:'medium'}}</td>
    </ng-container>
    <ng-container matColumnDef="lastModified">
      <th mat-header-cell
        [matTooltip]="helpMessagesService.experimentsListPage.TABLE_HEADER_LAST_MODIFICATION_DATE"
        *matHeaderCellDef mat-sort-header i18n>Last Modification Date</th>
      <td mat-cell *matCellDef="let experiment">{{experiment.lastModified | date:'medium'}}</td>
    </ng-container>
    <ng-container matColumnDef="startingPoint">
      <th mat-header-cell
        [matTooltip]="helpMessagesService.experimentsListPage.TABLE_HEADER_STARTING_POINT"
        *matHeaderCellDef mat-sort-header i18n>Starting Point</th>
      <td mat-cell *matCellDef="let experiment">{{experiment.startingPoint}}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef i18n>Tasks</th>
      <td mat-cell *matCellDef="let experiment">
        <mat-nav-list>
          <a mat-list-item *ngFor="let action of generateActions(experiment)"
            [matTooltip]="helpMessagesService.experimentsListPage.TABLE_CELL_GO_TO_TASK
              + action + '.'"
            [matTooltipDisabled]="!canViewSubmittedTask(experiment, action)"
            (click)="viewSubmittedTask(experiment, action)"
            [disabled]="!canViewSubmittedTask(experiment, action)">{{action}}</a>
        </mat-nav-list>
      </td>
    </ng-container>
    <ng-container matColumnDef="icons">
      <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
      <td mat-cell *matCellDef="let experiment">
        <div class="action-buttons-container">
          <button mat-icon-button
            [matTooltip]="helpMessagesService.experimentsListPage.TABLE_ACTION_EXPORT_EXPERIMENT"
            (click)="exportOneExperiment(experiment.id)"><mat-icon>download</mat-icon></button>
          <button mat-icon-button
            [matTooltip]="helpMessagesService.experimentsListPage.TABLE_ACTION_DELETE_EXPERIMENT"
            (click)="deleteOneExperiment(experiment)"><mat-icon>delete</mat-icon></button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [class.highlight-row]="row === clickedExperiment"></tr>
  </table>
</div>