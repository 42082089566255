/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

/**
 * Component used to select a CSV file containing configuration for splitting or
 * KPI data. The selected file is sent to the parent component to read and
 * validate the content.
 */
@Component({
  standalone: false,
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent {
  @Input() inputFileLabel = '';
  @Output() readonly fileSelected = new EventEmitter<File | null>();
  @ViewChild('fileInput') fileInput!: ElementRef;
  selectedFile: File | null = null;

  getSelectedFile(files: FileList): void {
    if (files?.length) {
      const file = files[0];
      this.fileSelected.emit(file);
      this.selectedFile = file;
    }
  }

  resetSelectedFile(): void {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = null;
  }
}
