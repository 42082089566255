<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <h2 i18n>Member Allocations for Test/Control Groups</h2>
  <div class="member-allocation-area">
    <mat-form-field>
      <mat-label>Member Filter</mat-label>
      <input matInput (keyup)="applyMemberBasedFilter($event)" placeholder="Ex. Tokyo"
        [matTooltip]="helpMessagesService.experimentCommonInputFields.INPUT_MEMBER_FILTER">
    </mat-form-field>
    <mat-form-field style="margin-left: 2em">
      <mat-label>Group</mat-label>
      <mat-select [(value)]="selectedGroup" (selectionChange)="applyGroupBasedFilter($event)"
        [matTooltip]="helpMessagesService.experimentCommonInputFields.SELECT_GROUP_FILTER">
        <mat-option value="All">All</mat-option>
        <mat-option value="Control">Control</mat-option>
        <mat-option value="{{testGroup}}"
            *ngFor="let testGroup of testGroupLabels">{{testGroup}}</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="download-button" *ngIf="isTableDownloadable">
      <button mat-flat-button  color="primary" (click)="exportToCsv()"
        [matTooltip]="helpMessagesService.experimentCommonInputFields.BUTTON_GROUP_MEMBER_DOWNLOAD"
        matTooltipClass="max-width-tooltip" i18n>
        <mat-icon>file_download</mat-icon>
        Export to CSV
      </button>
    </span>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 member-allocations-table" style="margin-bottom:1em;"
      [matTooltip]="helpMessagesService.experimentCommonInputFields.TABLE_MEMBER_GROUP_MAPPINGS"
      matTooltipClass="max-width-tooltip">
      <ng-container matColumnDef="member">
      <th mat-header-cell *matHeaderCellDef i18n>Member</th>
      <td mat-cell *matCellDef="let member">{{member.name}}</td>
      </ng-container>
      <ng-container matColumnDef="allocation">
      <th mat-header-cell *matHeaderCellDef i18n>Group Allocation</th>
      <td mat-cell *matCellDef="let member">{{member.allocation}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]">
    </mat-paginator>
  </div>
</div>
