/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, Input, OnInit, inject} from '@angular/core';

import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {Experiment} from '../../../model/experiment';
import {RecoverableError} from '../../../model/recoverable-error';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import * as Task from '../../../model/task';
import {TaskStatusEnum} from '../../../model/task-status-enum';
import {TaskTypeEnum} from '../../../model/task-type-enum';
import {BusinessLogicService} from '../../../service/business-logic.service';

import * as routes from '../../../common/routes';

/**
 * Component to display cancellation or error message when a task fails
 * or when it is aborted by the user.
 */
@Component({
  standalone: false,
  selector: 'app-cancellation-and-error-message-card',
  templateUrl: './cancellation-and-error-message-card.component.html',
  styleUrls: ['./cancellation-and-error-message-card.component.scss'],
})
export class CancellationAndErrorMessageCardComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  @Input({required: true}) task!: Task.Task;
  @Input({required: true}) experimentId!: string;
  experimentToRerunTask!: Experiment;

  readonly failureCardHeader = 'Error Message';
  readonly cancellationMessage =
    'No results to display since the task was cancelled.';
  readonly unknownErrorTypeLabel = 'UnknownError';
  readonly tracebackErrorTypeLabel = 'SystemError';
  readonly unknownErrorMessage = 'An unknown error has occurred.';

  constructor(
    private readonly businessLogicService: BusinessLogicService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.loadExperiment(this.experimentId);
  }

  private loadExperiment(experimentId: string) {
    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment: Experiment) => {
        this.experimentToRerunTask = experiment;
      });
  }

  protected displayTypeOfResults(): string {
    let textToDisplay: string;
    switch (this.task.taskType) {
      case TaskTypeEnum.SPLIT:
        textToDisplay = 'Splitting';
        break;
      case TaskTypeEnum.ANALYSIS_DID:
        textToDisplay = 'Impact Analysis';
        break;
      case TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT:
        textToDisplay = 'Impact Analysis';
        break;
      default:
        textToDisplay = 'Budget Simulation';
        break;
    }
    return textToDisplay.concat(' Results');
  }

  protected isCancelled(): boolean {
    return this.task.taskStatus === TaskStatusEnum.CANCELLED;
  }

  protected displayFailureOrCancellationCardHeader(): string {
    if (this.task.taskStatus === TaskStatusEnum.CANCELLED) {
      return this.displayTypeOfResults().concat(' - Cancelled');
    }
    return this.failureCardHeader;
  }

  protected displayErrorMessage(): string {
    if (this.task.taskStatus === TaskStatusEnum.CANCELLED) {
      return this.cancellationMessage;
    }
    return this.extractErrorMessage();
  }

  private isRecoverableError(
    taskResult: Task.TaskResult | null,
  ): taskResult is RecoverableError {
    const recoverableError = taskResult as RecoverableError;
    return (
      recoverableError !== null &&
      recoverableError.error_message !== undefined &&
      recoverableError.error_type !== undefined
    );
  }

  protected extractErrorMessage(): string {
    if (this.isRecoverableError(this.task.taskResult)) {
      return `${this.task.taskResult.error_message}`;
    } else {
      return this.task.taskTraceback ?? this.unknownErrorMessage;
    }
  }

  protected displayErrorType(): string {
    if (this.isRecoverableError(this.task.taskResult)) {
      return `${this.task.taskResult.error_type}`;
    } else if (this.task.taskTraceback) {
      return this.tracebackErrorTypeLabel;
    } else {
      return this.unknownErrorTypeLabel;
    }
  }

  protected rerunTask(): void {
    let rerunTask: StartingPointEnum;
    /** When the current task is DID or CI, we can simply use the
     * current continuation logic as there is no difference if the starting
     * point is Impact Measurement or not for such cases.
     */
    if (
      this.task.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT ||
      this.task.taskType === TaskTypeEnum.ANALYSIS_DID
    ) {
      rerunTask = StartingPointEnum.IMPACT_MEASUREMENT;
      this.router.navigate(
        [`${routes.CONTINUE_EXPERIMENT_URL}/${this.experimentId}/${rerunTask}`],
        {skipLocationChange: true},
      );
    } else if (
      /** For a Budget Simulation task, if the starting point of the experiment
       * is the splitting we can also use the current continuation logic for
       * transitions from Splitting to Budget Simulation.
       */
      this.task.taskType === TaskTypeEnum.BUDGET_TABLE &&
      this.experimentToRerunTask.startingPoint === StartingPointEnum.SPLITTING
    ) {
      rerunTask = StartingPointEnum.BUDGET_SIMULATION;
      this.router.navigate(
        [`${routes.CONTINUE_EXPERIMENT_URL}/${this.experimentId}/${rerunTask}`],
        {skipLocationChange: true},
      );
      return;
    }
    // TODO: b/310512499 - Implement the logic allowing users to rerun splitting
    // tasks and Budget Simulation tasks that are the starting point.
    return;
  }
}
