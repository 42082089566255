<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <mat-tab-group color="accent" (selectedTabChange)="resetOnTabChanged($event)">
    <mat-tab label="{{testGroupResult.key}}"
        *ngFor="let testGroupResult of budgetSimulationResults.budget_tables | keyvalue;
        index as i">
      <div class="view-result-container view-result-entry">
        <h2 i18n>Budget Simulation Results Table</h2>
      </div>
      <div class="view-result-container view-result-entry">
        <table mat-table [dataSource]="dataSources[i]" class="mat-elevation-z8">
          <ng-container matColumnDef="days">
            <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_DAYS"
              matTooltipClass="max-width-tooltip">{{simulationEntryLabels.days}}</th>
            <td mat-cell *matCellDef="let simulationEntry"
              >{{simulationEntry.days | number: '1.0-0'}}</td>
          </ng-container>
          <ng-container matColumnDef="budget">
            <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_BUDGET"
              matTooltipClass="multi-line-tooltip max-width-tooltip"
              >{{simulationEntryLabels.budget}}</th>
            <td mat-cell *matCellDef="let simulationEntry" class="budget-column-text"
              ><i>{{simulationEntry.budget | number: '1.0-0'}}</i></td>
          </ng-container>
          <ng-container matColumnDef="impact">
            <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_IMPACT"
              matTooltipClass="max-width-tooltip">{{simulationEntryLabels.impact}}</th>
            <td mat-cell *matCellDef="let simulationEntry"
              >{{simulationEntry.impact | percent:'1.2-2'}}</td>
          </ng-container>
          <ng-container matColumnDef="mape">
              <th mat-header-cell *matHeaderCellDef
                [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_MAPE"
                matTooltipClass="max-width-tooltip">{{simulationEntryLabels.mape}}</th>
              <td mat-cell *matCellDef="let simulationEntry"
                >{{simulationEntry.mape | percent:'1.2-2'}}</td>
          </ng-container>
          <ng-container matColumnDef="absEffect">
              <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_ABS_EFFECT"
                matTooltipClass="unset-max-width-tooltip">{{simulationEntryLabels.absEffect}}</th>
              <td mat-cell *matCellDef="let simulationEntry"
                >{{simulationEntry.absEffect | number: '1.0-0'}}</td>
          </ng-container>
          <ng-container matColumnDef="relEffect">
              <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_REL_EFFECT"
                matTooltipClass="max-width-tooltip">{{simulationEntryLabels.relEffect}}</th>
              <td mat-cell *matCellDef="let simulationEntry"
                >{{simulationEntry.relEffect | percent:'1.2-2'}}</td>
          </ng-container>
          <ng-container matColumnDef="effectDiff">
            <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_EFFECT_DIFF"
              matTooltipClass="max-width-tooltip">{{simulationEntryLabels.effectDiff}}</th>
            <td mat-cell *matCellDef="let simulationEntry"
              >{{simulationEntry.effectDiff | percent:'1.2-2'}}</td>
          </ng-container>
          <ng-container matColumnDef="diffImpact">
            <th mat-header-cell *matHeaderCellDef
            [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_DIFF_IMPACT"
              matTooltipClass="max-width-tooltip">{{simulationEntryLabels.diffImpact}}</th>
            <td mat-cell *matCellDef="let simulationEntry"
              >{{simulationEntry.diffImpact | percent:'1.2-2'}}</td>
          </ng-container>
          <ng-container matColumnDef="mean">
            <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_MEAN"
              matTooltipClass="max-width-tooltip">{{simulationEntryLabels.mean}}</th>
            <td mat-cell *matCellDef="let simulationEntry"
              >{{simulationEntry.mean | number: '1.0-0'}}</td>
          </ng-container>
          <ng-container matColumnDef="pValue">
            <th mat-header-cell *matHeaderCellDef
              [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TABLE_HEADER_P_VALUE"
              matTooltipClass="max-width-tooltip">{{simulationEntryLabels.pValue}}</th>
            <td mat-cell *matCellDef="let simulationEntry"
              >{{simulationEntry.pValue | number: '1.2-2'}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row matRipple matTooltip="Click for further analysis." [matRippleRadius]="25"
            *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer"
            (click)="selectSimulationEntryForCausalImpactAnalysis(row)"></tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="simulationEntryClicked" class="budget-simulation-table">
  <h2 i18n>Selected Simulation Entry</h2>
  <mat-card class="view-experiment-field">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title i18n>Duration & Uplift</mat-card-title>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
        <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle>{{simulationEntryLabels.days}}</mat-card-subtitle>
                <p>{{selectedSimulationEntry.days}}</p>
              </mat-card-title-group>
            </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
            <mat-card-header>
                <mat-card-title-group>
                  <mat-card-subtitle>{{simulationEntryLabels.impact}}</mat-card-subtitle>
                  <p>{{selectedSimulationEntry.impact | percent:'1.2-2'}}</p>
                </mat-card-title-group>
            </mat-card-header>
        </mat-card>
        <button mat-raised-button style="margin-top: 1em;" color="accent"
          [matTooltip]="helpMessagesService.budgetSimulationResultsPage.BUTTON_DISPLAY_SIMULATION_GRAPHS"
          matTooltipClass="max-width-tooltip" (click)="displayCausalImpactSimulationGraphs()"
          i18n>Impact Measurement Simulation</button>
    </mat-card-content>
  </mat-card>
</div>
<div class="view-result-container">
<app-rerun-button [task]="task" [experimentId]="experimentId" class="left-button"
  [matTooltip]="helpMessagesService.experimentCommonInputFields.BUTTON_RERUN"
  matTooltipClass="max-width-tooltip" />
  <ng-container *ngIf="!budgetSimulationGraphTask && !simulationEntryClicked">
    <app-routing-button [buttonLabel]="'Impact Measurement'"
      [matTooltip]="helpMessagesService.budgetSimulationResultsPage.BUTTON_GO_TO_IMPACT_MEASUREMENT"
      matTooltipClass="max-width-tooltip"
      [experimentId]="experimentId" [action]="sendImpactMeasurementAction()"/>
  </ng-container>
</div>
<div class="view-results" *ngIf="budgetSimulationGraphTask">
  <mat-divider></mat-divider>
  <h2 [matTooltip]="helpMessagesService.budgetSimulationResultsPage.TITLE_BUDGET_SIMULATION_DETAILS"
    matTooltipClass="multi-line-tooltip max-width-tooltip" i18n>Budget Simulation Details</h2>
  <ng-container *ngIf="isTaskRunning(); else noSpinner">
    <mat-spinner color="accent" [diameter]="spinnerDiameter" class="pending-results-splinner"/>
    <button mat-raised-button class="view-results-buttons" color="accent"
        (click)="cancelRunningTask()" i18n>Cancel</button>
  </ng-container>
  <ng-template #noSpinner>
    <ng-container *ngIf="isCompleted(); else cancelledOrFailed">
      <app-budget-simulation-graphs-results *ngIf="budgetSimulationGraphTask.taskResult"
        [budgetSimulationGraphsResult]="extractBudgetSimulationGraphResult()"
        [experimentId]="experimentId"/>
    </ng-container>
    <ng-template #cancelledOrFailed>
      <app-cancellation-and-error-message-card [task]="budgetSimulationGraphTask" [experimentId]="experimentId" />
    </ng-template>
  </ng-template>
</div>