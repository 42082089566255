/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, inject, OnDestroy} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {convertSelectedPlanFromIndexToStr} from '../../common/utils';
import {BudgetTableParameter} from '../../model/budget-table-parameter';
import {CausalImpactAnalysisParameters} from '../../model/causal-impact-analysis-parameters';
import {DidAnalysisParameters} from '../../model/did-analysis-parameters';
import {Experiment} from '../../model/experiment';
import {FileInfo} from '../../model/file-info';
import {SplittingParameters} from '../../model/splitting-parameters';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {Task} from '../../model/task';
import {TaskStatusEnum} from '../../model/task-status-enum';
import {TaskTypeEnum} from '../../model/task-type-enum';
import {BusinessLogicService} from '../../service/business-logic.service';
import {TaskWorkerClient} from '../../service/task-status-worker-client';
import {
  FromWorkerEvent,
  WORKER_EVENT_ERROR,
  WORKER_EVENT_UPDATED,
} from '../../task-status.worker-events';

import * as routes from '../../common/routes';

/** The main component to view an existing experiment. */
@Component({
  standalone: false,
  selector: 'app-view-experiment',
  templateUrl: './view-experiment.component.html',
  styleUrls: ['./view-experiment.component.scss'],
})
export class ViewExperimentComponent implements OnDestroy {
  private readonly destroyedRef = inject(DestroyRef);
  private readonly snackBarActionLabel = 'OK';
  private readonly snackBarDuration = 2000;
  private readonly selectedPlanLabel = 'selectedPlan';
  experimentToView!: Experiment;
  tasksToView!: Task[];
  selectedPlans: Array<string | null> = [];
  protected action: StartingPointEnum;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly businessLogicService: BusinessLogicService,
    private readonly taskWorkerClient: TaskWorkerClient,
    private readonly router: Router,
    private readonly submissionSnackBar: MatSnackBar,
  ) {
    // TODO: b/311582180 - Add validation on the experiment and on the action.
    // Display an error message if they don't exist.
    const experimentId = this.route.snapshot.paramMap.get('experimentId')!;
    this.action = this.route.snapshot.paramMap.get(
      'action',
    )! as StartingPointEnum;

    this.loadExperiment(experimentId);
  }

  ngOnDestroy() {
    this.taskWorkerClient.stop();
  }

  private loadExperiment(experimentId: string) {
    this.businessLogicService
      .getExperiment(experimentId)
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((experiment) => {
        this.experimentToView = experiment;
        this.tasksToView = this.getTasksBasedOnAction(this.action)!;
        this.tasksToView.forEach((task) =>
          this.selectedPlans.push(this.extractSelectedPlan(task)),
        );

        if (this.canStartWorker(this.tasksToView)) {
          this.startWorker(experimentId);
        }
      });
  }

  private canStartWorker(tasks: Task[]): boolean {
    return (
      tasks.filter(
        (task) =>
          task.taskStatus === TaskStatusEnum.QUEUED ||
          task.taskStatus === TaskStatusEnum.IN_PROGRESS,
      ).length > 0
    );
  }

  private startWorker(experimentId: string) {
    this.taskWorkerClient.start();
    this.taskWorkerClient.register((event: MessageEvent<FromWorkerEvent>) =>
      this.taskStatusUpdatedHandler(experimentId, event),
    );
  }

  private taskStatusUpdatedHandler(
    experimentId: string,
    event: MessageEvent<FromWorkerEvent>,
  ) {
    const data = event.data;
    const {type} = data;
    switch (type) {
      case WORKER_EVENT_UPDATED:
        this.loadExperiment(experimentId);
        this.router.navigate(
          [`${routes.VIEW_EXPERIMENT_URL}/${experimentId}/${this.action}`],
          {skipLocationChange: true},
        );
        break;
      case WORKER_EVENT_ERROR:
        this.openSnackBarWithMessage(data.message);
        break;
      default:
        this.openSnackBarWithMessage(`Unknown event type: ${type}`);
        break;
    }
  }

  private openSnackBarWithMessage(message: string): void {
    this.submissionSnackBar.open(message, this.snackBarActionLabel, {
      duration: this.snackBarDuration,
    });
  }

  private getTasksBasedOnAction(action: StartingPointEnum): Task[] | undefined {
    if (action === StartingPointEnum.SPLITTING) {
      return [this.experimentToView.tasks[TaskTypeEnum.SPLIT]!];
    } else if (action === StartingPointEnum.BUDGET_SIMULATION) {
      return [this.experimentToView.tasks[TaskTypeEnum.BUDGET_TABLE]!];
    }
    // When Causal Impact and DID tasks both exist, the latest is displayed 1st.
    const tasksToView: Task[] = [];
    if (this.experimentToView.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT]) {
      tasksToView.push(
        this.experimentToView.tasks[TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT],
      );
    }
    if (this.experimentToView.tasks[TaskTypeEnum.ANALYSIS_DID]) {
      tasksToView.push(this.experimentToView.tasks[TaskTypeEnum.ANALYSIS_DID]);
    }
    // Sort by last modified time in descending order, for display in matTabs.
    tasksToView.sort(
      (task1, task2) =>
        task2.lastModified.getTime() - task1.lastModified.getTime(),
    );
    return tasksToView;
  }

  protected isFirstTaskTypeCausalImpact(task: Task) {
    return task.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT;
  }

  /** Returns always false except when DID and Causal Impact results exist. */
  protected hasOnlyOneTask(): boolean {
    return this.tasksToView.length === 1;
  }

  canDisplaySplittingParameters(): boolean {
    return (
      this.hasOnlyOneTask() &&
      this.tasksToView[0].taskType === TaskTypeEnum.SPLIT
    );
  }

  canDisplayBudgetSimulationParameters(): boolean {
    return (
      this.hasOnlyOneTask() &&
      this.tasksToView[0].taskType === TaskTypeEnum.BUDGET_TABLE
    );
  }

  canDisplayCausalImpactAnalysisParameters(): boolean {
    return (
      this.tasksToView.filter(
        (task) => task.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
      ).length > 0
    );
  }

  canDisplayDidAnalysisParameters(): boolean {
    return (
      this.tasksToView.filter(
        (task) => task.taskType === TaskTypeEnum.ANALYSIS_DID,
      ).length > 0
    );
  }

  extractSplittingParameters(): SplittingParameters {
    if (!this.canDisplaySplittingParameters()) {
      throw new TypeError('Task type is not splitting.');
    }
    return this.tasksToView[0].taskParameters as SplittingParameters;
  }

  extractBudgetTableParameter(): BudgetTableParameter {
    if (!this.canDisplayBudgetSimulationParameters()) {
      throw new TypeError('Task type is not budget simulation.');
    }
    return this.tasksToView[0].taskParameters as BudgetTableParameter;
  }

  extractCausalImpactAnalysisParameters(): CausalImpactAnalysisParameters {
    // There should be at most one Causal Impact Analysis task.
    const causalImpactTask = this.tasksToView.find(
      (task) => task.taskType === TaskTypeEnum.ANALYSIS_CAUSAL_IMPACT,
    );
    if (!causalImpactTask) {
      throw new TypeError('No Causal Impact Analysis task in existing tasks.');
    }
    return causalImpactTask.taskParameters as CausalImpactAnalysisParameters;
  }

  extractDidAnalysisParameters(): DidAnalysisParameters {
    // There should be at most one DID Analysis task.
    const didTask = this.tasksToView.find(
      (task) => task.taskType === TaskTypeEnum.ANALYSIS_DID,
    );
    if (!didTask) {
      throw new TypeError('No DID Analysis task in existing tasks.');
    }
    return didTask.taskParameters as DidAnalysisParameters;
  }

  extractKPIFileForSplittingOrImpactMeasurement(index: number): FileInfo {
    if (!this.tasksToView[index].fileInfos.KPI) {
      throw new Error('Mandatory KPI file not found.');
    }
    return this.tasksToView[index].fileInfos.KPI!;
  }

  extractKPIFileForBudgetSimulation(): FileInfo | null {
    return this.tasksToView[0].fileInfos.KPI ?? null;
  }

  extractConfigFile(): FileInfo | null {
    return this.tasksToView[0].fileInfos.CONFIG ?? null;
  }

  private extractSelectedPlan(task: Task): string | null {
    if (!task.extra) return null;
    const extra = task.extra as Record<string, unknown>;
    if (extra[this.selectedPlanLabel] != null) {
      return convertSelectedPlanFromIndexToStr(
        extra[this.selectedPlanLabel] as number,
      );
    }
    return null;
  }
}
