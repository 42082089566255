/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/** Worker event type for starting polling. */
export const WORKER_EVENT_START = 'start';

/** Worker event type for stopping polling. */
export const WORKER_EVENT_STOP = 'stop';

/** Worker event type for emitting task-updated notification. */
export const WORKER_EVENT_UPDATED = 'updated';

/** Worker event type for emitting an error notification. */
export const WORKER_EVENT_ERROR = 'error';

/** Worker event type sent from the main thread. */
export type ToWorkerEvent = StartPollingEvent | StopPollingEvent;

/** Event to start polling. */
export declare interface StartPollingEvent {
  type: typeof WORKER_EVENT_START;
  interval?: number;
}

/** Event to stop polling. */
export declare interface StopPollingEvent {
  type: typeof WORKER_EVENT_STOP;
}

/** Worker event type sent to the main thread. */
export type FromWorkerEvent = TaskUpdatedEvent | WorkerErrorEvent;

/** Event to notify the main thread to refresh the UI. */
export declare interface TaskUpdatedEvent {
  type: typeof WORKER_EVENT_UPDATED;
  taskId: string;
}

/** Event to notify the main thread there is an error happened. */
export declare interface WorkerErrorEvent {
  type: typeof WORKER_EVENT_ERROR;
  message: string;
}
