<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div class="view-result-container view-result-entry">
  <ng-container *ngIf="summaryAverage && summaryCumulative">
    <h2 i18n>Reliability of KPI inference and Causal Effect</h2>
    <mat-card class="view-experiment-field"
      [matTooltip]="helpMessagesService.causalImpactResultsPage.CARD_MAPE"
      matTooltipClass="max-width-tooltip">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>MAPE</mat-card-subtitle>
          <p>{{mape | percent : '1.2-2'}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field"
      [matTooltip]="helpMessagesService.causalImpactResultsPage.CARD_P_VALUE"
      matTooltipClass="max-width-tooltip">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>P value</mat-card-subtitle>
          <p>{{summaryAverage['p_value']| number:'1.3-3'}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <h2 i18n>Causal Effect in Post-Experiment Period</h2>
    <mat-card class="view-experiment-field"
      [matTooltip]="helpMessagesService.causalImpactResultsPage.CARD_ABSOLUTE_EFFECT"
      matTooltipClass="max-width-tooltip">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Absolute effect on average</mat-card-subtitle>
          <p>{{summaryAverage['abs_effect']|number:'.1-1'}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content"
        [matTooltip]="helpMessagesService.causalImpactResultsPage.CARD_RELATIVE_EFFECT"
        matTooltipClass="max-width-tooltip">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Relative effect on average</mat-card-subtitle>
          <p>{{summaryAverage['rel_effect']|percent:'1.2-2'}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
    <h2 i18n>Causal Impact Report Summary</h2>
    <div class="view-experiment-field">
      <mat-expansion-panel (opened)="causalImpactSummaryPanelOpenState = true"
        (closed)="causalImpactSummaryPanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-description>Click to {{causalImpactSummaryPanelOpenState ? 'close': 'open'}}
            the detail</mat-panel-description>
        </mat-expansion-panel-header>
        <h3>Posterior Inference</h3>
        <table class="causal-impact-summary-table">
          <tr>
            <th></th>
            <th>Average</th>
            <th>Cumulative</th>
          </tr>
          <tr>
            <td>Actual</td>
            <td>{{summaryAverage['actual']| number:'1.1-1'}}</td>
            <td>{{summaryCumulative['actual']| number:'1.1-1'}}</td>
          </tr>
          <tr>
            <td>Prediction&emsp;(s.d.)</td>
            <td>{{summaryAverage['predicted']| number:'1.1-1'}}&emsp;
              ({{summaryAverage['predicted_sd'] | number:'1.2-2'}})</td>
            <td>{{summaryCumulative['predicted'] | number:'1.1-1'}}&emsp;
              ({{summaryCumulative['predicted_sd']| number:'1.2-2'}})</td>
          </tr>
          <tr>
            <td>{{1 - summaryAverage['alpha'] | percent}} CI</td>
            <td>[{{summaryAverage['predicted_lower'] | number:'1.1-1'}},&emsp;
              {{summaryAverage['predicted_upper'] |number:'1.1-1'}}]</td>
            <td>[{{summaryCumulative['predicted_lower'] | number:'1.1-1'}},&emsp;
              {{summaryCumulative['predicted_upper'] |number:'1.1-1'}}]</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Absolute effect&emsp;(s.d.)</td>
            <td>{{summaryAverage['abs_effect']|number:'1.1-1'}}&emsp;
              ({{summaryAverage['abs_effect_sd'] | number:'1.2-2'}})</td>
            <td>{{summaryCumulative['abs_effect']|number:'1.1-1'}}&emsp;
              ({{summaryCumulative['abs_effect_sd'] |number:'1.2-2'}})</td>
          </tr>
          <tr>
            <td>{{1 - summaryAverage['alpha'] | percent}} CI</td>
            <td>[{{summaryAverage['abs_effect_lower'] | number:'1.1-1'}},&emsp;
              {{summaryAverage['abs_effect_upper'] |number:'1.1-1'}}]</td>
            <td>[{{summaryCumulative['abs_effect_lower'] | number:'1.1-1'}},&emsp;
              {{summaryCumulative['abs_effect_upper'] | number:'1.1-1'}}]</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Relative effect&emsp;(s.d.)</td>
            <td>{{summaryAverage['rel_effect']|percent:'1.1-1'}}&emsp;
              ({{summaryAverage['rel_effect_sd'] | percent:'1.1-1'}})</td>
            <td>{{summaryCumulative['rel_effect']|percent:'1.1-1'}}&emsp;
              ({{summaryCumulative['rel_effect_sd'] |percent:'1.1-1'}})</td>
          </tr>
          <tr>
            <td>{{1 - summaryAverage['alpha'] | percent}} CI</td>
            <td>[{{summaryAverage['rel_effect_lower'] | percent:'1.1-1'}},&emsp;
              {{summaryAverage['rel_effect_upper'] |percent:'1.1-1'}}]</td>
            <td>[{{summaryCumulative['rel_effect_lower'] | percent:'1.1-1'}},&emsp;
              {{summaryCumulative['rel_effect_upper'] |percent:'1.1-1'}}]</td>
          </tr>
        </table>
        <div class="causal-impact-summary-text">
          <p>Posterior tail-area probability p:&emsp;
            {{summaryAverage['p_value'] |number:'1.3-3'}}</p>
          <p>Posterior prob. of a causal effect:&emsp;
            {{1 - summaryAverage['p_value'] | percent:'1.2-2'}}</p>
        </div>
      </mat-expansion-panel>
    </div>
  </ng-container>

</div>