/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable, map, of as observableOf, switchMap} from 'rxjs';
import {RerunDialogComponent} from '../common/rerun-dialog/rerun-dialog.component';
import {Experiment} from '../model/experiment';
import {Task} from '../model/task';
import {TaskStatusEnum} from '../model/task-status-enum';
import {TaskTypeEnum} from '../model/task-type-enum';
import {BusinessLogicService} from './business-logic.service';

/**
 * Service to check existing tasks affected by rerunning a task.
 */
@Injectable({
  providedIn: 'root',
})
export class ExperimentOverwriteGuardService {
  constructor(
    private readonly businessLogicService: BusinessLogicService,
    public dialog: MatDialog,
  ) {}

  /**
   * Prompts user to confirm that results from affected tasks will be overwritten.
   *
   * @param experimentId The Id for the experiment.
   * @param rerunTaskType The type of the rerun task.
   * @return An observable emitting the array of tasks affected.
   */
  confirmOverwrite(
    experimentId: string,
    rerunTaskType: TaskTypeEnum,
  ): Observable<boolean> {
    return this.getAffectedTasks(experimentId, rerunTaskType).pipe(
      switchMap((affectedTasks) => {
        if (affectedTasks.length > 0) {
          // When some tasks will be affected, display the comfirmation dialog.
          const dialogRef = this.dialog.open(RerunDialogComponent, {
            data: {
              affectedTaskTypes: affectedTasks.map((task) => task.taskType),
            },
          });
          return dialogRef.afterClosed();
        } else {
          // When no tasks will be affected, skip the confimation.
          return observableOf(true);
        }
      }),
    );
  }

  /**
   * Gets a list of existing tasks which will be affected when rerunning
   * the task.
   *
   * @param experimentId The Id for the experiment.
   * @param rerunTaskType The type of the rerun task.
   * @return An observable emitting the array of tasks affected.
   */
  getAffectedTasks(
    experimentId: string,
    rerunTaskType: TaskTypeEnum,
  ): Observable<Task[]> {
    return this.businessLogicService.getExperiment(experimentId).pipe(
      map((experiment: Experiment) => {
        return Object.values(experiment.tasks).filter((existingTask) =>
          this.isAffected(rerunTaskType, existingTask),
        );
      }),
    );
  }

  /**
   * Checks if an existing task is affected by the rerun of the current task.
   *
   * @param rerunTaskType The type of the task which will be rerun.
   * @param existingTask The existing task.
   * @return True if the existing task is affected, false if not.
   */
  private isAffected(rerunTaskType: TaskTypeEnum, existingTask: Task): boolean {
    if (
      existingTask.taskStatus === TaskStatusEnum.CANCELLED ||
      existingTask.taskStatus === TaskStatusEnum.FAILURE
    ) {
      // Cancelled or failed tasks are not affected.
      return false;
    }

    if (rerunTaskType === TaskTypeEnum.SPLIT) {
      // When reruning splitting, all the existing tasks are affected.
      return true;
    } else if (
      rerunTaskType === TaskTypeEnum.BUDGET_TABLE ||
      rerunTaskType === TaskTypeEnum.BUDGET_GRAPHS
    ) {
      // When rerunning budget simulation, all existing tasks but splitting
      // are affected.
      return existingTask.taskType === TaskTypeEnum.SPLIT ? false : true;
    } else {
      // When rerunning impact measurement, only the same type task is affected.
      return existingTask.taskType === rerunTaskType ? true : false;
    }
  }
}
