<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div class="new-exp-input-fields new-exp-text-style">
  <h2 i18n>Group Splitting</h2>
  <p i18n
    *ngIf="!rerun">Create a new A/B experiment starting from the Group Splitting operation.</p>
  <p i18n
    *ngIf="rerun">Rerun the Group Splitting operation.</p>
  <h2 i18n>Experiment Details</h2>
  <app-new-experiment-description (nameModified)="storeExperimentName($event)"
    [defaultName]="experimentName" [defaultDescription]="experimentDescription" [isDisabled]="rerun"
    (descriptionModified)="storeExperimentDescription($event)"/>
  <h2 i18n>Input Files</h2>
  <app-file-picker [inputFileLabel]="'KPI File'" (fileSelected)="storeKpiFile($event)"
    [matTooltip]="helpMessagesService.newSplittingPage.FILE_PICKER_KPI"
    [matTooltipPosition]="'above'"/>
  <app-file-preview [fileToView]="kpiFile"/>
  <app-file-picker [inputFileLabel]="'Configuration File'"
    [matTooltip]="helpMessagesService.newSplittingPage.FILE_PICKER_CONFIG"
    [matTooltipPosition]="'above'" matTooltipClass="max-width-tooltip"
    (fileSelected)="storeAdditionalVolumeRatios($event)"/>
  <app-file-preview [fileToView]="configFile"/>
  <h2 i18n>Splitting Parameters</h2>
  <app-numeric-input-field [fieldLabel]="numberOfPlansLabel" [minVal]="1" [maxVal]="5" [step]="1"
    [defaultValue]="numberOfPlansDefaultValue" [fieldMustBeInteger]="true"
    (fieldModified)="storeSplittingParameter($event)"
    [matTooltip]="helpMessagesService.newSplittingPage.INPUT_NUMBER_OF_PLANS"
    matTooltipClass="multi-line-tooltip max-width-tooltip"/>
  <app-numeric-input-field [fieldLabel]="numberOfGroupsLabel" [minVal]="2" [maxVal]="10" [step]="1"
    [defaultValue]="numberOfGroupsDefaultValue" [fieldMustBeInteger]="true"
    (fieldModified)="storeSplittingParameter($event)"
    [matTooltip]="helpMessagesService.newSplittingPage.INPUT_NUMBER_OF_GROUPS"
    matTooltipClass="max-width-tooltip"/>
  <mat-card class="new-exp-form-field member-per-group-card"
    [matTooltip]="helpMessagesService.newSplittingPage.INPUT_NUMBER_OF_MEMBERS_PER_GROUP"
    matTooltipClass="multi-line-tooltip max-width-tooltip">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-subtitle i18n>Number of Members per Group</mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div class="member-per-group-card-content">
        <mat-checkbox class="member-per-group-checkbox" [checked]="isNumberOfMembersPerGroupAuto"
          #autocheck (change)="changeNumberOfMembersPerGroupMode($event)"><i>auto</i>
        </mat-checkbox>
        <app-numeric-input-field [fieldLabel]="numberOfMembersPerGroupLabel" [minVal]="1"
          [maxVal]="1000" [step]="1"
          [defaultValue]="numberOfMembersPerGroupDefaultValue > 0
            ? numberOfMembersPerGroupDefaultValue : 1"
          [fieldMustBeInteger]="true"
          (fieldModified)="storeSplittingParameter($event)" *ngIf="!autocheck.checked"/>
      </div>
    </mat-card-content>
  </mat-card>
  <app-numeric-input-field [fieldLabel]="correlationCoefficientLabel"
    [defaultValue]="correlationCoefficientDefaultValue"
    (fieldModified)="storeSplittingParameter($event)"
    [matTooltip]="helpMessagesService.newSplittingPage.INPUT_CORRELATION_COEFFICIENT"
    matTooltipClass="unset-max-width-tooltip"/>
  <app-numeric-input-field [fieldLabel]="dataVolumeCoefficientLabel"
    [defaultValue]="dataVolumeCoefficientDefaultValue" [minVal]="0"
    (fieldModified)="storeSplittingParameter($event)"
    [matTooltip]="helpMessagesService.newSplittingPage.INPUT_DATA_VOLUME_COEFFICIENT"
    matTooltipClass="max-width-tooltip"/>
  <ng-container *ngIf="additionalVolumeRatios.length > 0">
    <h2 i18n>Configuration Volume Ratios</h2>
    <app-numeric-input-field *ngFor="let ratio of additionalVolumeRatios" [fieldLabel]="ratio"
      [defaultValue]="additionalVolumeRatioDefaultValue"
      (fieldModified)="storeAdditionalSplittingParameter($event)"
      [matTooltip]="helpMessagesService.newSplittingPage.INPUT_ADDITIONAL_CONFIGURATION_VOLUME_RATIO"
      matTooltipClass="multi-line-tooltip max-width-tooltip"/>
  </ng-container>
  <span class="input-btn">
    <button mat-flat-button color="accent" (click)="submitOrRerunSplitting()"
      [matTooltip]="helpMessagesService.newSplittingPage.BUTTON_SUBMIT"
      i18n>Submit</button>
  </span>
</div>