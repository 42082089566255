/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {TopLevelSpec} from 'vega-lite';

import {
  CausalImpactCovariateWeight,
  CausalImpactTaskResult,
} from '../../../model/causal-impact-task-result';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {HelpMessagesService} from '../../../service/help-messages.service';

/** Component for displaying Causal Impact results. */
@Component({
  standalone: false,
  selector: 'app-causal-impact-results',
  templateUrl: './causal-impact-results.component.html',
  styleUrls: ['./causal-impact-results.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class CausalImpactResultsComponent implements OnInit {
  private static readonly CHART_WIDTH = 600;
  private static readonly CHART_HEIGHT = 200;
  @Input({required: true}) testGroups!: string[];
  @Input({required: true}) causalImpactResults!: CausalImpactTaskResult;
  @Input({required: true}) experimentId!: string;
  causalImpactGraphs!: TopLevelSpec[];
  covariateWeightsGraphs!: TopLevelSpec[];

  constructor(protected readonly helpMessagesService: HelpMessagesService) {}

  ngOnInit(): void {
    this.causalImpactGraphs = this.causalImpactResults.causal_impact_graphs.map(
      // TODO: b/358236640 - Deprecate string format for Causal Impact graphs.
      // This conversion is not needed when the graph type is unified to json.
      (graph) => (typeof graph === 'string' ? JSON.parse(graph) : graph),
    );
    if (this.causalImpactResults.causal_impact_covariate_weights) {
      this.covariateWeightsGraphs =
        this.causalImpactResults.causal_impact_covariate_weights.map(
          (covariateWeights) => {
            if (covariateWeights?.length > 2) {
              return this.ConvertToVegaLiteBarChartSpec(covariateWeights);
            } else {
              // Chart is not displayed when the number of covariates including
              // intercept is less than 3.
              return {
                // Spec with no content, which is not supposed to be shown.
                '$schema': 'https://vega.github.io/schema/vega-lite/v5.json',
                data: {values: []},
                mark: 'bar',
              };
            }
          },
        );
    }
  }

  protected sendImpactMeasurementAction(): StartingPointEnum {
    return StartingPointEnum.IMPACT_MEASUREMENT;
  }

  private ConvertToVegaLiteBarChartSpec(
    covariate_weights: CausalImpactCovariateWeight[],
  ): TopLevelSpec {
    // Define Vega-Lite specification.
    const vegaLiteSpec: TopLevelSpec = {
      '$schema': 'https://vega.github.io/schema/vega-lite/v5.json',
      description: 'A bar chart',
      data: {values: covariate_weights},

      mark: {type: 'bar'},
      encoding: {
        x: {
          field: 'covariate_name',
          axis: {
            labelAngle: 0,
            titleFontSize: 18,
            labelFontSize: 16,
          },
          title: 'Covariate',
        },
        y: {
          field: 'weight',
          type: 'quantitative',
          title: 'Inclusion Probability',
          scale: {domain: [0, 1]},
          axis: {
            titleFontSize: 18,
            labelFontSize: 16,
            titlePadding: 20,
          },
        },
        tooltip: [
          {field: 'covariate_name', title: 'Covariate'},
          {field: 'weight', title: 'Inclusion Probability'},
        ],
      },

      width: CausalImpactResultsComponent.CHART_WIDTH,
      height: CausalImpactResultsComponent.CHART_HEIGHT,
    };

    return vegaLiteSpec;
  }
}
