/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This file contains the function for collecting internal EE4E instance usage
 * via GA4.
 */

declare let gtag: Function;

/**
 * This field is used to collect the customer name.
 */
export const f1 = 'customerName';
/**
 * This field is used to collect the connect sales customer id.
 */
export const f2 = 'connectSalesCustomerId';
/**
 * This field is used to collect the expected revenue impact.
 */
export const f3 = 'expectedRevenueImpact';

/**
 * This interface is used to collect additional information about which customer
 * is actually using the platform.
 */
export interface AdditionalInformation {
  [f1]: string;
  [f2]: string;
  [f3]: string;
}

/**
 * Collects system usage of internal instance of EE4E via GA4 for measuring
 * the incremental business value of EE4E generated by our internal
 * stakeholders when using the platform.
 * @param customerName Customer Name.
 * @param connectSalesCustomerId Connect Sales Customer ID.
 * @param expectedRevenueImpact Expected Revenue Impact.
 * @return true if the call to GA4 was successful, false otherwise.
 */
export function processAdditionalInformation(
  customerName: string,
  connectSalesCustomerId: string,
  expectedRevenueImpact: string,
): boolean {
  try {
    gtag('event', 'new_experiment', {
      'customer_data':
        `${customerName},` +
        ` ${connectSalesCustomerId}, ${expectedRevenueImpact}`,
      'customer_name': customerName,
      'customer_id': connectSalesCustomerId,
      'expected_revenue_impact': expectedRevenueImpact,
    });
    return true;
  } catch (error) {
    console.error('Error sending data to Google Analytics:', error);
    return false;
  }
}

/**
 * Collects system usage of internal instance of EE4E via GA4 for measuring
 * in details how many tasks for each customer are created by our internal
 * stakeholders when using the platform.
 * @param customerName Customer Name.
 * @param connectSalesCustomerId Connect Sales Customer ID.
 * @param expectedRevenueImpact Expected Revenue Impact.
 * @param taskType Task Type (Splitting, Budget Table, Budget Graph, DID,
 *  Causal Impact).
 * @param taskCount For splitting, budget simulation, DID it is at 1,
 *  for Causal Impact it is equal to the number of Test groups.
 * @return true if the call to GA4 was successful, false otherwise.
 */
export function processDetailedInformation(
  customerName: string,
  connectSalesCustomerId: string,
  expectedRevenueImpact: string,
  taskType: string,
  taskCount: string,
): boolean {
  try {
    gtag('event', 'task_creation', {
      'task_type': taskType,
      'task_count': taskCount,
      'customer_name': customerName,
      'customer_id': connectSalesCustomerId,
      'expected_revenue_impact': expectedRevenueImpact,
    });
    return true;
  } catch (error) {
    console.error('Error sending data to Google Analytics:', error);
    return false;
  }
}
