<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div>
  <div>
    <h2 i18n>Input Files</h2>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-title>KPI File</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Filename</mat-card-subtitle>
              <p>{{kpiFileName | longStringTruncate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Size</mat-card-subtitle>
              <p>{{kpiFileSize}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>First sample date</mat-card-subtitle>
              <p>{{kpiFirstSampleDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Last sample date</mat-card-subtitle>
              <p>{{kpiLastSampleDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <ng-container *ngIf="isMemberBased">
      <h2 i18n>Selected Plan</h2>
      <mat-card class="view-experiment-last-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>Selected Plan</mat-card-subtitle>
            <p>{{selectedPlan}}</p>
          </mat-card-title-group>
        </mat-card-header>
      </mat-card>
    </ng-container>
  </div>
  <div>
    <h2 i18n>Test Groups</h2>
    <mat-card class="view-experiment-last-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Test Groups</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-listbox disabled>
          <mat-chip-option *ngFor="let testGroup of testGroups">{{testGroup}}</mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <ng-container *ngIf="isMemberBased">
      <h2 i18n>Excluded Members</h2>
      <mat-card class="view-experiment-field">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>{{controlGroupKeyAndLabel}}</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <mat-chip-listbox disabled>
            <mat-chip-option
              *ngFor="let excludedMember of excludedMembers.get(controlGroupKeyAndLabel) ?? ['None']">{{excludedMember}}</mat-chip-option>
          </mat-chip-listbox>
        </mat-card-content>
      </mat-card>
      <mat-card class="view-experiment-field" [ngClass]="{'view-experiment-last-field': isLast}"
        *ngFor="let testGroup of testGroups; last as isLast">
        <mat-card-header class="view-exp-card-content">
          <mat-card-title-group>
            <mat-card-subtitle i18n>{{testGroup}}</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content>
          <mat-chip-listbox disabled>
            <mat-chip-option
              *ngFor="let excludedMember of excludedMembers.get(testGroup) ?? ['None']">{{excludedMember}}</mat-chip-option>
          </mat-chip-listbox>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
  <div>
    <h2 i18n>Analysis Methodology</h2>
    <mat-card class="view-experiment-last-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle>Analysis Method</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-select disabled [(value)]="selectedMethod">
          <mat-option *ngFor="let method of methods" [value]="method">{{method}}</mat-option>
        </mat-select>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <h2 i18n>Impact Measurement Parameters</h2>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Pre-Period</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Start Date</mat-card-subtitle>
              <p>{{preExperimentStartDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>End Date</mat-card-subtitle>
              <p>{{preExperimentEndDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <mat-card class="view-experiment-field">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>Experiment Period</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>Start Date</mat-card-subtitle>
              <p>{{experimentStartDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
        <mat-card class="view-exp-file-field">
          <mat-card-header>
            <mat-card-title-group>
              <mat-card-subtitle i18n>End Date</mat-card-subtitle>
              <p>{{experimentEndDate}}</p>
            </mat-card-title-group>
          </mat-card-header>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <ng-container *ngIf="isCausalImpact">
      <div class="view-experiment-field">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Advanced Settings </mat-panel-title>
            <mat-panel-description class="mat-expansion-panel-header-description">
              <mat-icon>settings</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle i18n>Number of Seasons</mat-card-subtitle>
                <p>{{numberOfSeasons}}</p>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
          <mat-card class="view-exp-file-field">
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-subtitle i18n>Confidence Interval</mat-card-subtitle>
                <p>{{confidenceInterval | percent}}</p>
              </mat-card-title-group>
            </mat-card-header>
          </mat-card>
        </mat-expansion-panel>
        <br>
    </div>
    </ng-container>
    <mat-card class="view-experiment-field" [ngClass]="{'view-experiment-last-field': isLast}"
      *ngFor="let testGroupCost of testGroupsCosts; index as i; last as isLast">
      <mat-card-header class="view-exp-card-content">
        <mat-card-title-group>
          <mat-card-subtitle i18n>{{testGroups[i]}}</mat-card-subtitle>
          <p>{{testGroupCost}}</p>
        </mat-card-title-group>
      </mat-card-header>
    </mat-card>
  </div>
  <mat-divider></mat-divider>
</div>