/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * The enum describing the starting point of an experiment
 */
export enum StartingPointEnum {
  // The users start the complete user journey from splitting phase with raw KPI
  // data
  SPLITTING = 'Splitting',
  // The users use pre-grouped KPI data to conduct a budget simulation.
  BUDGET_SIMULATION = 'Budget Simulation',
  // The users use post-experiment KPI data to measure its impact.
  IMPACT_MEASUREMENT = 'Impact Measurement',
}
