/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

import {HelpMessagesService} from '../../../service/help-messages.service';

/**
 * Component used to display MAPE and Causal Impact Summary for
 * Post-Experiment Impact Measurement and Budget Simulation.
 */
@Component({
  selector: 'app-mape-and-impact-summary-card',
  templateUrl: './mape-and-impact-summary-card.component.html',
  styleUrls: ['./mape-and-impact-summary-card.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class MapeAndImpactSummaryCardComponent implements OnInit {
  @Input() mape: number | null = null;
  @Input({required: true}) rawCausalImpactSummary!: Record<
    string,
    Record<string, number>
  >;
  @Input() cardTitle: string | null = null;
  protected causalImpactSummaryWithoutLastLinesInstructions!: string;

  displayedColumns: string[] = ['metric', 'average', 'cumulative'];
  causalImpactSummaryPanelOpenState = false;
  summaryAverage: Record<string, number> | null = null;
  summaryCumulative: Record<string, number> | null = null;

  constructor(protected readonly helpMessagesService: HelpMessagesService) {}

  ngOnInit() {
    // TODO: b/342112608 - Display an error message when the Causal Impact
    // summary has an invalid format.
    if ('average' in this.rawCausalImpactSummary) {
      this.summaryAverage = this.rawCausalImpactSummary['average'];
    }
    if ('cumulative' in this.rawCausalImpactSummary) {
      this.summaryCumulative = this.rawCausalImpactSummary['cumulative'];
    }
  }
}
