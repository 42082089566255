/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {firstValueFrom, of as observableOf, switchMap} from 'rxjs';

import {TaskTypeEnum} from 'src/app/model/task-type-enum';
import * as constants from '../../common/constants';
import * as routes from '../../common/routes';
import {
  experimentNameAndDescriptionAreValid,
  openSnackBarWithMessage,
} from '../../common/utils';
import {SplittingParameters} from '../../model/splitting-parameters';
import {SplittingTaskRequest} from '../../model/splitting-task-request';
import {StartingPointEnum} from '../../model/starting-point-enum';
import {BusinessLogicService} from '../../service/business-logic.service';
import {ExperimentOverwriteGuardService} from '../../service/experiment-overwrite-guard.service';
import {FileReaderService} from '../../service/file-reader.service';
import {HelpMessagesService} from '../../service/help-messages.service';

/** Component for creating a new Splitting experiment. */
@Component({
  standalone: false,
  selector: 'app-new-splitting',
  templateUrl: './new-splitting.component.html',
  styleUrls: ['./new-splitting.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY,
      },
    },
  ],
})
export class NewSplittingComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  additionalConstraintsOnNumberOfMembersPerGroupField = [
    {
      regex: new RegExp('[1-9]'),
      errorMessage: 'Number of Members per Group cannot be equal to 0.',
    },
  ];
  additionalVolumeRatios: string[] = [];

  @Input() experimentName = '';
  @Input() experimentDescription = '';
  @Input() rerun = false;
  @Input() experimentId = '';

  configFile: File | null = null;
  protected kpiFile: File | null = null;

  readonly numberOfPlansLabel = 'Number of Plans';
  readonly numberOfGroupsLabel = 'Number of Groups';
  readonly numberOfMembersPerGroupLabel = 'Number of Members per Group';
  readonly correlationCoefficientLabel = 'Correlation Coefficient';
  readonly dataVolumeCoefficientLabel = 'Data Volume Coefficient';
  numberOfPlansDefaultValue = 2;
  numberOfGroupsDefaultValue = 4;
  numberOfMembersPerGroupDefaultValue = 1;
  correlationCoefficientDefaultValue = 0.8;
  dataVolumeCoefficientDefaultValue = 0;
  readonly additionalVolumeRatioDefaultValue = 0.8;

  isNumberOfMembersPerGroupAuto = true;
  private readonly numberOfMembersPerGroupAutoValue = -1;

  readonly snackBarSplittingParametersErrorMessage =
    'Some splitting parameters are incorrect.';
  readonly snackBarAdditionalRatiosErrorMessage =
    'Some additional volume ratios are incorrect.';
  readonly snackBarSubmittingSplittingExperimentMessage =
    'Submitting splitting task to server.';

  additionalRatiosLabelForms = new Map<string, FormGroup>();
  splittingParametersDefaultValues = new Map<string, number>();
  splittingParametersLabelForms = new Map<string, FormGroup>();

  @Input()
  splittingParametersForRerun: SplittingParameters | null = null;

  constructor(
    private readonly fileReaderService: FileReaderService,
    readonly submissionSnackBar: MatSnackBar,
    private businessLogicService: BusinessLogicService,
    private router: Router,
    private readonly experimentOverwriteGuardService: ExperimentOverwriteGuardService,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {}

  ngOnInit() {
    if (this.rerun && this.splittingParametersForRerun) {
      this.numberOfPlansDefaultValue =
        this.splittingParametersForRerun.number_of_result_sets;
      this.numberOfGroupsDefaultValue =
        this.splittingParametersForRerun.number_of_groups;
      this.numberOfMembersPerGroupDefaultValue =
        this.splittingParametersForRerun.number_of_members_per_group;
      if (this.numberOfMembersPerGroupDefaultValue !== -1) {
        this.isNumberOfMembersPerGroupAuto = false;
      }
      this.correlationCoefficientDefaultValue =
        this.splittingParametersForRerun.correlation_coefficient;
      this.dataVolumeCoefficientDefaultValue =
        this.splittingParametersForRerun.data_volume_coefficient;
    }

    this.splittingParametersDefaultValues.set(
      this.numberOfPlansLabel,
      this.numberOfPlansDefaultValue,
    );
    this.splittingParametersDefaultValues.set(
      this.numberOfGroupsLabel,
      this.numberOfGroupsDefaultValue,
    );
    this.splittingParametersDefaultValues.set(
      this.numberOfMembersPerGroupLabel,
      this.numberOfMembersPerGroupDefaultValue,
    );
    this.splittingParametersDefaultValues.set(
      this.correlationCoefficientLabel,
      this.correlationCoefficientDefaultValue,
    );
    this.splittingParametersDefaultValues.set(
      this.dataVolumeCoefficientLabel,
      this.dataVolumeCoefficientDefaultValue,
    );
  }

  changeNumberOfMembersPerGroupMode(event: MatCheckboxChange): void {
    if (event.checked) {
      // It's required to reset the form since the numeric component
      // for numberOfMembersPerGroup might disappear in an invalid state,
      // which will block the submit button.
      this.splittingParametersLabelForms.set(
        this.numberOfMembersPerGroupLabel,
        new FormGroup({}),
      );
    }
    this.isNumberOfMembersPerGroupAuto = event.checked;
  }

  storeKpiFile(kpiFile: File | null): void {
    this.kpiFile = kpiFile;
  }

  async storeAdditionalVolumeRatios(configFile: File | null): Promise<void> {
    this.configFile = configFile;
    if (configFile) {
      this.additionalVolumeRatios = await firstValueFrom(
        this.fileReaderService.getConfigAdditionalColumns(
          this.configFile as File,
        ),
      );
      this.additionalVolumeRatios.map((label) => {
        this.additionalRatiosLabelForms.set(label, new FormGroup({}));
      });
    } else {
      this.additionalVolumeRatios = [];
      this.additionalRatiosLabelForms.clear();
    }
  }

  storeExperimentName(name: string): void {
    this.experimentName = name;
  }

  storeExperimentDescription(description: string): void {
    this.experimentDescription = description;
  }

  storeSplittingParameter(
    splittingParameterRecord: Record<string, FormGroup>,
  ): void {
    const parameterName = Object.keys(splittingParameterRecord)[0] as string;
    this.splittingParametersLabelForms.set(
      parameterName,
      splittingParameterRecord[parameterName],
    );
  }

  storeAdditionalSplittingParameter(
    additionalRatioRecord: Record<string, FormGroup>,
  ): void {
    const parameterName = Object.keys(additionalRatioRecord)[0] as string;
    this.additionalRatiosLabelForms.set(
      parameterName,
      additionalRatioRecord[parameterName],
    );
  }

  private openSnackBarWithMessage(message: string): void {
    openSnackBarWithMessage(message, this.submissionSnackBar);
  }

  private areAllInputFieldsValid(inputFields: Map<string, FormGroup>): boolean {
    for (const form of inputFields.values()) {
      if (form.invalid) {
        return false;
      }
    }
    return true;
  }

  private buildConfigVolumeRatios(): Record<string, number> {
    const configVolumeRatios: Record<string, number> = {};
    for (const [key, formGroup] of this.additionalRatiosLabelForms) {
      configVolumeRatios[key] =
        formGroup.get('fieldValue')?.value ??
        this.additionalVolumeRatioDefaultValue;
    }
    return configVolumeRatios;
  }

  private retrieveSplittingParameterValue(label: string): number {
    return (
      this.splittingParametersLabelForms.get(label)?.get('fieldValue')?.value ??
      this.splittingParametersDefaultValues.get(label)
    );
  }

  private buildSplittingTaskRequest(): SplittingTaskRequest {
    return {
      kpi_csv_file: this.kpiFile!,
      config_csv_file: this.configFile!,
      splitting_parameters: {
        number_of_result_sets: this.retrieveSplittingParameterValue(
          this.numberOfPlansLabel,
        ),
        number_of_groups: this.retrieveSplittingParameterValue(
          this.numberOfGroupsLabel,
        ),
        number_of_members_per_group: this.isNumberOfMembersPerGroupAuto
          ? this.numberOfMembersPerGroupAutoValue
          : this.retrieveSplittingParameterValue(
              this.numberOfMembersPerGroupLabel,
            ),
        correlation_coefficient: this.retrieveSplittingParameterValue(
          this.correlationCoefficientLabel,
        ),
        data_volume_coefficient: this.retrieveSplittingParameterValue(
          this.dataVolumeCoefficientLabel,
        ),
        config_volume_ratios: this.buildConfigVolumeRatios(),
      },
    };
  }

  submitOrRerunSplitting(): void {
    if (
      !experimentNameAndDescriptionAreValid(
        this.experimentName,
        this.experimentDescription,
      )
    ) {
      this.openSnackBarWithMessage(
        constants.SNACKBAR_INVALID_NAME_DESCRIPTION_ERROR_MESSAGE,
      );
      return;
    }
    // KPI File must be attached.
    if (!this.kpiFile) {
      this.openSnackBarWithMessage(constants.SNACKBAR_KPI_FILE_ERROR_MESSAGE);
      return;
    }
    // Mandatory splitting parameters must be valid.
    if (!this.areAllInputFieldsValid(this.splittingParametersLabelForms)) {
      this.openSnackBarWithMessage(
        this.snackBarSplittingParametersErrorMessage,
      );
      return;
    }
    // Additional configuration volume ratios must be valid.
    if (!this.areAllInputFieldsValid(this.additionalRatiosLabelForms)) {
      this.openSnackBarWithMessage(this.snackBarAdditionalRatiosErrorMessage);
      return;
    }

    if (this.rerun) {
      const currentTaskType = TaskTypeEnum.SPLIT;
      this.experimentOverwriteGuardService
        .confirmOverwrite(this.experimentId, currentTaskType)
        .pipe(
          switchMap((proceed) => {
            if (proceed) {
              // Submitting Splitting experiment to server.
              return this.businessLogicService.createSplittingTaskForRerun(
                this.experimentId,
                this.buildSplittingTaskRequest(),
              );
            } else {
              // Not sending a request to server.
              return observableOf(null);
            }
          }),
          takeUntilDestroyed(this.destroyedRef),
        )
        .subscribe(this.createRoutingObserver());
    } else {
      this.businessLogicService
        .createSplittingTask(
          this.experimentName,
          this.experimentDescription,
          this.buildSplittingTaskRequest(),
        )
        .pipe(takeUntilDestroyed(this.destroyedRef))
        .subscribe(this.createRoutingObserver());
    }
  }

  private createRoutingObserver() {
    return {
      next: (response: unknown) => {
        if (response) {
          this.openSnackBarWithMessage(
            this.snackBarSubmittingSplittingExperimentMessage,
          );
          this.router.navigate(
            [
              routes.VIEW_EXPERIMENT_URL +
                `/${response}/${StartingPointEnum.SPLITTING}`,
            ],
            {skipLocationChange: true},
          );
        }
      },
      error: (error: Error) => {
        this.openSnackBarWithMessage(error.message);
      },
    };
  }
}
