/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {TopLevelSpec} from 'vega-lite';

import {convertToLineChartSpec} from '../../../common/kpi-line-chart';
import {SplittingResultSet} from '../../../model/splitting-result-set';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {Task} from '../../../model/task';
import {HelpMessagesService} from '../../../service/help-messages.service';

/**
 * Component for displaying splitting results when viewing an existing
 * experiment.
 */
@Component({
  standalone: false,
  selector: 'app-splitting-results',
  templateUrl: './splitting-results.component.html',
  styleUrls: ['./splitting-results.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class SplittingResultsComponent implements OnInit {
  @Input({required: true}) experimentId!: string;
  @Input({required: true}) splittingResultSets: SplittingResultSet[] = [];
  @Input({required: true}) task!: Task;
  kpiGraphs!: TopLevelSpec[];
  normalizedKpiGraphs!: TopLevelSpec[];

  constructor(protected readonly helpMessagesService: HelpMessagesService) {}

  ngOnInit(): void {
    this.kpiGraphs = this.splittingResultSets.map((resultSet) =>
      convertToLineChartSpec(resultSet.kpi_dataframe, null, null, null, null),
    );
    this.normalizedKpiGraphs = this.splittingResultSets.map((resultSet) =>
      convertToLineChartSpec(
        resultSet.normalized_kpi_dataframe,
        null,
        null,
        null,
        null,
      ),
    );
  }

  protected sendBudgetSimulationAction(): StartingPointEnum {
    return StartingPointEnum.BUDGET_SIMULATION;
  }

  protected sendImpactMeasurementAction(): StartingPointEnum {
    return StartingPointEnum.IMPACT_MEASUREMENT;
  }
}
