/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, catchError, throwError} from 'rxjs';

import {BudgetSimulationGraphRequest} from '../model/budget-simulation-graph-task-request';
import {BudgetSimulationTableRequest} from '../model/budget-simulation-table-task-request';
import {CausalImpactAnalysisRequest} from '../model/causal-impact-analysis-request';
import {DidAnalysisRequest} from '../model/did-analysis-request';
import {GetTaskResponse} from '../model/get-task-response';
import {SplittingTaskRequest} from '../model/splitting-task-request';
import {TaskCancellationResponse} from '../model/task-cancellation-response';
import {TaskidResponse} from '../model/taskid-response';
import {ValidationErrorResponse} from '../model/validation-error-response';

function convertSplittingTaskRequestToFormData(
  request: SplittingTaskRequest,
): FormData {
  const formData = new FormData();
  formData.append('kpi_csv_file', request.kpi_csv_file);
  if (request.config_csv_file) {
    formData.append('config_csv_file', request.config_csv_file);
  }
  formData.append(
    'splitting_parameters',
    JSON.stringify(request.splitting_parameters),
  );
  return formData;
}

function convertBudgetSimulationTableRequestToFormData(
  request: BudgetSimulationTableRequest,
): FormData {
  const formData = new FormData();
  if (request.kpi_csv_file) {
    formData.append('kpi_csv_file', request.kpi_csv_file);
  }
  formData.append(
    'budget_table_parameters',
    JSON.stringify(request.budget_table_parameters),
  );
  return formData;
}

function convertCausalImpactAnalysisRequestToFormData(
  request: CausalImpactAnalysisRequest,
): FormData {
  const formData = new FormData();
  formData.append('post_experiment_kpi_file', request.post_experiment_kpi_file);
  formData.append(
    'causal_impact_analysis_parameters',
    JSON.stringify(request.causal_impact_analysis_parameters),
  );
  return formData;
}

function convertDidAnalysisRequestToFormData(
  request: DidAnalysisRequest,
): FormData {
  const formData = new FormData();
  if (request.post_experiment_kpi_file) {
    formData.append(
      'post_experiment_kpi_file',
      request.post_experiment_kpi_file,
    );
  }
  formData.append(
    'did_analysis_parameters',
    JSON.stringify(request.did_analysis_parameters),
  );
  return formData;
}

function handleError(errorResponse: HttpErrorResponse) {
  if (errorResponse.status === 0) {
    console.error('An error occurred:', errorResponse.error);
  } else if (errorResponse.status === 422) {
    return throwError(() => new ValidationErrorResponse(errorResponse));
  }
  return throwError(() => errorResponse);
}

/**
 * An API request service for interacting with the ee4e backend.
 */
@Injectable({providedIn: 'root'})
export class Ee4eApiService {
  private readonly apiMountPoint = '/api';
  constructor(private http: HttpClient) {}

  /**
   * Posts a request to ee4e backend to create a splitting task.
   *
   * @param {SplittingTaskRequest} request - A request object that contains
   *     a KPI file, a config file and a splitting parameter object.
   * @return {Observable<TaskidResponse>} An observable that emits an object
   *     with task_id.
   */
  createSplitTask(request: SplittingTaskRequest): Observable<TaskidResponse> {
    const formData = convertSplittingTaskRequestToFormData(request);
    return this.http
      .post<TaskidResponse>(this.apiMountPoint + '/split', formData)
      .pipe(catchError(handleError));
  }

  /**
   * Posts a request to ee4e backend to create a causal impact analysis task.
   * @param request
   * @return {Observable<TaskidResponse>} An observable that emits an object
   *     containing the causal impact analysis task result.
   */
  createCausalImpactTask(
    request: CausalImpactAnalysisRequest,
  ): Observable<TaskidResponse> {
    const formData = convertCausalImpactAnalysisRequestToFormData(request);
    return this.http
      .post<TaskidResponse>(
        this.apiMountPoint + '/analysis/causalimpact',
        formData,
      )
      .pipe(catchError(handleError));
  }

  /**
   * Posts a request to ee4e backend to create a DID analysis task.
   * @param {DidAnalysisRequest} request - A request object that contains
   *   a KPI file and a DID analysis parameter object.
   * @return {Observable<TaskidResponse>} An observable that emits an object
   *    containing the DID task result.
   */
  createDidTask(request: DidAnalysisRequest): Observable<TaskidResponse> {
    const formData = convertDidAnalysisRequestToFormData(request);
    return this.http
      .post<TaskidResponse>(this.apiMountPoint + '/analysis/did', formData)
      .pipe(catchError(handleError));
  }

  /**
   * Posts a request to ee4e backend to create a budget simulation table task.
   * @param {BudgetSimulationTableRequest} request - A request object that
   *    contains a KPI file and a budget simulation table parameter object.
   * @return {Observable<TaskidResponse>} An observable that emits an object
   *    containing the budget simulation table task result.
   */
  createBudgetSimulationTableTask(
    request: BudgetSimulationTableRequest,
  ): Observable<TaskidResponse> {
    const formData = convertBudgetSimulationTableRequestToFormData(request);
    return this.http
      .post<TaskidResponse>(this.apiMountPoint + '/budget/table', formData)
      .pipe(catchError(handleError));
  }

  /**
   * Posts a request to ee4e backend to create a budget simulation graph task.
   * @param {BudgetSimulationGraphRequest} request A request object that
   *    contains a KPI file and a budget simulation graph parameter object.
   * @return {Observable<TaskidResponse>} An observable that emits an object
   *    containing the budget simulation graph task result.
   */
  createBudgetSimulationGraphTask(
    request: BudgetSimulationGraphRequest,
  ): Observable<TaskidResponse> {
    return this.http
      .post<TaskidResponse>(
        this.apiMountPoint + '/budget/graphs',
        request.budget_graphs_parameters,
      )
      .pipe(catchError(handleError));
  }

  /**
   * Gets a task result from ee4e backend.
   *
   * @param {string} taskId - A UUID referring to an existing task.
   * @return {Observable<GetTaskResponse>} An observable that emits an object
   *     containing the task result.
   */
  getTask(taskId: string) {
    return this.http
      .get<GetTaskResponse>(`${this.apiMountPoint}/task/${taskId}`)
      .pipe(catchError(handleError));
  }

  /**
   * Cancels a task whose state is QUEUED or IN_PROGRESS.
   *
   * @param taskId A UUID referring to an existing task.
   *
   * @return An observable that emits a TaskCancellationResponse object.
   */
  cancelTask(taskId: string): Observable<TaskCancellationResponse> {
    return this.http
      .patch<TaskCancellationResponse>(
        `${this.apiMountPoint}/task/${taskId}`,
        {},
      )
      .pipe(catchError(handleError));
  }

  /**
   * Deletes a task.
   *
   * @param taskId A UUID referring to an existing task.
   *
   * @return An observable that emits a TaskCancellationResponse object.
   */
  deleteTask(taskId: string): Observable<TaskCancellationResponse> {
    return this.http
      .delete<TaskCancellationResponse>(`${this.apiMountPoint}/task/${taskId}`)
      .pipe(catchError(handleError));
  }
}
