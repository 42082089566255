<!--
 Copyright 2024 Google LLC.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     https://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->

<div class="new-exp-input-fields new-exp-text-style">
  <h2 i18n>Budget Simulation</h2>
  <p i18n *ngIf="!rerun">Continue to Budget Simulation for an existing Splitting experiment.</p>
  <p i18n *ngIf="rerun">Rerun Budget Simulation operation for an existing Splitting experiment.</p>
</div>
<app-result-set-display-and-selection *ngIf="canDisplayPage"
  (planSelectionModified)="storeResultSet($event)"
  (testGroupsSelectionModified)="storeTestGroups($event)"/>
<div class="new-exp-input-fields new-exp-text-style" *ngIf="canDisplayPage">
  <h2 i18n>Budget Simulation Parameters</h2>
  <app-numeric-input-field [fieldLabel]="iCPALabel" [minVal]="0.01" [maxVal]="1000000"
    [step]="0.01" [defaultValue]="iCPADefaultValue" (fieldModified)="storeICPA($event)"
    [matTooltip]="helpMessagesService.continueToBudgetSimulationPage.INPUT_ICPA"
    matTooltipClass="max-width-tooltip"/>
  <app-numeric-values-input-field [fieldLabel]="durationsLabel"
    (valuesChanged)="storeDurations($event)"[placeHolderLabel]="durationsPlaceHolder"
    [defaultValues]="durationsDefaultValue" [maxVal]="1000" [maxLength]="8"
    [matTooltip]="helpMessagesService.continueToBudgetSimulationPage.INPUT_DURATIONS"
    matTooltipClass="max-width-tooltip"/>
  <app-numeric-values-input-field [fieldLabel]="upliftsLabel"
    (valuesChanged)="storeUplifts($event)" [placeHolderLabel]="upliftsPlaceHolder"
    [defaultValues]="upliftsDefaultValue" [minVal]="1.01" [maxVal]="1000" [maxLength]="8"
    [valuesMustBeInteger]="false"
    [matTooltip]="helpMessagesService.continueToBudgetSimulationPage.INPUT_UPLIFTS"
    matTooltipClass="multi-line-tooltip max-width-tooltip"/>
  <span class="input-btn">
    <button mat-flat-button color="accent" (click)="submitBudgetSimulationExperiment()"
      [matTooltip]="helpMessagesService.newBudgetSimulationPage.BUTTON_SUBMIT"
      matTooltipClass="multi-line-tooltip max-width-tooltip" i18n>Submit</button>
  </span>
</div>