/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {v4 as uuidv4} from 'uuid';
import {processDetailedInformation} from '../new-experiment/additional-information/additional-information-util';

/** A service where provides utility functions. */
export class UtilService {
  /**
   * Generates a UUID.
   *
   * @return A string that conforms a UUID pattern.
   */
  generateId(): string {
    return uuidv4();
  }

  /**
   * Processes metrics.
   */
  processMetrics(
    field1: string,
    field2: string,
    field3: string,
    field4: string,
    field5: string,
  ): void {
    processDetailedInformation(field1, field2, field3, field4, field5);
  }
}
